import { createTheme } from '@mui/material/styles';
import { colors } from './shared-theme';

export const dialogSelectTheme = createTheme({
  palette: {
    contrastThreshold: 3,
    tonalOffset: 0.1,
  },
  shadows: [
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
  ],
  typography: {
    allVariants: {
      textTransform: 'none',
      display: 'flex',
      alignItems: 'center',
      fontFamily: 'ArialMT',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      letterSpacing: 'normal',
      lineHeight: 'normal',
    },
  },

  components: {
    MuiInput: {
      styleOverrides: {
        root: {
          '& .MuiInput-input': {
            display: 'flex',
            alignItems: 'center',
            paddingLeft: 19,
            paddingTop: 5,
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 'unset',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .role-alias': {
            width: 34,
            height: 34,
            margin: '1px 8px 1px 0',
            borderRadius: '100%',
            background: colors.LIGHTER_GREY,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textTransform: 'uppercase',
            minWidth: 34,
          },
          '& .role-alias-text': {
            display: '-webkit-box',
            '-webkit-line-clamp': '1',
            '-webkit-box-orient': 'vertical',
            overflow: 'hidden',
          },
          '& .list-values': {
            maxHeight: 200,
            overflowY: 'scroll',
            color: colors.MAIN_GREY,
            padding: 10,
            paddingLeft: 45,
            fontSize: 14,
            fontFamily: 'ArialMT',
            '&::-webkit-scrollbar': { width: 10 },
            '&::-webkit-scrollbar-track': { width: 5, borderRadius: 10, background: colors.LIGHTER_GREY },
            '&::-webkit-scrollbar-thumb': { borderRadius: 10, background: colors.EXTRA_LIGHT_GREY },
            '& .list-value': {
              padding: 5,
            },
            '& .delete-icon': {
              marginLeft: 'auto',
            },
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          '& .role-alias': {
            width: 34,
            height: 34,
            margin: '1px 8px 1px 0',
            borderRadius: '100%',
            background: colors.LIGHTER_GREY,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textTransform: 'uppercase',
            minWidth: 34,
          },
          '& .role-alias-text': {
            display: '-webkit-box',
            '-webkit-line-clamp': '1',
            '-webkit-box-orient': 'vertical',
            overflow: 'hidden',
          },
          '& .list-values': {
            color: colors.MAIN_GREY,
            padding: 10,
            paddingLeft: 45,
            fontSize: 14,
            fontFamily: 'ArialMT',
            '& .list-value': {
              padding: 5,
            },
          },
        },
      },
    },
  },
});
