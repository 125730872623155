import React from 'react';
import './App.css';
import { RoutesList } from './routes';
import './assets/sass/common.scss';
import { SnackBar } from './components';

function App() {
  return (
    <div className="App">
      <RoutesList />
      <SnackBar />
    </div>
  );
}

export default App;
