import React, { useState, useContext } from 'react';
import { useEffect } from 'react';
import { Grid,Checkbox } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { OnTheTable } from '../table/onTheTable/onTheTable';
import { CONSTANTS } from '../../utils';
import { TableFactory } from '../table/tableFactory/TableFactory';
import { getPatrimonies,getPatrimoniesWithPrestation } from '../../store/patrimonies/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../..';
import GlobalContext from "../../context/GlobalContext";
import { PatrimoniesSelect } from '../PatrimoniesSelect/PatrimoniesSelect';
import {getServiceDeliveryType} from '../../store/serviceDeleviryTypes/actions';

/**
 * component returning the prestations list
 *
 * @returns {ExoticComponent<() => JSX.Element>}
 */
export const PatrimoniesList = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState<number>(CONSTANTS.DEFAULT_PAGE);
  const [perpage, setPerpage] = useState<number>(CONSTANTS.DEFAULT_PER_PAGE);
  const row_description = ['grey', 'grey'];
  const gContext = useContext(GlobalContext);

  const headItems = [
    {
      label: t('patrimony.ref_patrimony'),
      type: 'normal',
      height: '50',
      width: '200',
      value: 'refPat',
    },

    {
      label: t('patrimony.adress_patrimony'),
      type: 'normal',
      height: '50',
      width: '300',
      value: 'streetName',
    },
  ];

  // get redux Parimonies State
  const patrimonies = useSelector((state: RootState) => state.patrimonies.patrimonies);
  const loading = useSelector((state: RootState) => state.patrimonies.loading);
  const serviceDeliveryType = useSelector((state: RootState) => state.serviceDeliveryType.services?.items);

  let filtredPatrimonies = []
  if (gContext?.selectedPatrominy)
    filtredPatrimonies.push(gContext?.selectedPatrominy)
  else {
    filtredPatrimonies = patrimonies
  }
  // get dispatch hooks
  const dispatch = useDispatch();

  useEffect(() => {
    getPatrimoniesList();
    getTypes();
  }, []);

  const getTypes=()=>{
    try {
      dispatch(getServiceDeliveryType());
    } catch (error) {
      console.log('error');
    }
  }
  const getPatrimoniesList = () => {
    try {
      dispatch(getPatrimonies());
    } catch (error) {
      console.log('error');
    }
  };
  const getPatrimoniesWithPrestations = () => {
    var sds =[]
    serviceDeliveryType.map((task) => {
    sds.push(""+task.id)
    })
    try {
      dispatch(getPatrimoniesWithPrestation(sds));
    } catch (error) {
      console.log('error');
    }
  };
  const [value, setValue] = React.useState(false);

  const handlechecked = (v:Boolean) => {
    
    setValue(!value);
    if(!value){
      dispatch(getPatrimoniesWithPrestations());
    }else{
      dispatch(getPatrimonies());
    }
  };
  return (
    <>
      <Grid container rowSpacing={5} direction="column">
        <Grid item>
          <OnTheTable title={t('patrimony.manage_patrimony')} />
        </Grid>
        <Grid item>
        <div style={{width: '340px'}}>
          Patrimoine avec une prestation associée <Checkbox checked={value} onClick={handlechecked}  />
          </div>
        </Grid>
        <PatrimoniesSelect></PatrimoniesSelect>
        <Grid item>
          <TableFactory
            loading={loading}
            name="patrimoins"
            canDelete={false}
            headItems={headItems}
            bodyData={filtredPatrimonies.slice((page - 1) * perpage, page * perpage)}
            rowDescription={row_description}
            count={filtredPatrimonies.length}
            page={page}
            perPage={perpage}
            setValue={setValue}
            handleChangePage={setPage}
            handleChangeRowsPerPage={(event) => {
              setPage(CONSTANTS.DEFAULT_PAGE);
              setPerpage(Number(event.target.value));
            }}
          ></TableFactory>
        </Grid>
      </Grid>
    </>
  );
};
