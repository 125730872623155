import { HIDE_WEEKENDS, WeekendsActionTypes, WeekendsState } from './types';

const INITIAL_STATE: WeekendsState = {
  show: false,
};

export const WeekendsReducer = (state = INITIAL_STATE, action: WeekendsActionTypes) => {
  switch (action.type) {
    case HIDE_WEEKENDS:
      state.show = true;
      break;
    // case SHOW_WEEKENDS:
    //   state.show = true;
    //   break;
    default:
      return state;
  }
};
