import React from 'react';
import { FormControl, MenuItem, Select, TableCell, TableHead, TableRow, ThemeProvider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { tableHeaderTheme } from '../../../theme';
import ArrowDown from '../../ui/Icon/icons/ArrowDown';
import { Description } from '../../usersList/table_data_user';

interface Choice {
  label: string;
  value: string;
}
interface HeadProps {
  items: Array<Description>;
  handleSelectType?: (value: string) => void;
}

/**
 *component returning the head of the table
 *
 *
 */

export const Head = (props: HeadProps) => {
  const { items, handleSelectType } = props;
  const { t } = useTranslation();
  const [choice] = React.useState('');
  return (
    <>
      <ThemeProvider theme={tableHeaderTheme}>
        <TableHead>
          <TableRow tabIndex={-1}>
            {items.map((item: Description, index: number) => (
              <TableCell
                sx={{ height: item.height, width: item.width }}
                align="left"
                key={`table-row-head-${index}`}
                className={item.type === 'select' ? 'select_style' : ''}
              >
                {item.type === 'normal' ? (
                  <> {t(item.label)}</>
                ) : item.type === 'select' ? (
                  <FormControl variant="standard">
                    <Select
                      value={choice}
                      size="small"
                      displayEmpty
                      disableUnderline
                      inputProps={{
                        'aria-label': 'Without label',
                      }}
                      IconComponent={ArrowDown}
                    >
                      <MenuItem value="" onClick={() => handleSelectType(item.label)}>
                        {t(item.label)}
                      </MenuItem>
                      {item.choices &&
                        item.choices.map((selectChoice: Choice, choiceIndex: number) => (
                          <MenuItem key={`item-${choiceIndex}`} value={selectChoice.value} onClick={() => handleSelectType(selectChoice.label)}>
                            {t(selectChoice.label)}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                ) : (
                  <></>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      </ThemeProvider>
    </>
  );
};
