import React, { FunctionComponent } from 'react';
import { Grid } from '@mui/material';
import { PrestationsList } from '../../components/prestationsList/prestationsList';

/**
 * Component PrestationsPage
 *
 * @component
 *
 * @example
 * return (
 *   <PrestationsPage localData={localData}/>
 * )
 */
const PrestationsPage: FunctionComponent = () => {
  return (
    <Grid container justifyContent="center" alignItems="center" className="listing_page_container">
      <PrestationsList />
    </Grid>
  );
};

export default PrestationsPage;
