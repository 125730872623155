import React, { useContext, useEffect, useState } from 'react';
import { AppointmentModel, ChangeSet, EditingState, ValidResourceInstance, ViewState } from '@devexpress/dx-react-scheduler';
import { Scheduler, WeekView, Appointments, Toolbar, DragDropProvider, EditRecurrenceMenu } from '@devexpress/dx-react-scheduler-material-ui';
import GlobalContext from '../../context/GlobalContext';
import LinearProgress from '@mui/material/LinearProgress';
import { colors } from '../../theme/shared-theme';
import { Grid, ThemeProvider } from '@mui/material';
import { scheduleCalendarTheme } from '../../theme';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Icon } from '..';
import { CONSTANTS } from '../../utils';
import { CustomDialog } from '../dialog/customDialog/customDialog';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../index';
interface Calendarprops {
  showWeekends?: boolean;
  tasks?: AppointmentModel[];
  loading?: boolean;
  weekTypeSelected?: boolean;
  updateWeek: () => void;
}
const useStyles = makeStyles({
  scheduler: {
    '& .Cell-dayOfMonth': {
      display: 'none',
    },
  },
});

export const Calendar = (props: Calendarprops) => {
  const { showWeekends = false, tasks = [], loading, weekTypeSelected, updateWeek } = props;
  const gContext = useContext(GlobalContext);
  const listUsers = useSelector((state: RootState) => state.users.users?.items);
  const [currentDate, setCurrentDate] = useState(gContext?.calendarWeek.toDate());
  const [editDialog, setEditDialog] = useState(false);
  const [taskToEdit, setTaskToEdit] = useState<AppointmentModel | null>(null);
  const [showDeleteDialog, setShowChangeDialog] = useState(false);
  const { t } = useTranslation();
  const classes = useStyles();
  useEffect(() => {
    console.log('use effect deps gContext?.calendarWeek,gContext?.weekTypeSelected ');
    var new_date = moment(gContext?.calendarWeek, 'DD-MM-YYYY').add(7, 'days');
    if (gContext?.weekTypeSelected) {
      console.log('yeeeah ==> ', gContext?.weekTypeSelected);
      setCurrentDate(new_date.toDate());
    } else {
      console.log('non ==> ', gContext?.calendarWeek.toDate());
      setCurrentDate(gContext?.calendarWeek.toDate());
    }
  }, [gContext?.calendarWeek, gContext?.weekTypeSelected]);

  useEffect(() => {
    console.log('tasks are changing ', props.tasks);
  }, [props.tasks]);

  const handleOpenEdit = (data: AppointmentModel | null) => {
    console.log('handle open edit , ', data);
    setTaskToEdit(data);
    setEditDialog(true);
  };

  const handleCloseEdit = () => {
    setEditDialog(false);
    setTaskToEdit(null);
  };

  const handelChangeDialog = (value: boolean) => {
    if (!value) {
      handleCloseEdit();
    } else {
      handleOpenEdit(taskToEdit);
    }
  };

  const onDeleteTask = () => {
    setShowChangeDialog(!showDeleteDialog);
  };

  // @ts-ignore
  const Appointment = (appointmentProps: {
    children: React.ReactNode;
    data: AppointmentModel;
    draggable: boolean;
    isShaded?: boolean;
    resources: Array<ValidResourceInstance>;
  }) => {
    console.log('[YASSINE][CALENDAR] data', appointmentProps.data);

    return (
      <Appointments.Appointment
        {...appointmentProps}
        style={{
          opacity: weekTypeSelected ? appointmentProps.data.status === 1 : appointmentProps.data.status === CONSTANTS.STATUS_DONE ? '0.3' : 1,
          backgroundColor: appointmentProps.data.isJobSchedule
            ? appointmentProps.data.collaborators && appointmentProps.data.collaborators[0] && appointmentProps.data.collaborators[0].distinctColor
              ? appointmentProps.data.collaborators[0].distinctColor
              : colors.MAIN_BLUE
            : listUsers
            ? listUsers.find((item) => item.id === appointmentProps.data.collaborators[0].id).distinctColor
            : colors.DARK_GREY,
          color: colors.WHITE,
        }}
        onClick={() => handleOpenEdit(appointmentProps.data)}
      >
        <Grid className="appointment-content">
          <Grid container className="black-text">
            {appointmentProps.data.isJobSchedule ? 'Prestation' : appointmentProps.data.isIntervention ? 'Intervention' : 'Prestation'}
          </Grid>
          <Grid container className="title">
            {appointmentProps.data.types[0].type}
          </Grid>
          {!weekTypeSelected && (
            <Grid container className="date">
              {`${moment(appointmentProps.data.startDate).format('HH:mm')} ${t('common.to')} ${moment(appointmentProps.data.endDate).format(
                'HH:mm',
              )}`}
            </Grid>
          )}
          <Grid container className="ref">
            {appointmentProps.data.refPat}
          </Grid>
          {weekTypeSelected ? (
            <></>
          ) : appointmentProps.data.status === CONSTANTS.STATUS_IN_PROGRESS ? (
            <Icon name={'planningClock'} prefix="domanys" />
          ) : (
            <></>
          )}
          {weekTypeSelected ? (
            <></>
          ) : appointmentProps.data.status === CONSTANTS.STATUS_DONE ? (
            <Icon name={'planningDone'} prefix="domanys" />
          ) : (
            <></>
          )}
        </Grid>
      </Appointments.Appointment>
    );
  };

  const ToolbarWithLoading = ({ children, ...restProps }: { children?: React.ReactNode }) => (
    <>
      <Toolbar.Root {...restProps}>{children}</Toolbar.Root>
      <LinearProgress />
    </>
  );

  const commitChanges = (changeProps: ChangeSet) => {
    console.log('on drop=', changeProps);
    return tasks;
  };

  return (
    <>
      <ThemeProvider theme={scheduleCalendarTheme}>
        {weekTypeSelected ? (
          <div className={classes.scheduler}>
            <Scheduler data={tasks} locale={'fr-FR'} firstDayOfWeek={1}>
              <ViewState defaultCurrentDate={new Date()} currentDate={currentDate} />
              <Toolbar {...(loading ? { rootComponent: ToolbarWithLoading } : null)} />
              <EditingState onCommitChanges={commitChanges} />
              <WeekView cellDuration={60} excludedDays={!showWeekends ? [0, 6] : []} startDayHour={6} endDayHour={20} />
              <Appointments appointmentComponent={Appointment} />
              <EditRecurrenceMenu />
              <DragDropProvider />
            </Scheduler>
          </div>
        ) : (
          <Scheduler data={tasks} locale={'fr-FR'} firstDayOfWeek={1}>
            <ViewState defaultCurrentDate={new Date()} currentDate={currentDate} />
            <Toolbar {...(loading ? { rootComponent: ToolbarWithLoading } : null)} />
            <EditingState onCommitChanges={commitChanges} />
            <WeekView cellDuration={60} excludedDays={!showWeekends ? [0, 6] : []} startDayHour={6} endDayHour={20} />
            <Appointments appointmentComponent={Appointment} />
            <EditRecurrenceMenu />
            <DragDropProvider />
          </Scheduler>
        )}
      </ThemeProvider>
      <CustomDialog
        show={editDialog}
        loading={false}
        title={''}
        changeDialog={handelChangeDialog}
        showDeleteDialog={showDeleteDialog}
        onDelete={onDeleteTask}
        updateWeek={updateWeek}
        setShowChangeDialog={setShowChangeDialog}
        handleCloseEdit={handleCloseEdit}
        canDelete={taskToEdit?.status === CONSTANTS.STATUS_TO_DO}
        showAction={taskToEdit?.status === CONSTANTS.STATUS_TO_DO}
        showStatus={taskToEdit?.status !== CONSTANTS.STATUS_TO_DO}
        weekTypeSelected={weekTypeSelected}
      >
        <Grid>{taskToEdit}</Grid>
      </CustomDialog>
    </>
  );
};
