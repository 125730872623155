import { CONFIG } from '../config/config';
import { CONSTANTS } from '../utils';
import { ENDPOINTS } from '../utils/endpoints';
import { FakeAdmin, FakeDAF, FakeGTI } from '../utils/fakeUsers';
import { encrypt, isDevMode, sendAsyncRequest } from '../utils/helpers';

const login = async (data: { login: string; password: string }) => {
  await sendAsyncRequest('post', ENDPOINTS.LOGIN, undefined, undefined, data)
    .then((response) => {
      localStorage.setItem(CONSTANTS.TOKEN, response.data.accessToken);
      localStorage.setItem(CONSTANTS.USER, encrypt(JSON.stringify(response.data.user)));
      localStorage.setItem('userId', response.data.user.id);
      return response.data;
    })
    .catch(() => {
      if (isDevMode() && CONFIG.FAKE_DATA.includes(data.login)) {
        let fakeuser = null;
        switch (data.login) {
          case CONFIG.ADMIN_FAKE_DATA:
            fakeuser = FakeAdmin;
            break;
          case CONFIG.DAF_FAKE_DATA:
            fakeuser = FakeDAF;
            break;
          case CONFIG.GTI_FAKE_DATA:
            fakeuser = FakeGTI;
            break;
          default:
            fakeuser = FakeAdmin;
            break;
        }
        localStorage.setItem(CONSTANTS.TOKEN, JSON.stringify(fakeuser.token));
        localStorage.setItem(CONSTANTS.USER, encrypt(JSON.stringify(fakeuser)));
        localStorage.setItem('userId', '');
      }
    });
};

const logout = () => {
  localStorage.clear();
};

const getCurrentUser = async () => {
  const response = await sendAsyncRequest('get', ENDPOINTS.WHOAMI, true, undefined, undefined);
  return response.data;
};

export const auth = { login, getCurrentUser, logout };
