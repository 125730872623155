<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <g fill="none" fillRule="evenodd">
    <path d="M0 0h24v24H0z" />
    <path
      d="M15.907 19.568c.41 0 .729-.11.958-.332.23-.222.345-.545.345-.971l.008-.216c0-.437-.081-.857-.245-1.258a5.445 5.445 0 0 0-.635-1.141 8.461 8.461 0 0 0-.846-1 18.176 18.176 0 0 0-.88-.835c-.282-.249-.52-.464-.714-.647a1.912 1.912 0 0 1-.328-.42.974.974 0 0 1-.112-.477.95.95 0 0 1 .112-.473c.075-.132.184-.27.328-.415.194-.188.432-.405.714-.651.282-.247.574-.52.876-.822a8.52 8.52 0 0 0 .842-.984c.26-.354.472-.733.635-1.137.163-.404.245-.833.245-1.287v-.199c0-.431-.115-.757-.345-.975-.23-.219-.549-.328-.958-.328H8.245c-.398 0-.705.108-.921.324-.216.216-.324.523-.324.921v.257c0 .454.083.883.249 1.287.166.404.38.783.643 1.137a9.1 9.1 0 0 0 .843.984c.299.302.588.575.867.822.28.246.52.463.718.651.144.144.253.283.328.415a.95.95 0 0 1 .112.473.946.946 0 0 1-.116.478 2.17 2.17 0 0 1-.324.419c-.199.183-.438.398-.718.647-.28.25-.57.528-.871.835-.302.307-.583.64-.843 1s-.473.74-.639 1.141c-.166.401-.249.82-.249 1.258v.274c0 .398.108.705.324.921.216.216.523.324.921.324h7.662zm-.797-1.162H9.108c-.132 0-.232-.04-.298-.12a.344.344 0 0 1-.071-.291.51.51 0 0 1 .212-.311l2.673-1.993a.514.514 0 0 0 .103-.1.231.231 0 0 0 .038-.14v-3.827c0-.2-.028-.364-.083-.494a1.056 1.056 0 0 0-.316-.386 13.107 13.107 0 0 0-.535-.444 13.862 13.862 0 0 1-.635-.535 4.301 4.301 0 0 1-.523-.548c-.078-.11-.11-.205-.1-.282.011-.078.064-.117.158-.117h4.756c.094 0 .146.04.154.117.008.077-.026.171-.104.282-.133.177-.304.36-.515.548-.21.188-.423.366-.639.535-.216.169-.395.314-.54.436-.149.127-.251.256-.306.386-.056.13-.083.297-.083.502v3.827c0 .06.012.108.037.14a.635.635 0 0 0 .095.1l2.673 1.993a.53.53 0 0 1 .216.31.326.326 0 0 1-.07.291.368.368 0 0 1-.295.12z"
      fill="#FFF"
      fillRule="nonzero"
    />
  </g>
</svg>;

import React from 'react';

export const Historic = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          d="M15.907 19.568c.41 0 .729-.11.958-.332.23-.222.345-.545.345-.971l.008-.216c0-.437-.081-.857-.245-1.258a5.445 5.445 0 0 0-.635-1.141 8.461 8.461 0 0 0-.846-1 18.176 18.176 0 0 0-.88-.835c-.282-.249-.52-.464-.714-.647a1.912 1.912 0 0 1-.328-.42.974.974 0 0 1-.112-.477.95.95 0 0 1 .112-.473c.075-.132.184-.27.328-.415.194-.188.432-.405.714-.651.282-.247.574-.52.876-.822a8.52 8.52 0 0 0 .842-.984c.26-.354.472-.733.635-1.137.163-.404.245-.833.245-1.287v-.199c0-.431-.115-.757-.345-.975-.23-.219-.549-.328-.958-.328H8.245c-.398 0-.705.108-.921.324-.216.216-.324.523-.324.921v.257c0 .454.083.883.249 1.287.166.404.38.783.643 1.137a9.1 9.1 0 0 0 .843.984c.299.302.588.575.867.822.28.246.52.463.718.651.144.144.253.283.328.415a.95.95 0 0 1 .112.473.946.946 0 0 1-.116.478 2.17 2.17 0 0 1-.324.419c-.199.183-.438.398-.718.647-.28.25-.57.528-.871.835-.302.307-.583.64-.843 1s-.473.74-.639 1.141c-.166.401-.249.82-.249 1.258v.274c0 .398.108.705.324.921.216.216.523.324.921.324h7.662zm-.797-1.162H9.108c-.132 0-.232-.04-.298-.12a.344.344 0 0 1-.071-.291.51.51 0 0 1 .212-.311l2.673-1.993a.514.514 0 0 0 .103-.1.231.231 0 0 0 .038-.14v-3.827c0-.2-.028-.364-.083-.494a1.056 1.056 0 0 0-.316-.386 13.107 13.107 0 0 0-.535-.444 13.862 13.862 0 0 1-.635-.535 4.301 4.301 0 0 1-.523-.548c-.078-.11-.11-.205-.1-.282.011-.078.064-.117.158-.117h4.756c.094 0 .146.04.154.117.008.077-.026.171-.104.282-.133.177-.304.36-.515.548-.21.188-.423.366-.639.535-.216.169-.395.314-.54.436-.149.127-.251.256-.306.386-.056.13-.083.297-.083.502v3.827c0 .06.012.108.037.14a.635.635 0 0 0 .095.1l2.673 1.993a.53.53 0 0 1 .216.31.326.326 0 0 1-.07.291.368.368 0 0 1-.295.12z"
          fill="#FFF"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};

export default Historic;
