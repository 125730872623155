import { MenuInterface } from '../interfaces/menu';
import { PATHS } from './paths';

export const menuAdmin: MenuInterface[] = [
  {
    icon: {
      name: 'users',
      prefix: 'domanys',
    },
    title: 'menu.users',
    url: PATHS.Users,
  },
  {
    icon: {
      name: 'form',
      prefix: 'domanys',
    },
    title: 'menu.forms',
    url: '',
    disabled: true,
  },
  {
    icon: {
      name: 'notification',
      prefix: 'domanys',
    },
    title: 'menu.notifications',
    url: PATHS.notifications,
  },
  {
    icon: {
      name: 'article',
      prefix: 'domanys',
    },
    title: 'menu.articles',
    url: '',
    disabled: true,
  },
  {
    icon: {
      name: 'contact',
      prefix: 'domanys',
    },
    title: 'menu.contacts',
    url: '',
    disabled: true,
  },
  {
    icon: {
      name: 'prestation',
      prefix: 'domanys',
    },
    title: 'menu.services',
    url: PATHS.Prestations,
  },
];

export const menuDAF: MenuInterface[] = [
  {
    icon: {
      name: 'intervention',
      prefix: 'domanys',
    },
    title: 'menu.interventions',
    url: PATHS.intervention,
  },
  {
    icon: {
      name: 'users',
      prefix: 'domanys',
    },
    title: 'menu.collaborators',
    url: PATHS.Users,
  },
  {
    icon: {
      name: 'patrimonyMenu',
      prefix: 'domanys',
    },
    title: 'menu.patrimony',
    url: PATHS.Patrimonies,
  },
];

export const menuGTI: MenuInterface[] = [
  {
    icon: {
      name: 'planning',
      prefix: 'domanys',
    },
    title: 'menu.planning',
    url: PATHS.Home,
  },
  {
    icon: {
      name: 'intervention',
      prefix: 'domanys',
    },
    title: 'menu.interventions',
    url: PATHS.intervention,
  },
  {
    icon: {
      name: 'users',
      prefix: 'domanys',
    },
    title: 'menu.my_collaborators',
    url: PATHS.Users,
  },
  {
    icon: {
      name: 'notification',
      prefix: 'domanys',
    },
    title: 'menu.notifications',
    url: PATHS.notifications,
  },
];
