import React from 'react';

export const IdCard = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" style={{ background: '#131415' }} {...props}>
      <g fill="none" fillRule="evenodd">
        <path fill="#131415" d="M0 0h24v24H0z" />
        <path stroke="#FFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" d="M3 6h18v12H3z" />
        <path
          d="M8.504 11.997c.299 0 .574-.075.827-.227.253-.152.456-.358.61-.618.153-.26.23-.552.23-.878a1.61 1.61 0 0 0-.23-.851 1.715 1.715 0 0 0-.61-.601 1.61 1.61 0 0 0-.827-.222 1.61 1.61 0 0 0-.829.224 1.72 1.72 0 0 0-.611.605 1.59 1.59 0 0 0-.226.853 1.721 1.721 0 0 0 .836 1.487c.252.153.53.228.83.228zm2.588 3.603c.336 0 .572-.045.706-.135a.449.449 0 0 0 .202-.398c0-.252-.081-.515-.243-.79a2.833 2.833 0 0 0-.702-.774 3.789 3.789 0 0 0-1.103-.586A4.376 4.376 0 0 0 8.5 12.69c-.538 0-1.023.075-1.454.226-.431.15-.799.346-1.103.586-.304.241-.537.499-.7.774-.162.275-.243.538-.243.79 0 .175.067.307.202.398.134.09.368.135.702.135h5.188z"
          fill="#FFF"
          fillRule="nonzero"
        />
        <path stroke="#FFF" strokeWidth="1.5" strokeLinecap="round" d="M14 10.8h2M14 13.8h4" />
      </g>
    </svg>
  );
};

export default IdCard;
