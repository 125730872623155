import React from 'react';
import { Grid } from '@mui/material';
import UsersList from '../../components/usersList/usersList';
import {useSelector} from "react-redux";
import {RootState} from "../../index";

/**
 * Component UsersPage
 *
 * @component
 *
 * @example
 * return (
 *   <UsersPage />
 * )
 */

const UsersPage = () => {
  return (
    <Grid container alignItems="center" justifyContent="center" className="listing_page_container">
      <UsersList />
    </Grid>
  );
};

export default UsersPage;
