export const ENDPOINTS = {
  LOGIN: '/users/login',
  WHOAMI: '/whoami',
  USERS: '/users',
  AGENCIES: '/agencies',
  ROLES: '/roles',
  PATRIMONIES: '/patrimonies',
  PATRIMONIESWITHPREST: '/patrimonies-with-servicedelivery',
  SERVICES: '/service-delivery-types',
  SERVICES_AVAILABLES: '/service-delivery-type-availables',
  INTERVENTIONS: '/service-deliveries/interventions',
  EXPORT: 'service-deliveries/export-interventions',
  EXPORT_TYPE: '?export_type=',
  STARTDATE: '&start_date=',
  ENDDATE: '&end_date=',
  SERVICEDELIVERIES: '/service-deliveries',
  SERVICEDELIVERIESGTI: '/service-deliveries/interventions',
  MyCalendar: '/week-templates/mycalendar',
  MyCalendarJob: '/mycalendar',
  JobSchedule: '/JobSchedule',
  JobScheduleMin: '/job-schedules',
  WeekTemplates: '/week-templates',
  Calendar: '/calendar',
  UPDATE_STATUS: '/update-status',
  UPDATE_SERVICE_STATUS: '/service-deliveries/status',
  VALIDATE_WEEK: '/service-deliveries/collab/validateWeek',
};
