import React, { useEffect, useState } from 'react';
import {
  Box,
  FormControl,
  FormHelperText,
  InputAdornment,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  LinearProgress,
  ThemeProvider,
  Grid,
} from '@mui/material';
import { dialogSelectTheme } from '../../theme';
import { Button } from '..';

export interface MultiSelectItem {
  label: JSX.Element;
  secondaryText?: string;
  value: string;
}

interface SelectMultiProps {
  label?: string;
  values: MultiSelectItem[];
  error?: boolean | string;
  onChange: (value: MultiSelectItem[]) => void;
  icon: React.ComponentType<{ className: string; color: string | undefined }>;
  items: MultiSelectItem[];
  iconColor?: string;
  className?: string;
  loading?: boolean;
  disabled?: boolean;
}

export const SelectMulti = (props: SelectMultiProps) => {
  const { label, onChange, error, values = [], items, iconColor, className, loading, disabled } = props;
  console.log('in select multi')
  console.log('values == ', values)
  console.log('label == ', label)
  const SelectIcon = props.icon;
  const [itemsList, setItemsList] = useState<MultiSelectItem[]>([]);
  const [deleteVisible, setDeleteVisible] = useState<boolean[]>([]);

  const onChangeArray = (e: SelectChangeEvent<string>) => {
    const selectedItem = items.find((item) => item.value === e.target.value);
    if (selectedItem && values.findIndex((value) => value.value === e.target.value) < 0) {
      onChange([...values, selectedItem]);
      setItemsList((prev) => [...prev.filter((item) => item.value !== e.target.value)]);
    }
  };

  const onDelete = (value: MultiSelectItem) => {
    const filtredValues = values.filter((item) => item.value !== value.value);
    onChange(filtredValues);
    setItemsList((prev) => [...prev, value]);
  };

  const changeVisibility = (index: number) => {
    const array = [];
    array[index] = true;
    setDeleteVisible(array);
  };

  useEffect(() => {
    setItemsList([...items]);
  }, [items]);

  return (
    <ThemeProvider theme={dialogSelectTheme}>
      <FormControl variant="standard" fullWidth>
        <Select
          disabled={disabled}
          fullWidth
          MenuProps={{
            PaperProps: {
              className: className,
            },
          }}
          displayEmpty
          disableUnderline
          className={'select_style'}
          {...(error && { error: true })}
          IconComponent={Box}
          size="small"
          label={label}
          onChange={onChangeArray}
          value=""
          startAdornment={
            <InputAdornment position="start">
              <SelectIcon className={'icon'} color={iconColor} />
            </InputAdornment>
          }
          inputProps={{
            'aria-label': label,
          }}
        >
          <MenuItem value="">
            <ListItemText primary={label} />
          </MenuItem>

          {loading ? (
            <MenuItem>
              <LinearProgress />
            </MenuItem>
          ) : (
            itemsList.map((item: MultiSelectItem, index: number) => (
              <MenuItem key={`item-${index}`} value={item.value}>
                <ListItemText
                  primary={<Typography>{item.label}</Typography>}
                  secondary={<Typography sx={{ fontSize: '9px !important' }}>{item.secondaryText}</Typography>}
                />
              </MenuItem>
            ))
          )}
        </Select>
        <Grid container direction="column" className="list-values" justifyContent="center" alignItems="flex-start" wrap="nowrap">
          {values &&
            values.map((value, index) => (
              <Grid
                container
                key={index}
                className="list-value"
                wrap="nowrap"
                alignItems="center"
                onMouseEnter={() => changeVisibility(index)}
                onMouseLeave={() => setDeleteVisible([])}
              >
                {value.label}
                <Button
                  style={{ opacity: deleteVisible[index] ? '1' : '0' }}
                  className={'delete-icon'}
                  onClick={() => onDelete(value)}
                  startIcon={{
                    name: 'close',
                    prefix: 'domanys',
                  }}
                />
              </Grid>
            ))}
        </Grid>
        {error && <FormHelperText className={'helper_text'}>{error}</FormHelperText>}
      </FormControl>
    </ThemeProvider>
  );
};
