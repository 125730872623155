export const common = {
  identifier: 'Identifiant',
  password: 'Mot de passe',
  input_error_message: 'Identifiant ou mot de passe incorrect',
  login: 'LogIn',
  home: 'Accueil',
  connect: 'Se connecter',
  users: 'Utilisateurs',
  forms: 'Formulaires',
  notifications: 'Notifications',
  articles: 'Articles',
  contacts: 'Contacts',
  prestations: 'Prestations',
  profile: 'Profile',
  create_user: 'Créer un utilisateur',
  first_name: 'Prénom',
  last_name: 'Nom',
  type: 'Type',
  function: 'Fonction',
  last_connetion: 'DERNIERE CONNEXION',
  account: 'COMPTE',
  activated: 'Activé',
  disactivated: 'Désactivé',
  disconnection: 'Déconnexion',
  validate: 'Valider',
  cancel: 'Annuler',
  madam: 'Madame',
  mister: 'Monsieur',
  delete_request_message: 'Voulez vous supprimer cet utilisateur ?',
  save: 'Enregistrer',
  construction: 'coming soon...',
  activate: 'Activer',
  disable: 'Désactiver',
  create: 'create',
  export: 'export',
  agency: 'Agence',
  no_data: 'Aucune donnée disponible',
  to: 'à',
};
