import { Dialog, DialogContent, Stack, ThemeProvider, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import 'moment/locale/fr';
import moment from 'moment';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { dialogTheme } from '../../../theme';
import { DialogActions } from '../dialogActions/dialogActions';
import { User } from '../../../store/user/types';
import { APIS } from '../../../api';
import GlobalContext from '../../../context/GlobalContext';
import { errorMsg } from '../../../utils';
import { showSnackBar } from '../../../store/snackBar/actions';

interface ValidateWeekDialogProps {
  show?: boolean;
  changeDialog: (value: boolean) => void;
  collaboratorDetails: User;
  updateWeek: () => void;
}

const useStyles = makeStyles({
  dialog_width: {
    '& .MuiDialog-paper': {
      height: '197px',
    },
  },
});

export const ValidateWeekDialog = (props: ValidateWeekDialogProps) => {
  const { show = false, changeDialog, collaboratorDetails } = props;
  const [open, setOpen] = React.useState(show);
  const { t } = useTranslation();
  const classes = useStyles();

  const gContext = useContext(GlobalContext);

  const handleCancellation = () => {
    setOpen(false);
    changeDialog(false);
  };

  const handleOnAction = () => {
    let calendar_date = moment(gContext?.calendarWeek, 'DD-MM-YYYY').subtract('days', 6);

    APIS.serviceDelivery.updateServiceDeliveryStatusWeek(props.collaboratorDetails.id, calendar_date.add(7, 'days').toDate()).then((resp) => {
      props.updateWeek();
      setOpen(!show);
    });
  };

  useEffect(() => {
    setOpen(show);
  }, [show]);

  return (
    <>
      <ThemeProvider theme={dialogTheme}>
        <Dialog onClose={handleCancellation} open={open} className={classes.dialog_width}>
          <DialogContent>
            <Stack direction="column" m={3}>
              <Typography className="validate_week">
                {t('planning.validate_week_of') + ' ' + collaboratorDetails.firstname + ' ' + collaboratorDetails.lastname + ' ?'}
              </Typography>
            </Stack>
          </DialogContent>

          <DialogActions type={'create'} secondButtonLabel={'Valider'} onClose={handleCancellation} onAction={handleOnAction} />
        </Dialog>
      </ThemeProvider>
    </>
  );
};
