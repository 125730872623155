import { Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../../assets/sass/historyBar.module.scss';
interface Item {
  date: string;
  prestation: string;
  collaborator: string;
  status: string;
}

interface HistoryItemProps {
  data: Item;
}
export const HistoryItem = (props: HistoryItemProps) => {
  const { data } = props;
  const { t } = useTranslation();

  return (
    <>
      <Stack style={{ minWidth: '243px', marginLeft: '24px' }} direction="row" justifyContent="space-between" alignItems="flex-start">
        <Stack direction="column">
          <Typography className={styles.date_title}>{data.date}</Typography>
          <Typography className={styles.prestation_title}>{data.prestation}</Typography>
          <Typography className={styles.collaborator_title}>{data.collaborator}</Typography>
        </Stack>
        <Typography
          sx={{ marginRight: '16px' }}
          className={
            data.status === t('history.deleted')
              ? styles.status_red_color
              : data.status === t('history.modified')
              ? styles.status_orange_color
              : styles.status_green_color
          }
        >
          {data.status}
        </Typography>
      </Stack>
    </>
  );
};
