import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from '../../../theme/shared-theme';

export const FromTextBox = () => {
  const { t } = useTranslation();
  return (
    <>
      <Box sx={{ width: 20 }}>
        <Typography sx={{ fontFamily: 'ArialMT', fontSize: '14px', color: colors.MAIN_GREY }}>{t('intervention.from')}</Typography>
      </Box>
    </>
  );
};
