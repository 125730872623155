import {User} from '../auth/model';

export const GET_SERVICE_DELIVERY = 'serviceDelivery/getAll';
export const GETTING_SERVICE_DELIVERY = 'serviceDelivery/loading';
export const GET_SERVICE_DELIVERY_ERROR = 'serviceDelivery/fetchError';
export const ADD_SERVICE_DELIVERY = 'serviceDelivery/add';
export const ADDING_SERVICE_DELIVERY = 'serviceDelivery/loading';
export const UPDATE_SERVICE_DELIVERY = 'serviceDelivery/update';
export const UPDATING_SERVICE_DELIVERY = 'serviceDelivery_update/loading';
export const EXPORTING_SERVICE_DELIVERY = 'serviceDelivery_export/loading';
export const EXPORT_SERVICE_DELIVERY = 'serviceDelivery/export';
export const DELETE_SERVICE_DELIVERY = 'serviceDelivery/delete';
export const PURGE_SERVICE_DELIVERY = 'serviceDelivery/purge';
export const ADD_INTERVENTION = 'addIntervention/loading';
export const ADD_INTERVENTION_SUCCESS = 'addIntervention/success';
export const ADD_INTERVENTION_ERROR = 'addIntervention/error';
export const DELETE_INTERVENTION = 'intervention/delete'
export const GET_SD = 'GET_SD/sdByid';

// INTERFACE SERVICE_DELIVERY
export interface ServiceDelivery {
    date: string;
    id: string;
    outOfStock: boolean;
    refPat: string;
    status: string;
    weekTemplateId: string;
    initialTimeRange: string[];
    type: string;
    collaborators: User[];
    comment: string;
    duration: number;
    type_id: string;
}

// INTERFACE SERVICE_DELIVERY_PAGINATED
export interface ServiceDeliveriesPaginated {
    filter(arg0: (serviceDelivery: ServiceDelivery) => boolean): ServiceDeliveriesPaginated;

    items?: ServiceDelivery[];
    lastPage?: number;
    totalItems?: number;
}

// INTERFACE SERVICE_DELIVERY_STATE
export interface ServiceDeliveryState {
    serviceDeliveries: ServiceDeliveriesPaginated;
    sd: ServiceDelivery;
    loading: boolean;
    addLoading: boolean;
}

interface LoadingListAction {
    type: typeof GETTING_SERVICE_DELIVERY;
}

interface ListErrorAction {
    type: typeof GET_SERVICE_DELIVERY_ERROR;
}

// GET_SERVICE_DELIVERY_ACTION
interface GetServiceDeliveryAction {
    type: typeof GET_SERVICE_DELIVERY;
    payload: ServiceDeliveriesPaginated;
}

// SHOW_SD_ACTION
interface GetSDAction {
    type: typeof GET_SD;
    payload: ServiceDelivery;
}

// ADD_SERVICE_DELIVERY_ACTION
interface AddServiceDeliveryAction {
    type: typeof ADD_SERVICE_DELIVERY | typeof ADDING_SERVICE_DELIVERY;
}

// UPDATE_SERVICE_DELIVERY_ACTION
interface UpdateServiceDeliveryAction {
    type: typeof UPDATE_SERVICE_DELIVERY | typeof UPDATING_SERVICE_DELIVERY;
}

// EXPORT_SERVICE_DELIVERY_ACTION
interface ExportServiceDeliveryAction {
    type: typeof EXPORT_SERVICE_DELIVERY | typeof EXPORTING_SERVICE_DELIVERY;
}

// PURGE_SERVICE_DELIVERY_ACTION
interface PurgeServiceDeliveryAction {
    type: typeof PURGE_SERVICE_DELIVERY;
}

// DELETE SERVICE_DELIVERY ACTION
interface DeleteServiceDeliveryAction {
    payload: string;
    type: typeof DELETE_SERVICE_DELIVERY;
}

// DELETE INTERVENTION ACTION
interface DeleteInterventionAction {
    payload: string;
    type: typeof DELETE_INTERVENTION;
}

interface AddInterventionAction {
    type: typeof ADD_INTERVENTION;
}

interface AddInterventionSuccessAction {
    type: typeof ADD_INTERVENTION_SUCCESS;
}

interface AddInterventionErrorAction {
    type: typeof ADD_INTERVENTION_ERROR;
}

export type ServiceDeliveryActionTypes =
    | GetServiceDeliveryAction
    | GetSDAction
    | AddServiceDeliveryAction
    | UpdateServiceDeliveryAction
    | ExportServiceDeliveryAction
    | PurgeServiceDeliveryAction
    | DeleteServiceDeliveryAction
    | LoadingListAction
    | ListErrorAction
    | AddInterventionAction
    | AddInterventionSuccessAction
    | AddInterventionErrorAction
    | DeleteInterventionAction;
