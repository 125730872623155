import React from 'react';
import { Box, Checkbox, Grid, Stack, Typography } from '@mui/material';
import { colors } from '../../../theme/shared-theme';
import { View } from '../../ui/Icon/icons/View';
import styles from '../../../assets/sass/checkBoxListItem.module.scss';

interface CheckboxPrestationItemProps {
  type: string;
  duration: string;
  color?: string;
  functionAbreviation?: string;
  handlechecked: (value: boolean) => void;
  value?: boolean;
}

export const CheckboxPrestationItem = (props: CheckboxPrestationItemProps) => {
  const { type, duration, value = false, handlechecked } = props;
  return (
    <>
      <Grid container direction="row" alignItems="center" columnSpacing={1} wrap="nowrap">
        <Grid item>
          <Checkbox value={value} onChange={(e) => handlechecked(e.target.checked)} defaultChecked disableRipple />
        </Grid>
        <Grid item>
          <Box
            sx={{
              paddingLeft: '16px',
              paddingTop: '4px',
              border: `1px solid ${colors.LIGHT_GREY}`,
              width: '210px',
              height: '40px',
              borderRadius: '4px',
            }}
            className={styles.icon_hover}
          >
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack direction="column">
                <Typography>{type}</Typography>
                <Typography
                  style={{
                    fontSize: '11px',
                    color: colors.MEDIUM_LIGHT_GREY,
                  }}
                >
                </Typography>
              </Stack>
              <View className={styles.hidden_cell} />
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
