import React, { useContext, useEffect, useState } from 'react';
import { Box, Grid, Stack, ThemeProvider } from '@mui/material';
import { SideBar } from '../sideBar/sideBar';
import { sideBarTheme } from '../../theme/sideBar-theme';
import styles from '../../assets/sass/historyBar.module.scss';
import Historic from '../ui/Icon/icons/Historic';
import { HistoryBar } from '../History/HistoryBar';
import { CheckBoxContext } from '../../context/checkBoxContext';
import { getServiceDeliveryByPatrimoine, getServiceDeliveryPlanning } from '../../store/intervention/action';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../..';
import { ENDPOINTS, formatCalendarDate } from '../../utils';
import { AppointmentModel } from '@devexpress/dx-react-scheduler';
import GlobalContext from '../../context/GlobalContext';
import { getUsers } from '../../store/user/actions';
import { getServiceDeliveryType } from '../../store/serviceDeleviryTypes/actions';
import { ServiceDeliveryType } from '../../store/serviceDeleviryTypes/types';
import { User } from '../../store/user/types';
import { Calendar } from '../calendar/calendar';
import { APIS } from '../../api';
import moment from 'moment';
import 'moment/locale/fr';
import { setMyCalendarData } from '../../store/myCalendar/actions';
import { CONSTANTS, getRole } from '../../utils';

export const PlanningDashboard = () => {
  const [historyBarOpen, setHistoryBarOpen] = useState(false);
  const [loadingTask, setLoading] = useState(false);
  const [dateOfSelectedWeek, setDateOfSelectedWeek] = useState(moment().format('YYYY-MM-DD'));
  const [tasksList, setTasksList] = useState<AppointmentModel[]>([]);
  const dispatch = useDispatch();
  const interventionsList = useSelector((state: RootState) => state.interventions.serviceDeliveries);
  const userFromSd = useSelector((state: RootState) => state.interventions.serviceDeliveries);
  const interventionsListLoading = useSelector((state: RootState) => state.interventions.loading);
  const gContext = useContext(GlobalContext);
  // get redux Collaborators List state
  const listUsers = useSelector((state: RootState) => state.users.users?.items);
  const loadingUsers = useSelector((state: RootState) => state.users.loading);
  // get redux ServiceDeliverTypes State
  const serviceDeliveryType = useSelector((state: RootState) => state.serviceDeliveryType.services?.items);
  const loadingServiceDeliveryType = useSelector((state: RootState) => state.serviceDeliveryType.loading);
  const [checked, setWeekendsVisible] = useState(false);
  const [filtredUsers, setFiltredUsers] = useState<User[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [filtredServiceDeliveryType, setFiltredServiceDeliveryType] = useState<ServiceDeliveryType[]>([]);
  const [selectedServiceDeliveryType, setSelectedServiceDeliveryType] = useState<ServiceDeliveryType[]>([]);
  const getCurrentWeekCalendar = (dt?: any) => {
    var new_date = moment(gContext?.calendarWeek, 'DD-MM-YYYY').add(7, 'days');
    if (gContext?.weekTypeSelected) {
      dt = moment(new_date).format('YYYY-MM-DD');
    }
    const url = ENDPOINTS.MyCalendarJob;
    let sdt: string[] = [];
    let collab: string[] = [];
    selectedServiceDeliveryType.map((item) => {
      sdt.push(item.id + '');
      return sdt;
    });
    selectedUsers.map((item) => {
      collab.push(item.id + '');
      return collab;
    });
    const params: any = {};
    if (collab.length > 0) {
      params['collab_ids'] = collab;
    }
    if (sdt.length > 0) {
      params['sdt_ids'] = sdt;
    }
    if (gContext?.selectedPatrominy?.refPat !== undefined) {
      params['pat_ids[]'] = gContext?.selectedPatrominy?.refPat;
    }
    gContext?.weekTypeSelected ? (params['week_date'] = dt) : (params['week_date'] = moment(gContext?.calendarWeek).format('YYYY-MM-DD'));
    params['empty_pats'] = true;
    if (collab.length > 0 && sdt.length > 0) {
      setLoading(true);

      APIS.serviceDelivery.getServiceJob(params, url).then((response) => {
        setLoading(false);
        const tasksList = [];
        response.calendar.map((item, index) => {
          for (let i = 0; i < item.tasks.job_schedules.length; i++) {
            var obj = item.tasks.job_schedules[i];
            obj.date = item.date;
            obj.isJobSchedule = true;
            tasksList.push(item.tasks.job_schedules[i]);
          }
          for (let i = 0; i < item.tasks.service_deliveries.length; i++) {
            tasksList.push(item.tasks.service_deliveries[i]);
          }
        });
        setTasksList(convertTaskList(tasksList));
      });
    }
  };

  const getData = () => {
    try {
      dispatch(getUsers());
      dispatch(getServiceDeliveryType());
      //  getInterventionsList();
    } catch (error) {
      console.log('error');
    }
  };
  const toggleDrawer = (open: boolean) => () => {
    setHistoryBarOpen(open);
  };
  const setLoaderStatus = (isLoading: boolean) => () => {
    setLoading(false);
  };
  const getInterventionsList = async () => {
    const id = [];
    id.push(gContext?.selectedPatrominy?.refPat ? gContext?.selectedPatrominy?.refPat : '');
    try {
      dispatch(getServiceDeliveryByPatrimoine(id));
    } catch (error) {
      console.log('error');
    }
  };
  const days = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];
  const getDayKey = (day: string) => {
    const repeatKey = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'];
    const dayOrder = days.indexOf(day);
    return repeatKey[dayOrder];
  };
  const getDateInCurrentWeek = (day: string) => {
    const dayOrder = days.indexOf(day);
    const curr = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000); // get current date
    const first = curr.getDate() - curr.getDay() + 1;
    const requestDay = first + dayOrder;
    const responseDay = new Date(curr.setDate(requestDay));
    return responseDay;
  };
  const convertTaskList = (data) => {
    if (data && data?.length > 0) {
      const formattedList: AppointmentModel[] = [];
      data.map((item) => {
        if (item.isJobSchedule == true) {
          if (
            gContext?.weekTypeSelected ||
            (getDateInCurrentWeek(item.createdAt) <= formatCalendarDate(item.date, item.initialTimeRange[0]) && !gContext?.weekTypeSelected)
          ) {
            const startDateTime = formatCalendarDate(item.date, item.initialTimeRange[0]);
            const endDateTime = formatCalendarDate(item.date, item.initialTimeRange[1]);
            formattedList.push({
              ...item,
              startDate: startDateTime,
              endDate: endDateTime,
              title: item.types[0].type,
              //rRule: 'FREQ=WEEKLY;BYDAY=' + getDayKey(item.weekday),
              isJobSchedule: true,
            });
          }
        } else if (!gContext?.weekTypeSelected) {
          const startDateTime = formatCalendarDate(item.date, item.initialTimeRange[0]);
          const endDateTime = formatCalendarDate(item.date, item.initialTimeRange[1]);
          formattedList.push({
            ...item,
            startDate: startDateTime,
            endDate: endDateTime,
            title: item.type,
            isJobSchedule: false,
          });
        }
      });
      return [...formattedList];
    }
    return [];
  };

  const handleChnageSelectedServiceDeliveryType = (list: string[]) => {
    if (serviceDeliveryType) {
      const newFiltred = filtredServiceDeliveryType.filter((itemInArray) => list.includes(itemInArray.id));
      setSelectedServiceDeliveryType(newFiltred);

      if (!gContext?.weekTypeSelected) {
        const initialList = convertTaskList(interventionsList);
        const filtredList = initialList.filter((item) => list.some((r) => item.type_id === r || !item.type_id));
        setTasksList([...filtredList]);
      }
    }
  };

  const handlecheckedcollabs = (list: string[]) => {
    console.log('[YASSINE] Checked collab');
    if (list.length > 0) {
      if (filtredUsers) {
        const newFiltred = filtredUsers.filter((itemInArray) => list.includes(itemInArray.id));
        setSelectedUsers(newFiltred);
        if (!gContext?.weekTypeSelected) {
          const initialList = convertTaskList(interventionsList);
          const filtredList = initialList.filter((item) =>
            list.some((r) => {
              return item.collaborators.findIndex((collab: User) => collab.id === r) >= 0;
            }),
          );
          setTasksList([...filtredList]);
        }
      }
    } else {
      setSelectedUsers([]);
      setTasksList([]);
    }
  };
  useEffect(() => {
    console.log('gContext?.calendarWeek === ', gContext?.calendarWeek);
    const dt = moment(gContext?.calendarWeek).format('YYYY-MM-DD');
    setDateOfSelectedWeek(dt);
    getCurrentWeekCalendar(dt);
  }, [gContext?.calendarWeek]);
  useEffect(() => {
    try {
      APIS.calendarService.getMyCalendar({ empty_pats: true }).then((data) => {
        dispatch(setMyCalendarData(data));
        localStorage.setItem('weekTemplateId', data.weekTemplateId);
      });
    } catch (error) {
      console.log('error handleChangeSemaineType');
    }
    getData();
    getCurrentWeekCalendar();
    gContext?.handleChangePatrimony(null);
  }, []);

  useEffect(() => {
    setFiltredUsers([...(listUsers || [])]);
  }, [listUsers]);

  useEffect(() => {
    setFiltredServiceDeliveryType([...(serviceDeliveryType || [])]);
    setSelectedServiceDeliveryType([...(serviceDeliveryType || [])]);
  }, [serviceDeliveryType]);

  // on change patrimony from header filter data
  useEffect(() => {
    console.log('changed');
    if (!loadingServiceDeliveryType && !loadingUsers && !loadingTask) {
      if (gContext?.weekTypeSelected) {
        getCurrentWeekCalendar();
        // getWeekTypeCalendar();
      } else {
        getCurrentWeekCalendar();
      }
    }
    //  getInterventionsList();
  }, [gContext?.selectedPatrominy]);

  useEffect(() => {
    if (gContext?.weekTypeSelected) {
      getCurrentWeekCalendar();
      // getWeekTypeCalendar();
    } else {
      getCurrentWeekCalendar();
      //   setTasksList(convertTaskList(interventionsList));
    }
  }, [interventionsList]);

  useEffect(() => {
    if (gContext?.weekTypeSelected) {
      getCurrentWeekCalendar();
      // getWeekTypeCalendar();
    } else {
      getCurrentWeekCalendar();
      //  getData();
    }
  }, [gContext?.weekTypeSelected]);
  //
  // useEffect(() => {
  //     // getWeekTypeCalendar();
  // }, [selectedUsers, selectedServiceDeliveryType]);
  useEffect(() => {
    getCurrentWeekCalendar();
  }, [selectedUsers, selectedServiceDeliveryType]);
  return (
    <>
      <CheckBoxContext.Provider value={{ checked, setWeekendsVisible }}>
        <Stack direction="column" justifyContent="space-between" alignItems="flex-end" spacing={2}>
          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" sx={{ backgroundColor: 'white' }} wrap="nowrap">
            <Grid item>
              <ThemeProvider theme={sideBarTheme}>
                <SideBar
                  userFromSd={userFromSd}
                  users={filtredUsers}
                  loadingUsers={loadingUsers}
                  serviceDeliveryType={filtredServiceDeliveryType}
                  loadingServiceDeliveryType={loadingServiceDeliveryType}
                  handlecheckedServiceType={handleChnageSelectedServiceDeliveryType}
                  handlecheckedcollabs={handlecheckedcollabs}
                  updateWeek={getCurrentWeekCalendar}
                />
              </ThemeProvider>
            </Grid>
            <Grid item className={styles.calendar_container}>
              <Calendar
                showWeekends={checked}
                tasks={tasksList}
                loading={loadingTask}
                weekTypeSelected={gContext?.weekTypeSelected}
                updateWeek={getCurrentWeekCalendar}
              />
            </Grid>
          </Grid>
          <Box onClick={toggleDrawer(true)} className={styles.history_button}>
            <Historic className={styles.icon}></Historic>
          </Box>
          <HistoryBar state={historyBarOpen} onClose={toggleDrawer(false)} onOpen={toggleDrawer(true)}></HistoryBar>
        </Stack>
      </CheckBoxContext.Provider>
    </>
  );
};
