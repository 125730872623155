import React from 'react';

export const Export = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path fill="#FFF" d="M0 0h24v24H0z" />
        <path
          d="M12.086 8.517a.756.756 0 0 0 .548-.225.723.723 0 0 0 .23-.534V5.133l-.066-1.313.572.638 1.285 1.36c.137.155.31.234.515.234.2 0 .368-.063.502-.188a.624.624 0 0 0 .201-.478.67.67 0 0 0-.225-.497l-2.98-2.887a.896.896 0 0 0-.291-.207.776.776 0 0 0-.582 0 .809.809 0 0 0-.28.207L8.522 4.889a.67.67 0 0 0-.225.497c0 .194.066.353.197.478a.692.692 0 0 0 .497.188.668.668 0 0 0 .525-.235l1.285-1.36.58-.646-.065 1.322v2.625a.73.73 0 0 0 .225.534.74.74 0 0 0 .544.225zm5.09 13.397c.95 0 1.665-.237 2.143-.712.478-.475.717-1.179.717-2.11v-8.569c0-.93-.24-1.636-.717-2.114-.478-.478-1.192-.717-2.142-.717H13.08v6.478a.919.919 0 0 1-.296.675.962.962 0 0 1-.698.29.952.952 0 0 1-.69-.29.919.919 0 0 1-.294-.675V7.692H6.986c-.944 0-1.656.238-2.138.713-.48.475-.721 1.18-.721 2.118v8.57c0 .93.24 1.634.721 2.109.482.475 1.194.712 2.138.712h10.19z"
          fill="#72706f"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};

export default Export;
