import React, { useContext } from 'react';
import { Checkbox, Grid, Stack, ThemeProvider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Button } from '..';
import { CheckBoxContext } from '../../context/checkBoxContext';
import { sideBarTheme } from '../../theme/sideBar-theme';
import { PlanningCreateDialog } from '../dialog/planningDialog/planningCreateDialog';
import { CollaboratorsList } from './collaboratorsList/collaboratorsList';
import { ListTitle } from './listTitle.tsx/listTitle';
import { PatrimonyDetails } from './patrimonyDetails/patrimonyDetails';
import { PrestationsList } from './prestationsList/prestationsList';
import GlobalContext from '../../context/GlobalContext';
import { User } from '../../store/user/types';

interface PrestationItem {
  type: string;
  duration: string;
  id: string;
}
interface SideBarProps {
  userFromSd: Object;
  users: User[];
  loadingUsers: boolean;
  serviceDeliveryType: PrestationItem[];
  loadingServiceDeliveryType: boolean;
  handlecheckedServiceType: (value: string[]) => void;
  handlecheckedcollabs: (value: string[]) => void;
  updateWeek: () => void;
}

export const SideBar = (props: SideBarProps) => {
  const { userFromSd, users, loadingUsers, serviceDeliveryType, loadingServiceDeliveryType, handlecheckedServiceType, handlecheckedcollabs } = props;
  const { t } = useTranslation();

  const [openCreate, setopenCreate] = React.useState(false);
  const [showCollaborators, setShowCollaborators] = React.useState(false);
  const [showPrestations, setShowPrestations] = React.useState(false);
  const { setWeekendsVisible, checked } = useContext(CheckBoxContext);
  const gContext = useContext(GlobalContext);

  const handleShowCollaborators = (clicked: boolean) => {
    setShowCollaborators(clicked);
  };
  const handleShowPrestations = (clicked: boolean) => {
    setShowPrestations(clicked);
  };
  const handleShowWeekends = () => {
    setWeekendsVisible(!checked);
  };
  const showCreateDialog = () => {
    setopenCreate(!openCreate);
  };

  return (
    <>
      <ThemeProvider theme={sideBarTheme}>
        <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" rowSpacing={2}>
          <Grid item>
            <Button
              className={'button'}
              variant="outlined"
              type="primary"
              size="large"
              label={t('planning.create')}
              onClick={showCreateDialog}
              startIcon={{
                name: 'plus',
                prefix: 'domanys',
                className: 'button-icon',
              }}
            />
          </Grid>
          {gContext?.selectedPatrominy && (
            <Grid item>
              <PatrimonyDetails
                reference={gContext?.selectedPatrominy?.refPat || ''}
                adress={`${gContext?.selectedPatrominy?.streetNumber} ${gContext?.selectedPatrominy?.streetName}, ${gContext?.selectedPatrominy?.insee}`}
              />
            </Grid>
          )}
          <Grid item>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
              <Checkbox onClick={handleShowWeekends} checked={checked} className={'grey'} disableRipple />

              <Typography>{t('planning.show_weekends')}</Typography>
            </Stack>
          </Grid>
          <Grid item>
            <ListTitle onClicked={handleShowCollaborators} title={t('planning.my_collaborators')} />
          </Grid>

          <Grid item style={{ display: showCollaborators ? 'block' : 'none' }}>
            <CollaboratorsList
              userFromSd={userFromSd.collabs}
              data={users}
              loading={loadingUsers}
              handlecheckedCollabs={handlecheckedcollabs}
              updateWeek={props.updateWeek}
            />
          </Grid>

          <Grid item>
            <ListTitle onClicked={handleShowPrestations} title={t('planning.my_prestations')} />
          </Grid>
          <Grid item style={{ display: showPrestations ? 'block' : 'none' }}>
            <PrestationsList data={serviceDeliveryType} loading={loadingServiceDeliveryType} handlecheckedServiceType={handlecheckedServiceType} />
          </Grid>
        </Grid>
      </ThemeProvider>
      <PlanningCreateDialog show={openCreate} changeDialog={(value: boolean) => setopenCreate(value)} type={'create'} />
    </>
  );
};
