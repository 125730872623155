export const HIDE_WEEKENDS = 'weekends/hide';
export const SHOW_WEEKENDS = 'weekends/show';

// reducer interface
export interface WeekendsState {
  show: boolean;
}

interface HideWeekendsAction {
  type: typeof HIDE_WEEKENDS;
}

interface ShowSnacBarAction {
  type: typeof SHOW_WEEKENDS;
}

export type WeekendsActionTypes = HideWeekendsAction | ShowSnacBarAction;
