import {
    Box,
    FormControl,
    FormHelperText,
    InputAdornment,
    ListItemText,
    MenuItem,
    Select,
    SelectChangeEvent,
    Typography,
    Checkbox,
    LinearProgress,
    ThemeProvider, ListItem,
} from '@mui/material';
import React from 'react';
import {dialogSelectTheme} from '../../../../theme';
import SelectBtn from '../../Icon/icons/SelectBtn';

export interface DialogItem {
    label: string;
    secondaryText?: string;
    value: string;
}

interface DialogSelectProps {
    label?: string;
    value?: string;
    error?: boolean | string;
    onChange?: ((event: SelectChangeEvent<string>, child: React.ReactNode) => void) | undefined;
    icon: React.ComponentType<{ className: string; color: string | undefined }>;
    items: DialogItem[] | any [];
    iconColor?: string;
    multipleSelection?: boolean;
    className?: string;
    loading?: boolean;
    disabled?: boolean;
}

export const DialogSelect = (props: DialogSelectProps) => {
    const {label, onChange, error, value, items, iconColor, multipleSelection, className, loading, disabled} = props;
    const SelectIcon = props.icon;
    return (
        <ThemeProvider theme={dialogSelectTheme}>
            <FormControl variant="standard" fullWidth>
                <Select
                    disabled={disabled}
                    fullWidth
                    MenuProps={{
                        PaperProps: {
                            className: className,
                        },
                    }}
                    displayEmpty
                    disableUnderline
                    className={'select_style'}
                    {...(error && {error: true})}
                    IconComponent={Box}
                    value={value}
                    size="small"
                    label={label}
                    onChange={onChange}
                    startAdornment={
                        <InputAdornment position="start">
                            <SelectIcon className={'icon'} color={iconColor}/>
                        </InputAdornment>
                    }
                    inputProps={{
                        'aria-label': label,
                    }}
                >
                    <MenuItem value="">
                        <ListItemText primary={label}/>
                    </MenuItem>

                    {loading ? (
                        <MenuItem>
                            <LinearProgress/>
                        </MenuItem>
                    ) : (
                        items && items.map((item: DialogItem, index: number) => (
                            <MenuItem key={`item-${index}`} value={item.value}>
                                {multipleSelection ? (
                                    <>
                                        <Checkbox checked={value && value?.indexOf(item.value) > -1 ? true : false}/>
                                        <ListItemText
                                            primary={<Typography>{item.label}</Typography>}
                                            secondary={<Typography
                                                sx={{fontSize: '9px !important'}}>{item.secondaryText}</Typography>}
                                        />
                                        <SelectBtn/>
                                    </>
                                ) : (
                                    <>
                                        {/*  types list from here */}
                                        <ListItemText primary={item.label} secondary={item.secondaryText}
                                                      aria-selected={item.label === value}/>
                                    </>
                                )}
                            </MenuItem>
                        ))
                    )}
                </Select>
                {error && <FormHelperText className={'helper_text'}>{error}</FormHelperText>}
            </FormControl>
        </ThemeProvider>
    );
};
