export interface Description {
  label: string;
  type: string;
  height: number;
  width?: number;
  choices?: { label: string; value: string }[];
  value?: string;
  value2?: string;
  cellType?: string;
  valueName?: string;
  index?: number;
}

export const headItems: Description[] = [
  {
    label: 'intervention.type',
    type: 'normal',
    height: 50,
    value: 'type',
    valueName: 'type',
  },

  {
    label: 'intervention.ref_patrimony',
    type: 'normal',
    height: 50,
    value: 'refPat',
    valueName: 'refPat',
  },
  {
    label: 'intervention.date',
    type: 'normal',
    height: 50,
    value: 'date',
    cellType: 'serviceDeliveryDate',
  },
  {
    label: 'intervention.duration',
    type: 'normal',
    height: 50,
    value: 'actualTimeRange',
    valueName: 'duration',
  },
  {
    label: 'intervention.stock_withdrawal',
    type: 'select',
    height: 50,
    choices: [
      { label: 'intervention.yes', value: '10' },
      { label: 'intervention.no', value: '20' },
    ],
    value: 'outOfStock',
    cellType: 'outofStock',
  },
  {
    label: 'intervention.status',
    type: 'select',
    height: 50,
    choices: [
      { label: 'intervention.todo', value: '10' },
      { label: 'intervention.inprogress', value: '20' },
      { label: 'intervention.validated', value: '30' },
    ],
    value: 'status',
    valueName: 'status',
    cellType: 'status',
  },
];

export const row_description = ['black', 'grey', 'grey', 'grey', 'circle', 'colored'];
