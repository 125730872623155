import { Dialog, ThemeProvider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { dialogTheme } from '../../../theme';
import { DialogActions } from '../dialogActions/dialogActions';
import { DialogHeader } from '../dialogTitle/dialogTitle';
import { NotificationDialogBody } from './notificationDialogBody/notificationDialogBody';

const initialValues = {
  heure: '',
  function: '',
  title: '',
  lastname: '',
  state: '',
};

interface NotificationsDialogProps {
  show?: boolean;
  type: string;
  changeDialog: (value: boolean) => void;
}
/**
 * component describing the diolog for creating/updating a notification
 *
 * @returns {JSX.Element}
 */

export const NotificationsDialog = (props: NotificationsDialogProps) => {
  const { t } = useTranslation();
  const { show = false, type, changeDialog } = props;
  const [open, setOpen] = React.useState(show);

  useEffect(() => {
    setOpen(show);
  }, [show]);

  const handleClose = () => {
    setOpen(false);
    changeDialog(false);
  };

  const [values] = useState(initialValues);
  const [errors, setErrors] = useState({
    title: '',
    lastname: '',
    heure: '',
    function: '',
  });

  const validate = () => {
    errors.title = values.title ? '' : t('error.empty_field');
    errors.lastname = values.lastname ? '' : t('error.empty_field');
    errors.heure = values.heure ? '' : t('error.empty_field');
    errors.function = values.function ? '' : t('error.empty_field');
    setErrors({
      ...errors,
    });
    return Object.values(errors).every((x) => x === '');
  };
  const handleSubmit = () => {
    if (validate()) {
      console.log('notifications dialog data=');
    }
  };

  return (
    <>
      <ThemeProvider theme={dialogTheme}>
        <Dialog open={open}>
          <DialogHeader type={type} onClose={handleClose} />
          <NotificationDialogBody />

          <DialogActions type={type} onClose={handleClose} onAction={handleSubmit} secondButtonLabel={t('notification.validate')} />
        </Dialog>
      </ThemeProvider>
    </>
  );
};
