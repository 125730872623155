export const user = {
  users: 'Utilisateurs',
  create_users: 'Créer un utilisateur',
  first_name: 'PRÉNOM',
  last_name: 'NOM',
  type: 'TYPE',
  function: 'FONCTION',
  last_connection: 'DERNIÈRE CONNEXION',
  account: 'COMPTE',
  activated: 'Activé',
  disactivated: 'Désactivé',
  madam: 'Madame',
  mister: 'Monsieur',
  login: 'Login',
  email: 'email',
  delete_message: 'Voulez-vous supprimer cet utilisateur ?',
  delete: 'Supprimer',
  create_my_collaborators: 'Créer un collaborateur',
  create_collaborators: 'Créer un collaborateur',
  disconnect: 'Déconnexion',
  collab: 'Collaborateur',
  int: 'Intérimaire',
  add_success: 'Utilisateur crée avec succès',
  update_success: 'Utilisateur modifié avec succès',
};
