import { createTheme } from '@mui/material/styles';
import { colors } from './shared-theme';

export const tableTheme = createTheme({
  palette: {
    contrastThreshold: 3,
    tonalOffset: 0.1,
  },
  shadows: [
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
  ],
  typography: {
    allVariants: {
      textTransform: 'none',
      display: 'flex',
      alignItems: 'center',
      fontFamily: 'ArialMT',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '17px',
      letterSpacing: 'normal',
      lineHeight: 'normal',
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          '&.MuiTableContainer-root': {
            backgroundColor: colors.LIGHTER_GREY,
          },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          borderCollapse: 'separate',
          borderSpacing: '0px 10px !important',
          '.grey': {
            opacity: 0.3,
          },
          '.black': {
            color: colors.BLACK,
          },
          '.colored': {
            fontWeight: 'bold',
            fontFamily: 'Arial-BoldMT',
          },
          '.icon_hover': {
            backgroundColor: colors.WHITE,
            '& .hidden_cell': {
              opacity: '0',
            },
            '&:hover .hidden_cell': {
              opacity: '1',
              color: '#d8d8d8',
            },
          },
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: colors.MAIN_BLUE,
          margin: 25,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:hover': {
            boxShadow: `0 2px 16px 0 ${colors.HOVER_COLOR}`,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          height: 50,
          border: 'none',
          padding: '0px 16px',
          maxWidth: '200px',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: colors.MAIN_BLUE,
          '&.Mui-checked': {
            color: colors.MAIN_BLUE,
          },
        },
        checked: {},
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          '& .MuiTablePagination-selectLabel ': {
            color: colors.MAIN_GREY,
            font: 'ArialMT',
            fontSize: '15px',
          },
          '& .MuiTablePagination-displayedRows': {
            color: colors.MAIN_GREY,
            font: 'ArialMT',
            fontSize: '15px',
          },
          '.MuiTablePagination-select': {
            paddingTop: 0,
            paddingBottom: 0,
            color: colors.MAIN_GREY,
          },
        },
      },
    },
  },
});
