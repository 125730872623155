import { ENDPOINTS, sendAsyncRequest } from '../utils';

// GET ALL AGENCIES
const getAgencies = async () => {
  const response = await sendAsyncRequest('get', ENDPOINTS.AGENCIES, true, undefined, undefined);
  return response.data;
};

// GET DETAILS_AGENCY_BY_PATRIMONY

const getDetailsAgencyByPatrimony = async (id: string) => {
  const response = await sendAsyncRequest('get', ENDPOINTS.PATRIMONIES + '/' + id + '/agency', true, undefined, undefined);
  return response.data;
};

export const agencies = { getAgencies, getDetailsAgencyByPatrimony };
