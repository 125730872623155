import React from 'react';

export const UserInputIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M11.609 11.789c.579 0 1.114-.156 1.606-.468a3.488 3.488 0 0 0 1.184-1.27c.298-.535.448-1.138.448-1.807a3.46 3.46 0 0 0-1.632-2.988 2.997 2.997 0 0 0-1.606-.456c-.58 0-1.117.153-1.611.46a3.444 3.444 0 0 0-1.628 3c0 .66.148 1.256.444 1.79.295.535.688.958 1.18 1.27.491.313 1.03.469 1.615.469zm5.027 7.411c.653 0 1.11-.093 1.372-.279.261-.185.392-.458.392-.818 0-.518-.158-1.06-.473-1.625-.315-.566-.77-1.096-1.363-1.591-.594-.495-1.308-.898-2.143-1.207-.836-.31-1.776-.465-2.821-.465-1.045 0-1.987.155-2.825.465-.838.31-1.552.712-2.143 1.207-.59.495-1.044 1.025-1.36 1.59-.314.566-.472 1.108-.472 1.626 0 .36.13.633.392.818.261.186.716.279 1.363.279h10.081z"
        fill="#72706F"
        fillRule="nonzero"
      />
    </svg>
  );
};

export default UserInputIcon;
