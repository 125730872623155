import {
  ServiceDeliveryState,
  ServiceDeliveryActionTypes,
  GET_SERVICE_DELIVERY,
  GETTING_SERVICE_DELIVERY,
  GET_SERVICE_DELIVERY_ERROR,
  ADD_SERVICE_DELIVERY,
  ADDING_SERVICE_DELIVERY,
  UPDATING_SERVICE_DELIVERY,
  UPDATE_SERVICE_DELIVERY,
  EXPORT_SERVICE_DELIVERY,
  EXPORTING_SERVICE_DELIVERY,
  DELETE_SERVICE_DELIVERY,
  ServiceDeliveriesPaginated,
  ServiceDelivery,
  GET_SD,
} from './type';

const INITIAL_STATE: ServiceDeliveryState = {
  serviceDeliveries: {} as ServiceDeliveriesPaginated,
  sd: {} as ServiceDelivery,
  loading: true,
  addLoading: false,
};

export const ServiceDeliveryReducer = (state: ServiceDeliveryState = INITIAL_STATE, action: ServiceDeliveryActionTypes): ServiceDeliveryState => {
  switch (action.type) {
    case GETTING_SERVICE_DELIVERY:
      return {
        ...state,
        loading: true,
      };
    case GET_SERVICE_DELIVERY:
      return {
        ...state,
        serviceDeliveries: action.payload,
        loading: false,
      };
    case GET_SERVICE_DELIVERY_ERROR:
      return {
        ...state,
        serviceDelivery: [],
        loading: false,
      };
    case GET_SD:
      return {
        ...state,
        sd: action.payload,
        loading: false,
      };
    case ADDING_SERVICE_DELIVERY:
      return {
        ...state,
        addLoading: true,
      };
    case ADD_SERVICE_DELIVERY:
      return {
        ...state,
        addLoading: false,
      };
    case UPDATING_SERVICE_DELIVERY:
      return {
        ...state,
        addLoading: true,
      };
    case UPDATE_SERVICE_DELIVERY:
      return {
        ...state,
        addLoading: false,
      };
    case EXPORTING_SERVICE_DELIVERY:
      return {
        ...state,
        addLoading: true,
      };
    case EXPORT_SERVICE_DELIVERY:
      return {
        ...state,
        addLoading: false,
      };
    case DELETE_SERVICE_DELIVERY:
      return {
        ...state,
        serviceDelivery: state.serviceDeliveries.filter((service) => service.id !== action.payload),
        addLoading: false,
      };
    default:
      return state;
  }
};
