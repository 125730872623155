import { Box, Dialog, DialogContent, Grid, ThemeProvider } from '@mui/material';
import React, { useEffect } from 'react';
import { dialogTheme } from '../../../theme';
import { DialogTitle } from '../../ui/Dialog/DialogLabel/DialogLabel';
import FunctionAbreviation from '../../ui/Icon/icons/functionAbreviation';
import ListIcon from '../../ui/Icon/icons/list';
import Location from '../../ui/Icon/icons/location';
import Patrimony from '../../ui/Icon/icons/Patrimony';
import { StockExit } from '../../ui/Icon/icons/stockExit';
import TextArea from '../../ui/Icon/icons/TextArea';
import { DialogHeader } from '../dialogTitle/dialogTitle';
import { useDispatch } from 'react-redux';
import { getServiceDeliveryById } from '../../../store/intervention/action';
import { ServiceDelivery } from '../../../store/intervention/type';
import { Calendar } from '../../ui/Calendar/calendar';

interface DafInterventionDiaolgProps {
  show?: boolean;
  changeDialog: (value: boolean) => void;
  type: string;
  data: ServiceDelivery;
}

/**
 * component describing pending/validated interventions dialog
 *
 *
 * @returns {JSX.Element}
 */

export const DafInterventionDiaolg = (props: DafInterventionDiaolgProps) => {
  const { show = false, changeDialog, data } = props;
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(show);
  useEffect(() => {
    setOpen(show);
    // getOneServiceDelivery();
  }, [show]);

  const handleClose = () => {
    setOpen(false);
    changeDialog(false);
  };
  const getOneServiceDelivery = () => {
    dispatch(getServiceDeliveryById(data.id));
  };
  return (
    <>
      <ThemeProvider theme={dialogTheme}>
        <Dialog open={open}>
          <DialogHeader type={data.status} titleText={data.status} onClose={handleClose} />
          <DialogContent>
            <Box sx={{ m: 2 }}></Box>
            <Grid container direction="column" alignItems="flex-start" rowSpacing={2}>
              <Grid item>
                <DialogTitle label={data.type} secondaryText={data.initialTimeRange} icon={ListIcon} />
              </Grid>
              <Grid item>
                <DialogTitle label={data.refPat} icon={Patrimony} />
              </Grid>
             
              <Grid item>
                <Calendar value={data.date} disabled={true} />
              </Grid>
              <Grid item>
                {/* FunctionAbreviation is bigger than other icons (34x34) */}
                <Box sx={{ marginLeft: '-5px' }}>
                  <DialogTitle label={data.firstname} abreviationText="AR" icon={FunctionAbreviation} />
                </Box>
              </Grid>
              <Grid item>
                <DialogTitle label={'Sacs poubelle et éponge'} icon={StockExit} />
              </Grid>
              <Grid item>
                <DialogTitle label={data.comment ? data.comment : 'No comment'} icon={TextArea} />
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </ThemeProvider>
    </>
  );
};
