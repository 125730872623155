import React from 'react';

export const Profile = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="34" height="34" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M16.51 16.736c.725 0 1.394-.195 2.008-.585A4.36 4.36 0 0 0 20 14.563c.373-.669.56-1.421.56-2.258 0-.81-.187-1.539-.56-2.19a4.29 4.29 0 0 0-1.48-1.545A3.746 3.746 0 0 0 16.51 8c-.725 0-1.396.192-2.014.575a4.305 4.305 0 0 0-1.485 1.556c-.373.654-.556 1.386-.549 2.195 0 .823.185 1.568.554 2.237.37.668.86 1.197 1.475 1.588.614.39 1.287.585 2.019.585zM22.796 26c.817 0 1.388-.116 1.715-.348.327-.232.49-.573.49-1.024 0-.647-.197-1.324-.591-2.03-.394-.708-.962-1.37-1.704-1.99-.742-.619-1.635-1.121-2.68-1.508-1.043-.387-2.218-.58-3.525-.58s-2.484.193-3.531.58c-1.047.387-1.94.89-2.679 1.508-.738.62-1.305 1.282-1.699 1.99-.394.706-.591 1.383-.591 2.03 0 .45.163.792.49 1.024.327.232.895.348 1.704.348h12.601z" />
    </svg>
  );
};

export default Profile;
