import { ThunkDispatch } from 'redux-thunk';
import { APIS } from '../../api';
import { RootState } from '../../index';
import {
  GET_SERVICE_DELIVERY,
  ServiceDeliveryActionTypes,
  GETTING_SERVICE_DELIVERY,
  ADDING_SERVICE_DELIVERY,
  ADD_SERVICE_DELIVERY,
  DELETE_SERVICE_DELIVERY,
  GET_SERVICE_DELIVERY_ERROR,
  UPDATING_SERVICE_DELIVERY,
  UPDATE_SERVICE_DELIVERY,
  EXPORTING_SERVICE_DELIVERY,
  EXPORT_SERVICE_DELIVERY,
  PURGE_SERVICE_DELIVERY,
  ServiceDelivery,
  ADD_INTERVENTION_ERROR,
  ADD_INTERVENTION_SUCCESS,
  GET_SD,
  DELETE_INTERVENTION,
} from './type';
import { SnackBarActionTypes, SHOW_SNACK_BAR } from '../snackBar/types';
import { showSnackBar } from '../snackBar/actions';
import { errorMsg } from '../../utils';

// GET_SERVICE_DELIVERY INTERVENTION
export const getServiceDelivery =
  (page?: number, perPage?: number, status?: string, outOfStock: boolean = false) =>
  async (dispatch: ThunkDispatch<RootState, undefined, ServiceDeliveryActionTypes | SnackBarActionTypes>) => {
    dispatch({
      type: GETTING_SERVICE_DELIVERY,
    });
    try {
      const ServiceDeliveryPaginated = await APIS.serviceDelivery.getServiceDeliveryListGTI(page, perPage, status, outOfStock);

      const newServiceDelivery: ServiceDelivery[] = [];
      const serviceDeliveryFromWs = ServiceDeliveryPaginated.items ? ServiceDeliveryPaginated.items : ServiceDeliveryPaginated;
      if (serviceDeliveryFromWs && serviceDeliveryFromWs?.length > 0) {
        serviceDeliveryFromWs.map((item) => {
          const serviceDelivery = { ...item, firstname: null, type: null };
          if (item.collaborators && item.collaborators[0]) {
            serviceDelivery.firstname = item.collaborators[0].firstname;
          }
          if (item.types && item.types[0]) {
            serviceDelivery.type = item.types[0].type;
          }
          newServiceDelivery.push({ ...serviceDelivery });
        });
      }
      ServiceDeliveryPaginated.items = [...newServiceDelivery];
      dispatch({
        type: GET_SERVICE_DELIVERY,
        payload: ServiceDeliveryPaginated,
      });
    } catch (error) {
      dispatch({
        type: SHOW_SNACK_BAR,
        payload: { message: errorMsg(error), severity: 'error' },
      });
      dispatch({
        type: GET_SERVICE_DELIVERY_ERROR,
      });
    }
  };

// GET SERVICE DELIVERY BY ID
export const getServiceDeliveryById =
  (id: string, page?: number, perPage?: number) =>
  async (dispatch: ThunkDispatch<RootState, undefined, ServiceDeliveryActionTypes | SnackBarActionTypes>) => {
    try {
      const response = await APIS.serviceDelivery.getServiceDeliveryBYID(id, page, perPage);
      dispatch(getServiceDelivery(1, 5));
      dispatch({
        type: GET_SD,
        payload: response,
      });
      return true;
    } catch (error) {
      throw new Error('error');
    }
  };
export const getServiceDeliveryByPatrimoine =
  (id: string[]) => async (dispatch: ThunkDispatch<RootState, undefined, ServiceDeliveryActionTypes | SnackBarActionTypes>) => {
    try {
      const response = await APIS.serviceDelivery.getServiceDeliveryBYPAT(id);
      dispatch({
        type: GET_SERVICE_DELIVERY,
        payload: response,
      });
      return true;
    } catch (error) {
      throw new Error('error');
    }
  };
// GET_SERVICE_DELIVERY Planning
export const getServiceDeliveryPlanning =
  () => async (dispatch: ThunkDispatch<RootState, undefined, ServiceDeliveryActionTypes | SnackBarActionTypes>) => {
    dispatch({
      type: GETTING_SERVICE_DELIVERY,
    });
    try {
      const ServiceDeliveryPaginated = await APIS.serviceDelivery.getServiceDeliveryList();
      const newServiceDelivery: ServiceDelivery[] = [];
      ServiceDeliveryPaginated.map((item) => {
        if (item?.tasks && item?.tasks?.length > 0) {
          item?.tasks.map((task) => {
            let newTas = {
              ...task,
              date: task.date,
              id: task.id,
              outOfStock: task.outOfStock,
              refPat: task.refPat,
              status: task.status,
              weekTemplateId: task.weekTemplateId,
              initialTimeRange: task.initialTimeRange,
            };
            if (task?.types && task?.types[0]) {
              newTas = {
                ...newTas,
                type: task?.types[0]?.type,
                duration: task?.types[0]?.duration,
                type_id: task?.types[0]?.id,
              };
            } else {
              newTas = { ...newTas, type: '', duration: '', type_id: null };
            }
            newServiceDelivery.push(newTas);
          });
        }
      });

      dispatch({
        type: GET_SERVICE_DELIVERY,
        payload: newServiceDelivery,
      });
    } catch (error) {
      dispatch({
        type: SHOW_SNACK_BAR,
        payload: { message: errorMsg(error), severity: 'error' },
      });
      dispatch({
        type: GET_SERVICE_DELIVERY_ERROR,
      });
    }
  };
// DELETE SERVICE_DELIVERY_FROM_CALANDAR
export const deleteServiceDeliveryCalendar =
  (id: string) => async (dispatch: ThunkDispatch<RootState, undefined, ServiceDeliveryActionTypes | SnackBarActionTypes>) => {
    await APIS.serviceDelivery.deleteServiceDelivery(id);
    dispatch(showSnackBar('intervention.delete_success', 'success'));
    dispatch(getServiceDeliveryPlanning());
    dispatch({
      type: DELETE_SERVICE_DELIVERY,
      payload: id,
    });

    return true;
  };
// DELETE SERVICE_DELIVERY
export const deleteServiceDelivery =
  (id: string) => async (dispatch: ThunkDispatch<RootState, undefined, ServiceDeliveryActionTypes | SnackBarActionTypes>) => {
    await APIS.serviceDelivery.deleteServiceDelivery(id);
    dispatch(showSnackBar('intervention.delete_success', 'success'));
    dispatch(getServiceDelivery(1, 5));
    dispatch({
      type: DELETE_SERVICE_DELIVERY,
      payload: id,
    });

    return true;
  };

// ADD SERVICE_DELIVERY
export const addServiceDelivery =
  (
    date: string,
    initialTimeRange: string[],
    isIntervention: boolean,
    refPat: string,
    serviceDeliveryTypeIds: string[],
    collabIds: string[],
    is_intervention: boolean = true,
  ) =>
  async (dispatch: ThunkDispatch<RootState, undefined, ServiceDeliveryActionTypes | SnackBarActionTypes>) => {
    dispatch({
      type: ADDING_SERVICE_DELIVERY,
    });
    try {
      await APIS.serviceDelivery.addNewServiceDelivery({
        date,
        initialTimeRange,
        isIntervention,
        refPat,
        serviceDeliveryTypeIds,
        collabIds,
      });
      dispatch(showSnackBar(is_intervention ? 'intervention.add_success' : 'prestation.add_success', 'success'));
      dispatch(getServiceDeliveryPlanning());
      dispatch({
        type: ADD_SERVICE_DELIVERY,
      });
      return true;
    } catch (error) {
      dispatch({
        type: SHOW_SNACK_BAR,
        payload: { message: errorMsg(error), severity: 'error' },
      });
      dispatch({
        type: ADD_SERVICE_DELIVERY,
      });
      throw new Error('error');
    }
  };

// EDIT SERVICE_DELIVERY
export const editServiceDelivery =
  (id: string, date: string, initialTimeRange: string[], actualTimeRange: [], isIntervention: boolean, refPat: string, comment: string) =>
  async (dispatch: ThunkDispatch<RootState, undefined, ServiceDeliveryActionTypes | SnackBarActionTypes>) => {
    try {
      await APIS.serviceDelivery.editServiceDel(id, {
        date,
        initialTimeRange,
        actualTimeRange,
        isIntervention,
        refPat,
        comment,
      });
      dispatch(showSnackBar('intervention.update_success', 'success'));
      dispatch(getServiceDelivery());
      dispatch({
        type: UPDATING_SERVICE_DELIVERY,
      });
      return true;
    } catch (error) {
      dispatch({
        type: SHOW_SNACK_BAR,
        payload: { message: errorMsg(error), severity: 'error' },
      });
      dispatch({
        type: UPDATE_SERVICE_DELIVERY,
      });
      throw new Error('error');
    }
  };

// EXPORT_SERVICE_DELIVERIES
export const exportServiceDelivery =
  (exportType: string, startDate: Date, endDate: Date) =>
  async (dispatch: ThunkDispatch<RootState, undefined, ServiceDeliveryActionTypes | SnackBarActionTypes>) => {
    dispatch({
      type: EXPORTING_SERVICE_DELIVERY,
    });

    try {
      await APIS.serviceDelivery.exportServiceDelivery(exportType, startDate, endDate);
      dispatch(showSnackBar('intervention.export_success', 'success'));
      dispatch({
        type: EXPORT_SERVICE_DELIVERY,
      });
      return true;
    } catch (error) {
      dispatch({
        type: SHOW_SNACK_BAR,
        payload: { message: errorMsg(error), severity: 'error' },
      });

      dispatch({
        type: GET_SERVICE_DELIVERY_ERROR,
      });
    }
  };

// PURGE_SERVICE_DELIVERIES
export const purgeServiceDelivery =
  (startDate: string) => async (dispatch: ThunkDispatch<RootState, undefined, ServiceDeliveryActionTypes | SnackBarActionTypes>) => {
    try {
      await APIS.serviceDelivery.purgeServiceDelivery(startDate);
      dispatch(showSnackBar('intervention.purge_success', 'success'));
      dispatch(getServiceDelivery(1, 5));
      dispatch({
        type: PURGE_SERVICE_DELIVERY,
      });
    } catch (error) {
      dispatch({
        type: SHOW_SNACK_BAR,
        payload: { message: errorMsg(error), severity: 'error' },
      });
      dispatch({
        type: GET_SERVICE_DELIVERY_ERROR,
      });
    }
  };

// ADD SERVICE_DELIVERY
export const addIntervention =
  (
    date: string,
    initialTimeRange: string[],
    isIntervention: boolean,
    refPat: string,
    comment: string,
    serviceDeliveryTypeIds: string[],
    collabIds: string[],
  ) =>
  async (dispatch: ThunkDispatch<RootState, undefined, ServiceDeliveryActionTypes | SnackBarActionTypes>) => {
    dispatch({
      type: ADDING_SERVICE_DELIVERY,
    });
    try {
      await APIS.serviceDelivery.addIntervention({
        date,
        initialTimeRange,
        isIntervention,
        refPat,
        comment,
        serviceDeliveryTypeIds,
        collabIds,
      });
      dispatch(getServiceDelivery(1, 5));
      dispatch({
        type: ADD_INTERVENTION_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: SHOW_SNACK_BAR,
        payload: { message: errorMsg(error), severity: 'error' },
      });
      dispatch({
        type: ADD_INTERVENTION_ERROR,
      });
    }
  };
// DELETE INTERVENTION
export const deleteIntervention =
  (id: string) => async (dispatch: ThunkDispatch<RootState, undefined, ServiceDeliveryActionTypes | SnackBarActionTypes>) => {
    await APIS.calendarService.deleteIntervention(id);
    dispatch(showSnackBar('intervention.delete_success', 'success'));
    dispatch(getServiceDelivery(1, 5));
    dispatch({
      type: DELETE_INTERVENTION,
      payload: id,
    });

    return true;
  };

// UPDATE SERVICE_DELIVERY STATUS
export const updateServiceDeliveryStatus =
  (id: string, timeIn: string, timeOut: string, status: string, comment: string, outOfStock: boolean, date: string) =>
  async (dispatch: ThunkDispatch<RootState, undefined, ServiceDeliveryActionTypes | SnackBarActionTypes>) => {
    try {
      await APIS.serviceDelivery.updateServiceDelStatus(id, {
        timeIn,
        timeOut,
        status,
        comment,
        outOfStock,
        date,
      });
      dispatch(showSnackBar('intervention.update_success', 'success'));
      dispatch(getServiceDelivery());
      dispatch({
        type: UPDATING_SERVICE_DELIVERY,
      });
      return true;
    } catch (error) {
      dispatch({
        type: SHOW_SNACK_BAR,
        payload: { message: errorMsg(error), severity: 'error' },
      });
      dispatch({
        type: UPDATE_SERVICE_DELIVERY,
      });
      throw new Error('error');
    }
  };
