import { createTheme } from '@mui/material/styles';
import { colors } from './shared-theme';

export const tableHeaderTheme = createTheme({
  palette: {
    contrastThreshold: 3,
    tonalOffset: 0.1,
  },
  shadows: [
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
  ],
  typography: {
    allVariants: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: 'ArialMT',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '13px',
      opacity: 0.5,
      color: colors.MAIN_GREY,
      border: 'none',
      textTransform: 'uppercase',
    },
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          height: 50,
          border: 'none',
          padding: '0px 16px',
          maxWidth: '200px',
          color: colors.MAIN_GREY,
          '&.select_style': {
            opacity: 1,
            '.MuiSelect-select': {
              opacity: 0.5,
              color: colors.MAIN_GREY,
            },
            '.MuiSelect-icon': {
              color: colors.BLACK,
              fill: colors.BLACK,
              top: 0,
            },
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          opacity: 1,
          '.MuiInputBase-input': {
            paddingBottom: 0,
          },
        },
      },
    },
  },
});
