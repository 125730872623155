import { Navigate, useLocation } from 'react-router-dom';
import { getRole, isLoggedIn, isRoleValid, PATHS } from '../utils';
import React from 'react';

function PrivateRoute({ children, roles }: { children: JSX.Element; roles: string[] }) {
  const location = useLocation();

  if (!isLoggedIn() || !isRoleValid()) {
    localStorage.clear();

    // Redirect them to the login page if they are not logged
    return <Navigate to={PATHS.Login} state={{ from: location }} />;
  } else if (!roles.includes(getRole())) {
    // the use does not have acces to this route
    return <Navigate to={PATHS.Users} state={{ from: location }} />;
  }

  return children;
}

export default PrivateRoute;
