import { input } from './fr/input';
import { common } from './fr/common';
import { error } from './fr/error';
import { user } from './fr/user';
import { table } from './fr/table';
import { prestation } from './fr/prestation';
import { week } from './fr/week';
import { notification } from './fr/notification';
import { intervention } from './fr/intervention';
import { planning } from './fr/planning';
import { menu } from './fr/menu';
import { history } from './fr/history';
import { patrimony } from './fr/patrimony';
import { interventionDAF } from './fr/interventionDAF';

const fr = {
  input,
  common,
  error,
  user,
  table,
  prestation,
  week,
  notification,
  intervention,
  interventionDAF,
  planning,
  menu,
  history,
  patrimony,
};

export default fr;
