import { Box, Menu, MenuItem, Stack, ThemeProvider, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../../assets/sass/collabDetailsDialog.module.scss';
import { ValidateWeekDialog } from '../dialog/validateWeekDialog/validateWeekDialog';
import { dialogTheme } from '../../theme/dialog-theme';
import { User } from '../../store/user/types';
import { Icon } from '..';
import { colors } from '../../theme/shared-theme';
import { formatDate } from '../../utils';

interface CollaboratorDetailsMenuProps {
  collaboratorDetails: User;
  open: boolean | null;
  changeDialog: (value: boolean) => void;
  updateWeek: () => void;
  userFromSd: Object;
}
export const CollaboratorDetailsMenu = (props: CollaboratorDetailsMenuProps) => {
  const [weekValidate, setWeekValidate] = React.useState(false);

  const { collaboratorDetails, open, changeDialog, userFromSd } = props;
  const [anchorEl, setAnchorEl] = React.useState(open);
  const { t } = useTranslation();
  const [realizedTime, setrealizedTime] = useState<string>();
  const [planningTime, setPlanningTime] = useState<string[]>([]);
  useEffect(() => {
    if (userFromSd) {
      userFromSd.map((item) => {
        if (item['id'] === collaboratorDetails.id) {
          setrealizedTime(item['realizedHours']);
          setPlanningTime(item['plannedHours']);
        }
      });
    }
    setAnchorEl(open);
  }, [open]);

  const handleClose = () => {
    setAnchorEl(null);
    changeDialog(false);
  };
  const handleValidateWeekDialog = () => {
    setWeekValidate(true);
  };

  return (
    <>
      <ThemeProvider theme={dialogTheme}>
        <Menu
          PaperProps={{
            style: {
              overflow: 'visible',
              width: 240,
              height: 361,
              borderRadius: 8,
              boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.16)',
            },
          }}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <Stack direction="column">
            <MenuItem className={styles.no_hover}>
              <Stack direction="column">
                <Typography className={styles.name}>{`${collaboratorDetails.firstname} ${collaboratorDetails.lastname}`}</Typography>
                <Typography className={styles.function}>{collaboratorDetails.role_description}</Typography>
              </Stack>
            </MenuItem>
            <MenuItem className={styles.no_hover}>
              <Stack direction="column">
                <Typography className={styles.last_connection}>{t('planning.last_connection')}</Typography>
                <Typography className={styles.connection_date}>
                  {collaboratorDetails.lastConnexionTime ? formatDate(collaboratorDetails.lastConnexionTime) : '-'}
                </Typography>
              </Stack>
            </MenuItem>
            <MenuItem className={styles.no_hover}>
              <Stack direction="column">
                <Typography className={styles.status}>{t('planning.account')}</Typography>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={0.25}>
                  <Icon
                    name={'active'}
                    prefix="domanys"
                    style={{ fill: collaboratorDetails.active ? colors.SECONDARY_GREEN : colors.SECONDARY_RED }}
                  />
                  <Typography className={styles.active}>{collaboratorDetails.active ? t('user.activated') : t('user.disactivated')}</Typography>
                </Stack>
              </Stack>
            </MenuItem>
            <MenuItem className={styles.no_hover}>
              <Stack direction="column">
                <Typography className={styles.total_hour}>{t('planning.total_planned_hours')}</Typography>
                <Typography className={styles.blue_hour}>{planningTime}</Typography>
              </Stack>
            </MenuItem>
            <MenuItem className={styles.no_hover}>
              <Stack direction="column">
                <Typography className={styles.total_hour}>{t('planning.total_worked_hours')}</Typography>
                <Typography className={styles.orange_hour}>{realizedTime}</Typography>
              </Stack>
            </MenuItem>
            <>
              <Box sx={{ height: 16 }}></Box>
              <MenuItem className={styles.button_text}>{t('planning.show_planning')}</MenuItem>
              <MenuItem className={styles.button_text} onClick={handleValidateWeekDialog}>
                {t('planning.validate_week')}
              </MenuItem>
              <MenuItem sx={{ borderRadius: '0 0 8px 8px' }} className={styles.button_text}>
                {t('planning.modify')}
              </MenuItem>
            </>
          </Stack>
        </Menu>
      </ThemeProvider>
      {
        <ValidateWeekDialog
          show={weekValidate}
          changeDialog={(value: boolean) => setWeekValidate(value)}
          collaboratorDetails={collaboratorDetails}
          updateWeek={props.updateWeek}
        />
      }
    </>
  );
};
