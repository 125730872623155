import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Layout from '../layouts';
import { HomePage, LoginPage } from '../pages';
import InterventionsPage from '../pages/Intervention/Intervention';
import NotificationsPage from '../pages/Notifications/Notifications';
import PatrimoniesPage from '../pages/Patrimonies/Patrimonies';
import PrestationsPage from '../pages/Prestations/Prestations';
import UsersPage from '../pages/Users/Users';
import { CONSTANTS } from '../utils';
import { PATHS } from '../utils/paths';
import GuestRoute from './GuestRoute';
import PrivateRoute from './PrivateRoute';

export const RoutesList = () => {
  return (
    <Routes>
      <Route
        path={PATHS.Home}
        element={
          <PrivateRoute roles={[CONSTANTS.GTI_ROLE]}>
            <Layout>
              <HomePage />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path={PATHS.weekType}
        element={
          <PrivateRoute roles={[CONSTANTS.GTI_ROLE]}>
            <Layout>
              <HomePage />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path={PATHS.Users}
        element={
          <PrivateRoute roles={[CONSTANTS.ADMIN_ROLE, CONSTANTS.DAF_ROLE, CONSTANTS.GTI_ROLE]}>
            <Layout>
              <UsersPage />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path={PATHS.Prestations}
        element={
          <PrivateRoute roles={[CONSTANTS.ADMIN_ROLE]}>
            <Layout>
              <PrestationsPage />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path={PATHS.notifications}
        element={
          <PrivateRoute roles={[CONSTANTS.ADMIN_ROLE, CONSTANTS.GTI_ROLE]}>
            <Layout>
              <NotificationsPage />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path={PATHS.intervention}
        element={
          <PrivateRoute roles={[CONSTANTS.DAF_ROLE, CONSTANTS.GTI_ROLE]}>
            <Layout>
              <InterventionsPage />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path={PATHS.Patrimonies}
        element={
          <PrivateRoute roles={[CONSTANTS.DAF_ROLE]}>
            <Layout>
              <PatrimoniesPage />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path={PATHS.Login}
        element={
          <GuestRoute>
            <LoginPage />
          </GuestRoute>
        }
      />
      <Route path="*" element={<Navigate replace to={PATHS.Users} />} />
    </Routes>
  );
};
export default RoutesList;
