import { Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { Button } from '../..';
import { colors } from '../../../theme/shared-theme';
interface ListTitleProps {
  onClicked: (value: boolean) => void;
  title: string;
}

const useStyles = makeStyles({
  show_weekes_checkbox: {
    color: colors.MAIN_GREY,
    '&.Mui-checked': {
      color: colors.MAIN_GREY,
    },
  },
  my_collaborators: {
    fontWeight: 'bold',
    color: colors.DARK_GREY,
  },
  Unclicked: {
    transform: 'rotate(360deg)',
  },
  clicked: {
    transform: 'rotate(180deg)',
    transition: 'transform 200ms ease',
  },
});

export const ListTitle = (props: ListTitleProps) => {
  const { title } = props;

  const [clicked, setClicked] = React.useState(true);
  const handleClick = () => {
    setClicked(!clicked);
    props.onClicked(clicked);
  };

  const classes = useStyles();

  return (
    <>
      <Stack direction="row" style={{ minWidth: '250px' }} justifyContent="space-between" alignItems="center">
        <Typography className={classes.my_collaborators}>{title}</Typography>
        <Button
          className={clicked ? classes.clicked : classes.Unclicked}
          onClick={handleClick}
          startIcon={{
            name: 'sideBarUpArrow',
            prefix: 'domanys',
          }}
        />
      </Stack>
    </>
  );
};
