export const planning = {
  create: 'Créer',
  show_weekends: 'Afficher les weekends',
  my_collaborators: 'Mes collaborateurs',
  my_prestations: 'Mes prestations',
  monday: 'Lun.',
  tuesday: 'Mar.',
  wednesday: 'Mer.',
  thursday: 'Jeu.',
  friday: 'Ven.',
  saturday: 'Sam.',
  sunday: 'Dim.',
  type_week: 'Semaine type',
  current_week: 'Semaine',
  last_connection: 'Dernière connexion',
  account: 'Compte',
  total_planned_hours: 'Total des heures prévues',
  total_worked_hours: 'Total des heures réalisées',
  show_planning: 'Afficher le planning',
  validate_week: 'Valider la semaine',
  modify: 'Modifier',
  validate_week_of: 'Valider la semaine de',
};
