import * as React from 'react';

export const WhiteTick = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.5 2.4a9.6 9.6 0 1 1 0 19.2 9.6 9.6 0 0 1 0-19.2zm3.974 5.722a.8.8 0 0 0-1.102.254l-3.54 5.662L9.33 12.16l-.084-.055a.8.8 0 0 0-.876 1.335l3.2 2.4.083.054a.8.8 0 0 0 1.075-.27l4-6.4.045-.082a.8.8 0 0 0-.299-1.02z"
        fill="#FFF"
        fillRule="evenodd"
      />
    </svg>
  );
};
