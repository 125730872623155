import { debounce, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../..';
import { getServiceDelivery } from '../../../store/intervention/action';
import { ServiceDelivery } from '../../../store/intervention/type';
import { CONSTANTS } from '../../../utils';
import { OnTheTable } from '../../table/onTheTable/onTheTable';
import { TableFactory } from '../../table/tableFactory/TableFactory';
import { headItems, row_description } from './fake_data_intervention_gti';

/**
 * @component
 *
 * @return(
 *  <InterventionsGtiList />
 * )
 */
export const InterventionsGtiList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [page, setPage] = useState<number>(CONSTANTS.DEFAULT_PAGE);
  const [perpage, setPerpage] = useState<number>(CONSTANTS.DEFAULT_PER_PAGE);
  const interventionsList = useSelector((state: RootState) => state.interventions.serviceDeliveries);
  const loading = useSelector((state: RootState) => state.interventions.loading);
  const [statusSelected, setStatusSelected] = useState<boolean>(false);
  const [serviceFiltred, setServiceFiltred] = useState<ServiceDelivery[]>([]);
  useEffect(() => {
    getInterventionsList();
  }, []);

  const getInterventionsList = (pageValue: number = page, perpageValue: number = perpage) => {
    try {
      dispatch(getServiceDelivery(pageValue, perpageValue));
    } catch (error) {
      console.log('error');
    }
  };

  const handelChangePage = debounce((value: number) => {
    setPage(value);
    getInterventionsList(value, perpage);
  }, 500);

  const handelChangePerPage = debounce((value: number) => {
    setPerpage(value);
    setPage(CONSTANTS.DEFAULT_PAGE);
    getInterventionsList(CONSTANTS.DEFAULT_PAGE, value);
  }, 500);

  const handleSelectStatus = (value: string) => {
    const ListServices: ServiceDelivery[] = [];
    if (interventionsList?.items) {
      for (let i = 0; i < interventionsList?.items.length; i++) {
        ListServices.push(interventionsList?.items[i]);
      }
    }
    switch (value) {
      case 'intervention.todo': {
        const serviceTODO = ListServices.filter((service) => service.status === 'todo');
        setServiceFiltred(serviceTODO);
        setStatusSelected(true);
        break;
      }
      case 'intervention.inprogress': {
        const serviceInProgress = ListServices.filter((service) => service.status === 'inprogress');
        setServiceFiltred(serviceInProgress);
        setStatusSelected(true);
        break;
      }
      case 'intervention.validated': {
        const serviceValidated = ListServices.filter((service) => service.status === 'done');
        setServiceFiltred(serviceValidated);
        setStatusSelected(true);
        break;
      }
      case 'intervention.yes': {
        const serviceOutOfStock = ListServices.filter((service) => service.outOfStock === true);
        setServiceFiltred(serviceOutOfStock);
        setStatusSelected(true);
        break;
      }
      case 'intervention.no': {
        const serviceInStock = ListServices.filter((service) => service.outOfStock === false);
        console.log('serviceInStockserviceInStockserviceInStock', serviceInStock);
        setServiceFiltred(serviceInStock);
        setStatusSelected(true);
        break;
      }
      default:
        setServiceFiltred(interventionsList?.items);
        setStatusSelected(false);
    }
  };

  return (
    <>
      <Grid container rowSpacing={5} direction="column">
        <Grid item>
          <OnTheTable title={t('intervention.interventions')} button={t('intervention.creer')} name={CONSTANTS.INTEVENTION} />
        </Grid>

        <Grid item>
          <TableFactory
            loading={loading}
            name={'intervention'}
            headItems={headItems}
            bodyData={statusSelected ? serviceFiltred : interventionsList?.items}
            handleSelectType={handleSelectStatus}
            rowDescription={row_description}
            canEdit={true}
            canDelete={true}
            count={interventionsList?.totalItems}
            page={page}
            perPage={perpage}
            handleChangePage={(value) => handelChangePage(value)}
            handleChangeRowsPerPage={(event) => handelChangePerPage(Number(event.target.value))}
          ></TableFactory>
        </Grid>
      </Grid>
    </>
  );
};
