import React, { useState } from 'react';
import { CircularProgress, Grid } from '@mui/material';
import { CheckBoxListItem } from '../checkBoxListItem/checkBoxListItem';
import { useTranslation } from 'react-i18next';
import { User } from '../../../store/user/types';

interface CollaboratorsListProps {
  data: Array<User>;
  loading: boolean;
  userFromSd: Object;
  handlecheckedCollabs: (list: string[]) => void;
  updateWeek: () => void;
}

export const CollaboratorsList = (props: CollaboratorsListProps) => {
  const { userFromSd, data, loading, handlecheckedCollabs } = props;
  const { t } = useTranslation();
  const [selectedCollabs, setSelectedCollabs] = useState<string[]>([]);

  /**
   * on check/uncheck collab
   * @param id
   * @param value
   */
  const handlecheckedList = (id: string, value: boolean) => {
    let list = [...selectedCollabs];
    if (value) {
      list.push(id);
    } else {
      list = list.filter(function (item) {
        return item !== id;
      });
    }
    setSelectedCollabs([...list]);
    handlecheckedCollabs(list);
  };

  // useEffect(() => {
  //   if (data) {
  //     const idList = data ? data.map((a) => a.id) : [];
  //     setSelectedCollabs(idList);
  //   }
  // }, [data]);

  return (
    <>
      <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
        {loading ? (
          <Grid container justifyContent="center">
            <CircularProgress size={20} color="inherit" />
          </Grid>
        ) : data && data?.length > 0 ? (
          data.map((item: User, index: number) => (
            <Grid item key={`table-row-${index}`}>
              <CheckBoxListItem
                user={item}
                userFromSd={userFromSd}
                value={!!selectedCollabs.includes(item.id)}
                handlechecked={(value) => handlecheckedList(item.id, value)}
                updateWeek={props.updateWeek}
              />
            </Grid>
          ))
        ) : (
          <>{t('common.no_data')}</>
        )}
      </Grid>
    </>
  );
};
