import React, { useEffect, useState } from 'react';
import { debounce, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { OnTheTable } from '../../table/onTheTable/onTheTable';
import { TableFactory } from '../../table/tableFactory/TableFactory';
import { useDispatch, useSelector } from 'react-redux';
import { CONSTANTS } from '../../../utils';
import { RootState } from '../../..';
import { getServiceDelivery } from '../../../store/intervention/action';
import { headItems, row_description } from './table_data_intervention';
import { ServiceDelivery } from '../../../store/intervention/type';

/**
 * component returning the interventions list
 *
 * @returns {ExoticComponent<() => JSX.Element>}
 */
export const InterventionsList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const interventionsList = useSelector((state: RootState) => state.interventions.serviceDeliveries);
  // const interventionsListLoading = useSelector((state: RootState) => state.interventions.loading);
  const [page, setPage] = useState<number>(CONSTANTS.DEFAULT_PAGE);
  const [perpage, setPerpage] = useState<number>(CONSTANTS.DEFAULT_PER_PAGE);
  const loading = useSelector((state: RootState) => state.interventions.loading);
  const [status, setStatus] = useState<undefined | string>(undefined);
  const [isOutOfStock, setIsOutOfStock] = useState<boolean>(false);

  // const [serviceFiltred, setServiceFiltred] = useState<ServiceDelivery[]>([]);

  useEffect(() => {
    getInterventionsList();
  }, [status, isOutOfStock]);

  const getInterventionsList = async (pageValue: number = page, perpageValue: number = perpage) => {
    try {
      dispatch(getServiceDelivery(pageValue, perpageValue, status, isOutOfStock));
    } catch (error) {
      console.log('error');
    }
  };

  const handelChangePage = debounce((value: number) => {
    setPage(value);
    getInterventionsList(value, perpage);
  }, 500);

  const handelChangePerPage = debounce((value: number) => {
    setPerpage(value);
    setPage(CONSTANTS.DEFAULT_PAGE);
    getInterventionsList(CONSTANTS.DEFAULT_PAGE, value);
  }, 500);

  const handleSelectStatus = (value: string) => {
    const ListServices: ServiceDelivery[] = [];
    if (interventionsList?.items) {
      for (let i = 0; i < interventionsList?.items.length; i++) {
        ListServices.push(interventionsList?.items[i]);
      }
    }

    const lookUpTableStatus: Record<string, string> = {
      'intervention.todo': 'todo',
      'intervention.inprogress': 'inprogress',
      'intervention.validated': 'done',
    };

    if (Object.keys(lookUpTableStatus).includes(value)) {
      setStatus(lookUpTableStatus[value]);
    }

    // Maybe better way
    if (value === 'intervention.yes') {
      setIsOutOfStock(true);
    } else if (value === 'intervention.no') {
      setIsOutOfStock(false);
    }
  };

  return (
    <>
      <Grid container rowSpacing={5} direction="column">
        <Grid item>
          <OnTheTable
            title={t('intervention.interventions')}
            button={t('intervention.purge')}
            name={CONSTANTS.INTERVENTIONDAF}
            date={interventionsList?.items}
          />
        </Grid>
        <Grid item>
          <TableFactory
            loading={loading}
            name={CONSTANTS.INTERVENTIONDAF}
            headItems={headItems}
            bodyData={interventionsList?.items}
            handleSelectType={handleSelectStatus}
            rowDescription={row_description}
            canEdit={true}
            canDelete={false}
            count={interventionsList?.totalItems}
            page={page}
            perPage={perpage}
            handleChangePage={(value) => handelChangePage(value)}
            handleChangeRowsPerPage={(event) => handelChangePerPage(Number(event.target.value))}
          ></TableFactory>
        </Grid>
      </Grid>
    </>
  );
};
