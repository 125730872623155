import React from 'react';

export const PatrimonyMenu = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M18 9a2 2 0 0 1 2 2v4h10a2 2 0 0 1 2 2v14h-4v-5h-4v5h-8v-5h-4v5H8V11a2 2 0 0 1 2-2h8zm-2.5 10h-3v3h3v-3zm12 0h-3v3h3v-3zm-12-6h-3v3h3v-3z"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default PatrimonyMenu;
