export const GET_AGENCIES = 'agencies/getAll';
export const GET_DETAILS_AGENCY_BY_PATRIMONY = 'patrimonies/id/agency';
export const GETTING_DETAILS_AGENCY_BY_PATRIMONY = 'detailsAgency/loading';
export const GET_AGENCY_ERROR = 'agency/fetchError';

// REDUCER INTERFACE AGENCY
export interface Agency {
  id: string;
  agencyNumber: string;
  code: number;
  postalCode: string;
  city: string;
  street: string;
  name: string;
  phone: string;
  fax: string;
  email: string;
  schedule: ScheduleType;
}

export interface AgencyState {
  items: Agency[];
  loading: boolean;
  addLoading: boolean;
}

export interface ScheduleType {
  fri: string;
  mon: string;
  sat: string;
  thu: string;
  tue: string;
  wed: string;
}

interface GetDetailsAgencyAction {
  type: typeof GET_DETAILS_AGENCY_BY_PATRIMONY;
  payload: AgencyState;
}

interface LoadingDetailsAgencyAction {
  type: typeof GETTING_DETAILS_AGENCY_BY_PATRIMONY;
}

// GET AGENCIES ACTION
interface GetAgenciesAction {
  type: typeof GET_AGENCIES;
  payload: AgencyState;
}

interface ListErrorAction {
  type: typeof GET_AGENCY_ERROR;
}
export type AgencyActionTypes = GetAgenciesAction | GetDetailsAgencyAction | LoadingDetailsAgencyAction | ListErrorAction;
