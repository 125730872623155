import React from 'react';

export const MenuDots = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="34" height="34" viewBox="0 0 34 34" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd" opacity=".5">
        <path d="M5 5h24v24H5z" />
        <g transform="rotate(-180 9.3 11.7)" fill="#72706F" stroke="#72706F" strokeWidth=".8">
          <circle cx="1.6" cy="1.6" r="1.2" />
          <circle cx="1.6" cy="6.4" r="1.2" />
          <circle cx="1.6" cy="11.2" r="1.2" />
        </g>
      </g>
    </svg>
  );
};

export default MenuDots;
