import { DialogTitle } from '@mui/material';
import React, {useContext} from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../..';
import { colors } from '../../../theme/shared-theme';
import { CONSTANTS } from '../../../utils/constants';
import Clock from '../../ui/Icon/icons/Clock';
import GreenFilledTick from '../../ui/Icon/icons/greenFilledTick';
import OrangeFilledTick from '../../ui/Icon/icons/orangeFilledTick';
import { DeleteDialog } from '../deleteDialog/deleteDialog';
import {
  deleteIntervention,
  deleteServiceDelivery,
  deleteServiceDeliveryCalendar
} from '../../../store/intervention/action';
import { useDispatch } from 'react-redux';
import { ServiceDelivery } from '../../../store/intervention/type';
import GlobalContext from "../../../context/GlobalContext";
// declare type headerType = "update" | "success" | "create" | "pending";

interface DialogHeaderProps {
  type?: string;
  titleText?: string;
  showStatus?: boolean;
  etat?: string;
  onClose?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined) => void;
  onDelete?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined) => void;
  canDelete?: boolean;
  showDeleteDialog?: boolean;
  setShowChangeDialog?: (showDeleteDialog: boolean) => void;
  service?: string;
  handleCloseEdit?: () => void;
  data?: ServiceDelivery;
  weekTypeSelected: boolean;
}

/**
 * component describing the different forms of a dialog title
 *
 * @returns {JSX.Element}
 */

export const DialogHeader = (props: DialogHeaderProps) => {
  const {
    type,
    titleText,
    onClose = () => {},
    onDelete,
    canDelete = true,
    service,
    showDeleteDialog,
    setShowChangeDialog = () => {},
    handleCloseEdit = () => {},
    showStatus = true,
    etat = true,
    data,
    weekTypeSelected,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const gContext = useContext(GlobalContext);
  const deleteOneServiceDelivery = () => {
    console.log('delete now -- deleteOneServiceDelivery')
    if (service != null) {
      if(gContext?.weekTypeSelected) {
        console.log('dispatch good WS');
        dispatch(deleteIntervention(service))
      } else {
        dispatch(deleteServiceDelivery(service));
      }
      setShowChangeDialog(!showDeleteDialog);
      handleCloseEdit();
    }
  };
  const deleteService = () => {
    setShowChangeDialog(!showDeleteDialog);
    // if (data) {
    //   dispatch(deleteServiceDelivery(data.id));
    //   onClose();
    // } else {
    //   if (service != null) {
    //     if(gContext?.weekTypeSelected) {
    //       dispatch(deleteIntervention(service))
    //     } else {
    //       dispatch(deleteServiceDeliveryCalendar(service));
    //     }
    //
    //     setShowChangeDialog(!showDeleteDialog);
    //     handleCloseEdit();
    //   }
    // }
  };
  return (
    <>
      <DialogTitle>
        {weekTypeSelected && (
          <Button
            className={'head_icon delete_position'}
            onClick={deleteService}
            startIcon={{
              name: 'trash2',
              prefix: 'domanys',
            }}
          />
        )}
        {canDelete && type === 'update' && (
          <Button
            className={'head_icon delete_position'}
            onClick={deleteService}
            startIcon={{
              name: 'trash2',
              prefix: 'domanys',
            }}
          />
        )}
        {canDelete && (
          <DeleteDialog
            show={showDeleteDialog}
            changeDialog={() => setShowChangeDialog()}
            name={'intervention'}
            onDelete={deleteOneServiceDelivery}
          />
        )}
        {weekTypeSelected ? (
          <></>
        ) : (
          <>
            {' '}
            {showStatus && etat === CONSTANTS.STATUS_DONE ? (
              <>
                <GreenFilledTick />
                <p className={'bold_title'}>{t('Terminée')}</p>
              </>
            ) : etat === CONSTANTS.STATUS_IN_PROGRESS ? (
              <>
                <OrangeFilledTick />
                <p className={'orange_title'}>{t('intervention.inprogress')}</p>
              </>
            ) : (
              <></>
            )}
          </>
        )}

        {type === 'create' ? (
          <p className={'create_title'}>{titleText}</p>
        ) : type === 'export' ? (
          <p className={'export_title'}>{titleText}</p>
        ) : type === 'done' ? (
          <>
            <GreenFilledTick />
            <p className={'bold_title'}>{t('Terminée')}</p>
          </>
        ) : type === 'todo' ? (
          <>
            <OrangeFilledTick />
            <p className={'orange_title'}>{t('En attente')}</p>
          </>
        ) : type === 'pending' ? (
          <>
            <Clock color={colors.SECONDARY_ORANGE} />
            <p className={'bold_title'} style={{ color: colors.SECONDARY_ORANGE }}>
              {titleText}
            </p>
          </>
        ) : (
          <></>
        )}
        <Button
          className={'head_icon close_position'}
          onClick={onClose}
          startIcon={{
            name: 'close',
            prefix: 'domanys',
          }}
        />
      </DialogTitle>
    </>
  );
};
