import { Grid, ThemeProvider, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../..';
import { onTheTableTheme } from '../../../theme';
import { CONSTANTS, formatStartEndDate, getRole } from '../../../utils';
import { DeleteDialog } from '../../dialog/deleteDialog/deleteDialog';
import { ExportDialog } from '../../dialog/exportDialog/exportDialog';
import { CreateInterventionDialog } from '../../dialog/interventionsDialog/createInterventionDialog';
import { NotificationsDialog } from '../../dialog/notificationsDialog/notificationsDialog';
import { UsersDialog } from '../../dialog/usersDialog/usersDialog';

interface OnTheTableProps {
  title: string;
  button?: string;
  name?: string;
  date?: Date[];
}

/**
 *component returning text and buttons to the top of the table
 *
 * @returns {ExoticComponent<() => JSX.Element>}
 */
export const OnTheTable = (props: OnTheTableProps) => {
  const { title, button, name, date } = props;
  const { t } = useTranslation();

  const [open1, setopen1] = React.useState(false);
  const [open2, setopen2] = React.useState(false);
  const [listeDates, setListeDates] = React.useState([]);

  const showDialog1 = () => {
    setopen1(!open1);
  };
  const showDialog2 = () => {
    setopen2(!open2);

    if (date) {
      const listeD: date[] = [];
      date.map((x) => listeD.push(formatStartEndDate(x.date)));
      setListeDates(listeD);
    }
  };

  return (
    <>
      <ThemeProvider theme={onTheTableTheme}>
        <Grid item>
          <Grid container columnSpacing={2}>
            <Grid item>
              <Typography>{title}</Typography>
            </Grid>
            <>
              {name === CONSTANTS.INTERVENTIONDAF ? (
                getRole() === CONSTANTS.DAF_ROLE && (
                  <>
                    <Grid item>
                      <Button
                        className={'success_button'}
                        variant="contained"
                        type="error"
                        size="large"
                        label={t('intervention.export')}
                        onClick={showDialog1}
                      />
                    </Grid>
                    <Grid item>
                      <Button className={'error_button'} type="error" size="large" label={t('intervention.purge')} onClick={showDialog2} />
                    </Grid>
                  </>
                )
              ) : (
                <Grid item>
                  <Button className={'button'} variant="outlined" type="primary" size="large" label={button} onClick={showDialog1} />
                </Grid>
              )}
            </>
          </Grid>
        </Grid>
        {name === CONSTANTS.USER ? (
          <UsersDialog show={open1} changeDialog={(value: boolean) => setopen1(value)} type={'create'} />
        ) : name === 'notification' ? (
          <NotificationsDialog show={open1} type={'create'} changeDialog={(value: boolean) => setopen1(value)} />
        ) : name === CONSTANTS.INTERVENTIONDAF ? (
          <>
            <ExportDialog show={open1} changeDialog={(value: boolean) => setopen1(value)} disabled={false} />
            <DeleteDialog
              show={open2}
              message={t('intervention.delete_message')}
              changeDialog={(value: boolean) => setopen2(value)}
              name={'interventionDAF'}
              onDelete={undefined}
              listeDates={listeDates}
            />
          </>
        ) : (
          <>
            <CreateInterventionDialog show={open1} type={'create'} changeDialog={(value: boolean) => setopen1(value)} />
          </>
        )}
      </ThemeProvider>
    </>
  );
};
