import React from 'react';

export const Article = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="34" height="34" viewBox="0 0 34 34" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.355 16.157a.612.612 0 0 1-.451-.177c-.119-.119-.178-.28-.178-.481 0-.132.027-.282.081-.45l1.27-3.751c.195-.578.544-.867 1.045-.867.528 0 .89.289 1.086.867l1.259 3.752a.984.984 0 0 1 .066.245c.01.08.015.148.015.204a.676.676 0 0 1-.167.475c-.112.122-.27.183-.472.183a.614.614 0 0 1-.437-.151c-.108-.101-.19-.263-.244-.486l-.243-.7h-1.706l-.213.7c-.095.425-.332.637-.71.637zm1.178-2.393h1.208l-.568-1.996h-.061l-.579 1.996zm5.127-1.41a.702.702 0 0 1-.533-.22.746.746 0 0 1-.208-.533c0-.209.07-.385.208-.528a.71.71 0 0 1 .533-.214h3.096c.21 0 .384.072.523.214a.728.728 0 0 1 .208.528c0 .21-.07.387-.208.533a.69.69 0 0 1-.523.22H18.66zm0 3.458a.702.702 0 0 1-.533-.22.76.76 0 0 1-.208-.542.71.71 0 0 1 .74-.732h3.097a.71.71 0 0 1 .731.731.76.76 0 0 1-.208.544.69.69 0 0 1-.523.22H18.66zm-7.147 8.548v-4.859c0-.453.12-.794.36-1.024.24-.23.57-.345.99-.345h8.305c.886 0 1.33.457 1.33 1.37v4.733h-1.199l-1.939-2.32a.716.716 0 0 0-.304-.22 1.021 1.021 0 0 0-.706 0 .947.947 0 0 0-.34.22l-2.03 1.86-.812-.763a.87.87 0 0 0-.6-.25.802.802 0 0 0-.578.25l-1.27 1.348h-1.207zm3.736-2.435c.29 0 .541-.11.751-.329.21-.22.315-.479.315-.778 0-.3-.105-.554-.315-.763a1.066 1.066 0 0 0-.751-.324.952.952 0 0 0-.757.308 1.08 1.08 0 0 0-.32.779c0 .3.107.559.32.778.214.22.466.33.757.33zM8 25.311c0 1.198.306 2.113.919 2.743.612.63 1.494.946 2.644.946h10.874c1.157 0 2.04-.315 2.65-.946.608-.63.913-1.545.913-2.743V9.69c0-1.191-.305-2.104-.914-2.738-.609-.634-1.492-.951-2.65-.951H11.564c-1.15 0-2.032.317-2.644.95C8.306 7.586 8 8.499 8 9.69V25.31z"
        fill="#72706F"
        fillRule="nonzero"
        opacity={0.5}
      />
    </svg>
  );
};

export default Article;
