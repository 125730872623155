import React, {useContext, useEffect, useState} from "react"
import Select from "react-select"
import {patrimonies} from "../../api/patrimonies";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../index";
import GlobalContext from "../../context/GlobalContext";
import {getUser} from "../../utils";

import {getAllPatrimoniesByAgency} from '../../store/patrimonies/actions';

const CustomStyle = {
    option: (base: any) => ({
        ...base,
        color: '#000',
        fontSize: '14px'
    })
}
export type PatrimoniesSelectProps = {
    popup?: boolean;
    selectedPat?: string;
    setPatrimony: (value: string) => void;
}
const defaultText = 'Sélectionner un patrimoine';
const getSomePatrimoniesList = async (params: {
                                          'page'?: number;
                                          'filter[where][refPat][like]'?: string;
                                          'pat_levels'?: String[];

                                      }
) => {
    return await patrimonies.getSomePatrimonies(params);

};
export const PatrimoniesSelect = (props: PatrimoniesSelectProps) => {
    const [defaultPat, setDefaultPat] = useState(defaultText)
    const [optionsPat, setOptionsPat] = useState<{ value: string, label: string }[]>([]);
    const [patS, setPatS] = useState([]);

    const user = getUser();
    // get dispatch hooks
    const dispatch = useDispatch();

    const gContext = useContext(GlobalContext);
    const handlerPatrimony = (event: any) => {
        const patrimony = patS?.find((value) => value.id === event.value);
        if (props.popup) {
            // @ts-ignore
            props.setPatrimony(patrimony.refPat);
        } else {
            gContext?.handleChangePatrimony(patrimony || null);
        }
    }
    const handlerSearch = (term: any) => {
        const pat = []
        pat.push("1");
        pat.push("2");
        pat.push("3");
        if (term != '') {
            getSomePatrimoniesList({
                "filter[where][refPat][like]": "%" + term + "%", "pat_levels": pat
            }).then((data) => {
                updateOptions(data);
            })
        }
    }
    const updateOptions = (data: string | any[]) => {
        const pats: { value: string, label: string }[] = [];
        for (let i = 0; i < data.length; i++) {
            pats.push({
                value: data[i].id,
                label: data[i].refPat.slice(0, 17)
            });
            setPatS(data)
        }
        setOptionsPat(pats);
    }
    useEffect(() => {
        let params: any
        if (props.selectedPat) {
            params = {
                'filter[where][refPat][like]': "%" + props.selectedPat.substring(0, 10) + "%",
                'page': 1
            }
        } else {
            params = {
                'page': 1
            }
        }
        getSomePatrimoniesList(params).then((data) => {
            updateOptions(data.items)
        });
    }, []);
    useEffect(() => {
        setDefaultPat(props.selectedPat ?
            props.selectedPat :
            ((gContext?.selectedPatrominy && gContext.selectedPatrominy?.id !== '')
                ? gContext.selectedPatrominy.id : defaultText))
    }, [optionsPat])

    return (
        <div style={{width: '260px'}}>
            {optionsPat.length > 0 && <Select options={optionsPat}
                                              onChange={handlerPatrimony}
                                              onInputChange={handlerSearch}
                                              styles={CustomStyle}
                                              placeholder={defaultPat}
                                              defaultValue={{
                                                  value: defaultPat == defaultText ? defaultPat : defaultPat.slice(0, 17),
                                                  label: defaultPat
                                              }}
            />
            }
        </div>
    )
}
