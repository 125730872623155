export const intervention = {
  interventions: ' Interventions ',
  intervention: ' Intervention ',
  export: ' Exporter ',
  purge: ' Purger ',
  creer: ' Créer Intervention ',
  type: ' Type ',
  ref_patrimony: ' Ref. Patrimoine ',
  date: ' DATE ',
  duration: ' DURÉE ',
  stock_withdrawal: ' SORTIE DE STOCK ',
  status: ' STATUT ',
  pending: ' En attente ',
  validated: ' Terminée ',
  todo: ' à faire ',
  csv: 'CSV',
  excel: 'Excel',
  pdf: 'PDF',
  from: ' Du ',
  to: ' Au ',
  delete: ' Supprimer ',
  delete_message: ' Voulez vous supprimer cette intervention? ',
  yes: ' Oui',
  no: ' Non ',
  Collaborators: ' Collaborateurs ',
  done: ' Terminée',
  inprogress: ' En cours ',
  create_intervention: ' Créer une intervention ',
  select_prestation_type: ' Sélectionner un type de prestation ',
  add_collaborator: ' Ajouter un collaborateur ',
  add_intervention: ' Ajouter intervention ',
  add_success: ' Intervention ajoutée avec succès ',
  update_success: ' Intervention modifiée avec succès ',
  delete_success: ' Intervention supprimée avec succès ',
  starts_at_error: ' Il faut choisir une date de début ! ',
  ends_at_error: ' Il faut choisir une date de fin ! ',
  download_type: " Il faut choisir un type d'exportation ",
  export_success: ' Exportation faite avec succès ',
  purge_success: ' Les interventions purgés avec succès',
  date_valide: 'Choisir une date valide',
  MONDAY: 'Lundi',
  TUESDAY: 'Mardi',
  WEDNESDAY: 'Mercredi',
  THURSDAY: 'Jeudi',
  FRIDAY: 'Vendredi',
  SATURDAY: 'Samedi',
  SUNDAY: 'Dimanche',
  selectOneDay: 'Sélectionner un jour',
  jobCreated: 'L\'intervention est bien créée!'
};
