import * as React from 'react';

export const View = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="34" height="34" viewBox="0 0 34 34" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g stroke="#72706F" strokeWidth="1.5" fill="none" fillRule="evenodd" opacity=".5" strokeLinecap="round">
        <path d="M14.667 11.56V9H26v10.88H22.852" opacity=".5" strokeLinejoin="round" strokeDasharray=".64" />
        <path strokeLinejoin="round" d="M20.333 19.24V25H9V14.12h6.296M20.354 16.696l-.02-2.54-2.54-.02" />
        <path d="m20.333 14.156-4.407 4.408" />
      </g>
    </svg>
  );
};
