import {ENDPOINTS, sendAsyncRequest} from "../utils";

const getMyCalendar = async (params?: any) => {
    const myCalendarResponse = await sendAsyncRequest('get', ENDPOINTS.MyCalendar, true, params, undefined);
    return myCalendarResponse.data;
};
const getMyWeekCalendar = async (params: any, url: string) => {
    const weekCalendarResponse = await sendAsyncRequest('get', url, true, params, undefined);
    return weekCalendarResponse.data;
};

const deleteIntervention = async (id: string): Promise<any> => {
    const response = await sendAsyncRequest('delete', ENDPOINTS.JobScheduleMin + '/' + id, true, undefined, undefined);
    return response.data;
};
export type addJobScheduleProps = {
    weekday: string;
    initialTimeRange: string[];
    isIntervention: true;
    status: string;
    outOfStock: boolean;
    comment: string;
    creatorId: string;
    dayScheduleId: number;
    refPat: string;
    serviceDeliveryTypeIds: string[];
    collabIds: string[];
}
const addJobSchedule = async (data: addJobScheduleProps, url: string) => {
    const newJob = await sendAsyncRequest('post', url, true, undefined, data);
    return newJob.data;
}
export const calendarService = {
    getMyCalendar,
    addJobSchedule,
    getMyWeekCalendar,
    deleteIntervention
}
