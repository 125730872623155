import { DialogContent, Grid } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Calendar } from '../../../ui/Calendar/calendar';
import { DialogTitle } from '../../../ui/Dialog/DialogLabel/DialogLabel';
import { DialogRadioGroup } from '../../../ui/Dialog/DialogRadioGroup/DialogRadioGroup';
import { DialogSelect } from '../../../ui/Dialog/DialogSelect/DialogSelect';
import { DialogTextArea } from '../../../ui/Dialog/DialogTextArea/DialogTextArea';
import { DialogTextField } from '../../../ui/Dialog/DialogTextField/DialogTextField';
import Clock from '../../../ui/Icon/icons/Clock';
import Profile from '../../../ui/Icon/icons/Profile';

/**
 * component describing pending/validated interventions dialog
 *
 *
 * @returns {JSX.Element}
 */
const initialValues = {
  heure: '',
  function: '',
  civility: '',
  title: '',
  lastname: '',
  login: '',
  state: '',
};

export const NotificationDialogBody = () => {
  const { t } = useTranslation();

  const [active, setActive] = React.useState(true);

  const saveChosenOption = (chosenOption: string) => {
    setActive(chosenOption === t('notification.now'));
  };

  const [values, setValues] = useState(initialValues);
  const [errors] = useState({
    title: '',
    lastname: '',
    login: '',
    heure: '',
    function: '',
  });

  // const validate = () => {
  //   errors.title = values.title ? '' : t('error.empty_field');
  //   errors.lastname = values.lastname ? '' : t('error.empty_field');
  //   errors.login = values.login ? '' : t('error.empty_field');
  //   errors.heure = values.heure ? '' : t('error.empty_field');
  //   errors.function = values.function ? '' : t('error.empty_field');
  //   setErrors({
  //     ...errors,
  //   });
  //   return Object.values(errors).every((x) => x === '');
  // };

  const handleChange = (prop: string) => (event: React.ChangeEvent<HTMLSelectElement>) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  return (
    <>
      <DialogContent>
        <Grid container direction="column" alignItems="flex-start" rowSpacing={1.5}>
          <Grid item>
            <DialogTextField
              disableUnderline={false}
              error={errors.title}
              label={t('notification.add_title')}
              name="title"
              value={values.title}
              onChange={handleChange('title')}
              withIcon={false}
              bigSize
            />
          </Grid>
          {/* <Box sx={{ m: 0.5 }} /> */}
          <Grid item>
            <DialogRadioGroup
              onchooseOption={saveChosenOption}
              items={[
                {
                  id: t('notification.now'),
                  title: t('notification.now'),
                },
                {
                  id: t('notification.program'),
                  title: t('notification.program'),
                },
              ]}
              icon={Clock}
            ></DialogRadioGroup>
          </Grid>
          <Grid item>
            <Calendar disabled={active} />
          </Grid>
          {!active && (
            <Grid item>
              <DialogSelect
                label={'Heure'}
                value={values.heure}
                onChange={handleChange('heure')}
                icon={Clock}
                items={[
                  { label: '10:00', value: '10' },
                  { label: '10:30', value: '20' },
                  { label: '11:00', value: '30' },
                  { label: '11:30', value: '40' },
                ]}
              />
            </Grid>
          )}
          <Grid item>
            <DialogTextArea />
          </Grid>
          {/* <Box sx={{ m: 0.75 }} /> */}
          <Grid item>
            <DialogTitle label={t('notification.add_collaborators')} icon={Profile} />
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
};
