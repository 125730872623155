export const GET_USERS = 'users/getAll';
export const ADD_USER = 'user/add';
export const UPDATE_USER = 'user/update';
export const DELETE_USER = 'user/delete';
export const GET_ROLES = 'roles/getAll';
export const GETTING_USERS = 'user/loading';
export const ADDING_USER = 'user_add/loading';
export const UPDATING_USER = 'user_update/loading';
export const GET_USERS_ERROR = 'user/fetchError';

// REDUCER INTERFACE USER
export interface User {
  id: string;
  firstname: string;
  lastname: string;
  civility: string;
  login: string;
  email: string;
  active: boolean;
  type: string;
  roleId: string;
  agencyId: string;
  state: string;
  function: string;
  distinctColor: string;
  role: string;
  role_level: number;
  role_alias: string;
  role_access: string;
  role_isDisplayed: boolean;
  role_description: string;
  lastConnexionTime: string;
  roles: Role[];
}

// REDUCER INTERFACE ROLE

export interface Role {
  id: number;
  role: string;
  level: number;
  alias: string;
  access: string;
  isDisplayed: boolean;
  description: string;
}

export interface UsersPaginated {
  filter(arg0: (user: User) => boolean): UsersPaginated;
  items?: User[];
  lastPage?: number;
  totalItems?: number;
}

export interface UserState {
  users: UsersPaginated;
  roles: Role[];
  loading: boolean;
  addLoading: boolean;
}

// GET USERS ACTION
interface GetUsersAction {
  type: typeof GET_USERS;
  payload: UsersPaginated;
}
// ADD USER ACTION
interface AddUserAction {
  type: typeof ADD_USER | typeof ADDING_USER;
}
// UPDATE USER ACTION
interface UpdateUserAction {
  type: typeof UPDATE_USER | typeof UPDATING_USER;
}

// GET ROLES ACTION
interface GetRolesAction {
  type: typeof GET_ROLES;
  payload: Role[];
}

interface LoadingListAction {
  type: typeof GETTING_USERS;
}

interface ListErrorAction {
  type: typeof GET_USERS_ERROR;
}

export type UserActionTypes = GetUsersAction | AddUserAction | UpdateUserAction | GetRolesAction | LoadingListAction | ListErrorAction;
