import React, { useEffect, useState } from 'react';
import { Grid, CircularProgress } from '@mui/material';
import { CheckboxPrestationItem } from '../checkBoxPrestationsItem/checkBoxPrestationItem';
import { useTranslation } from 'react-i18next';

interface PrestationItem {
  type: string;
  duration: string;
  id: string;
}

interface PrestationsListProps {
  data: Array<PrestationItem>;
  loading?: boolean;
  handlecheckedServiceType: (value: string[]) => void;
}

export const PrestationsList = (props: PrestationsListProps) => {
  const { data, loading, handlecheckedServiceType } = props;
  const [selectedServiceTypes, setSelectedServiceTypes] = useState<string[]>([]);
  const { t } = useTranslation();

  /**
   * on check/uncheck service type
   * @param id
   * @param value
   */
  const handlecheckedList = (id: string, value: boolean) => {
    let list = [...selectedServiceTypes];
    if (value) {
      list.push(id);
    } else {
      list = list.filter(function (item) {
        return item !== id;
      });
    }
    console.log('id to add', id);
    setSelectedServiceTypes([...list]);
    handlecheckedServiceType(list);
  };

  useEffect(() => {
    if (data) {
      const idList = data ? data.map((a) => a.id) : [];
      setSelectedServiceTypes(idList);
    }
  }, [data]);

  return (
    <>
      <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" rowSpacing={0.5}>
        {loading ? (
          <Grid container justifyContent="center">
            <CircularProgress size={20} color="inherit" />
          </Grid>
        ) : data && data?.length > 0 ? (
          data.map((item: PrestationItem, index: number) => (
            <Grid item key={`table-row-${index}`}>
              <CheckboxPrestationItem
                type={item.type}
                duration={item.duration}
                value={!!selectedServiceTypes.includes(item.id)}
                handlechecked={(value) => handlecheckedList(item.id, value)}
              />
            </Grid>
          ))
        ) : (
          <>{t('common.no_data')}</>
        )}
      </Grid>
    </>
  );
};
