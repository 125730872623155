import MuiAlert from '@mui/lab/Alert';
import { Snackbar as SnackBarComponent } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../..';
import { hideSnackBar } from '../../../store/snackBar/actions';
import { alertTheme } from '../../../theme';

/**
 * Component SnackBar
 *
 * @component
 *
 * @example
 * return (
 *   <SnackBar/>
 * )
 */
export const SnackBar = () => {
  // get redux snack bar state
  const snackBar = useSelector((state: RootState) => state.snackBar);
  // get dispatch hooks
  const dispatch = useDispatch();
  // translation hooks
  const { t } = useTranslation();
  // handle close snack bar
  const handleClose = useCallback(() => {
    dispatch(hideSnackBar());
  }, [dispatch]);

  return (
    <SnackBarComponent
      open={snackBar.open}
      autoHideDuration={600000000000000}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <div>
        <ThemeProvider theme={alertTheme}>
          <MuiAlert icon={false} elevation={6} variant="filled" severity={snackBar.severity}>
            {t(snackBar.message)}
          </MuiAlert>
        </ThemeProvider>
      </div>
    </SnackBarComponent>
  );
};
export default SnackBar;
