import { Navigate, useLocation } from 'react-router-dom';
import { PATHS, isLoggedIn, isRoleValid } from '../utils';
import React from 'react';
function GuestRoute({ children }: { children: JSX.Element }) {
  const location = useLocation();

  if (isLoggedIn() && isRoleValid()) {
    // Redirect them to the home page if they are logged
    return <Navigate to={PATHS.Users} state={{ from: location }} />;
  }

  return children;
}

export default GuestRoute;
