import { Box, Dialog, DialogContent, Grid, ThemeProvider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { dialogTheme } from '../../../theme';
import { DialogTextField } from '../../ui/Dialog/DialogTextField/DialogTextField';
import Location from '../../ui/Icon/icons/location';
import { DialogActions } from '../dialogActions/dialogActions';
import { DialogHeader } from '../dialogTitle/dialogTitle';
import ListIcon from '../../ui/Icon/icons/list';
import Patrimony from '../../ui/Icon/icons/Patrimony';
import { useDispatch, useSelector } from 'react-redux';
import { getPatrimonies, getPatrominyDetail, updatePatrimony } from '../../../store/patrimonies/actions';
import { RootState } from '../../..';
import { DialogSwitch } from '../../ui/Dialog/DialogSwitch/DialogSwitch';
import { Patrimony as PatrimonyType } from '../../../store/patrimonies/types';

const initialValues = {
  type: [],
  duration: 0,
  refPat: '',
  streetName: '',
};

interface PrestationsDialogProps {
  show?: boolean;
  changeDialog: (value: boolean) => void;
  type: string;
  data?: PatrimonyType;
}

/**
 * component describing an update/create prestation dialog
 *
 * @returns {JSX.Element}
 */

export const PrestationsDialog = (props: PrestationsDialogProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { show = false, changeDialog, type, data } = props;
  const [open, setOpen] = React.useState<boolean>(show);
  const [values, setValues] = useState(initialValues);
  const [patrimonyId, setPatrimonyId] = useState<string>('');
  const [actifServiceTypeIds, setActifServiceTypeIds] = useState<string[]>([]);

  const serviceTypes = useSelector((state: RootState) => state.patrimonies.patrimony.sdTypes);
  const getAllPatrimonies = () => {
    dispatch(getPatrimonies());
  };

  const getPatrominy = () => {
    dispatch(getPatrominyDetail(patrimonyId));
  };
const removeEmpty=()=>{
  console.log("here3")
  const ids = [];
  actifServiceTypeIds.map((el) => {
    ids.push(el);
  });
  for (let i = 0; i < ids.length; i++) {
    if (ids[i] === "9") {
      ids.splice(i, 1);
    }
  }
  console.log(ids)
  setActifServiceTypeIds(ids);

}

  const getServicesStatus = (id: string, status: boolean) => {
    const ids = [];
    actifServiceTypeIds.map((el) => {
      ids.push(el);
    });
    if (status) {
      if(id!="9"){
      for (let i = 0; i < ids.length; i++) {
        if (ids[i] === "9") {
          ids.splice(i, 1);
        }
      }
    }
      ids.push(id);
      console.log(ids)
      setActifServiceTypeIds(ids);
    } else {
      for (let i = 0; i < ids.length; i++) {
        if (ids[i] === id) {
          ids.splice(i, 1);
        }
      }
      if(ids.length==0){
        ids.push("9");
      }
      setActifServiceTypeIds(ids);
    }
  };

  const handleClose = () => {
    setOpen(false);
    changeDialog(false);
  };

  useEffect(() => {
    if (serviceTypes) {
      const actifs = Object.entries(serviceTypes).filter((el) => el[1].status);
      const ids = [];
      actifs.map((el) => {
        ids.push(el[0]);
      });
      setActifServiceTypeIds(ids);
    }
  }, [serviceTypes]);

  const handleSubmitModifier = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    try {
      await dispatch(updatePatrimony(data.id, data.refPat, data.agencyId, actifServiceTypeIds));
      getAllPatrimonies();
      setPatrimonyId('');
    } catch {
      // catch
    }
    setOpen(false);
    changeDialog(false);
  };

  useEffect(() => {
    if (data) {
      setPatrimonyId(data.id);
      setValues({ ...data });
    } else {
      setValues({ ...initialValues });
    }
    setOpen(show);
    if (show) {
      getAllPatrimonies();
    }
  }, [show]);

  useEffect(() => {
    if (patrimonyId) {
      getPatrominy();
    }
  }, [patrimonyId]);

  return (
    <>
      <ThemeProvider theme={dialogTheme}>
        <Dialog open={open}>
          <DialogHeader onClose={handleClose} type={type} canDelete={false} titleText={t('prestation.create_prestation')} />
          <DialogContent sx={{ mt: 4 }} style={{ overflowY: 'scroll' }}>
            <Grid container direction="column" alignItems="flex-start" rowSpacing={1}>
              <Box sx={{ m: 2 }} />
              <Grid container direction="row" spacing={3.4} pt={1}>
                <Grid item>
                  <Patrimony />
                </Grid>
                <Grid item md={8} color={'gray'}>
                  {data.refPat}
                </Grid>
              </Grid>
              <ul></ul>
              <Box sx={{ m: -3 }} />
              <Grid item>
                <DialogTextField
                  label=""
                  name="streetName"
                  icon={Location}
                  value={values.streetName}
                  withIcon={true}
                />
              </Grid>
              <Box sx={{ m: 1 }} />
              <Grid container direction="row" spacing={3.4} pt={1}>
                <Grid item>
                  <ListIcon />{' '}
                </Grid>
                <Grid item md={8} color={'gray'}>
                  {t('prestation.type_prestations')}
                </Grid>
              </Grid>
              <Box sx={{ m: 1 }} />
              <Grid item sx={{ m: 0.3 }}>
                {serviceTypes &&
                  Object.entries(serviceTypes).map((el) => {
                    return (
                      // eslint-disable-next-line react/jsx-key
                      <DialogSwitch
                        label={el[1].ref_obj.type}
                        duration={el[1].ref_obj.duration}
                        status={el[1].status}
                        id={el[0]}
                        getServicesStatus={getServicesStatus}
                      />
                    );
                  })}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions onClose={handleClose} type={type} onAction={handleSubmitModifier} secondButtonLabel={'Enregistrer'} />
        </Dialog>
      </ThemeProvider>
    </>
  );
};
