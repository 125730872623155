import { Box, Dialog, DialogContent, DialogTitle, Grid, Stack, ThemeProvider, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { ChangeEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { dialogTheme } from '../../../theme';
import { colors } from '../../../theme/shared-theme';
import { CONSTANTS, formatStartEndDate } from '../../../utils';
import { Calendar } from '../../ui/Calendar/calendar';
import { Delete } from '../../ui/Icon/icons/Delete';
import { DialogActions } from '../dialogActions/dialogActions';
import { useDispatch } from 'react-redux';
import { purgeServiceDelivery } from '../../../store/intervention/action';
/**
 * common delete dialog dialog shown to the user to confirm a table line delete
 * or a purge operation in interventions page
 *
 * @returns {JSX.Element}
 */
interface DeleteDialogProps {
  show?: boolean;
  message?: string;
  changeDialog?: (value: boolean) => void;
  name?: string;
  onDelete?: () => void;
  data?: Array<string>;
  listeDates?: Date[];
}

const useStyles = makeStyles({
  dialog_width: {
    '& .MuiDialog-paper': {
      height: '341px',
      minHeight: '100px',
    },
  },
});

export const DeleteDialog = (props: DeleteDialogProps) => {
  const { show = false, changeDialog, name, onDelete, listeDates } = props;
  const [open, setOpen] = React.useState(show);
  const [date, setDate] = React.useState('');
  const [dateError, setDateError] = React.useState('');
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleCancellation = () => {
    setOpen(false);
    changeDialog(false);
  };

  const onPurge = () => {
    const d = formatStartEndDate(date);
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < listeDates.length; i++) {
      if (listeDates[i] <= d) {
        dispatch(purgeServiceDelivery(d));
        changeDialog(false);
      } else {
        setDateError(t('intervention.date_valide'));
      }
    }
  };

  useEffect(() => {
    setOpen(show);
  }, [show]);

  const classes = useStyles();

  return (
    <>
      <ThemeProvider theme={dialogTheme}>
        <Dialog className={classes.dialog_width} onClose={handleCancellation} open={open}>
          <DialogTitle>
            <Grid container justifyContent="center">
              <Box sx={{ marginTop: '36px', paddingRight: '13px' }}>
                <Delete />
              </Box>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Stack direction="column" alignItems="center">
              <Typography gutterBottom align="center" style={{ fontSize: '20px', fontFamily: 'ArialMT' }}>
                {name === CONSTANTS.INTERVENTIONDAF ? <>{t('intervention.purge')}</> : <>{t(name + '.delete')}</>}
              </Typography>

              <Typography
                gutterBottom
                align="center"
                sx={{
                  fontSize: '16px',
                  fontFamily: 'ArialMT',
                  color: colors.MAIN_GREY,
                }}
              >
                {name === CONSTANTS.INTERVENTIONDAF ? <>{t(name + '.purge_message')}</> : <>{t(name + '.delete_message')}</>}
              </Typography>

              {name == CONSTANTS.INTERVENTIONDAF && (
                <>
                  <Box sx={{ paddingLeft: '70px' }}>
                    <Calendar
                      error={dateError}
                      value={date}
                      onChange={(newValue: ChangeEvent<Date>) => {
                        return setDate(new Date(newValue.toString()));
                      }}
                      disabled={false}
                    />
                  </Box>
                </>
              )}
            </Stack>
          </DialogContent>
          <DialogActions
            type={CONSTANTS.DELETE}
            onClose={handleCancellation}
            secondButtonLabel={name === CONSTANTS.INTERVENTIONDAF ? t('intervention.purge') : t(name + '.delete')}
            onAction={name == CONSTANTS.INTERVENTIONDAF ? onPurge : onDelete}
          />
        </Dialog>
      </ThemeProvider>
    </>
  );
};
