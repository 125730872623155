import {MyCalendarType} from "./type";
import {SET_CALENDAR} from "./const";

const INITIAL_STATE: MyCalendarType = {
    calendar: [],
    collabs: [],
    patrimonies: [],
    serviceDeliveryTypes: [],
    weekTemplateId: ''
};

export const MyCalendarReducer = (state: MyCalendarType = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case SET_CALENDAR:
            return {
                ...state,
                calendar: action.payload
            };
        default:
            return state;
    }
};
