import { createTheme } from '@mui/material/styles';
import { colors } from './shared-theme';

export const buttonTheme = createTheme({
  palette: {
    primary: {
      main: colors.WHITE,
      contrastText: colors.WHITE,
    },
    secondary: {
      main: colors.TERTIARY_BLUE,
      contrastText: colors.TERTIARY_BLUE,
      dark: colors.DARK_GREY,
    },
    action: {
      disabledBackground: colors.MAIN_BLUE,
      disabled: colors.WHITE,
      disabledOpacity: 0.3,
    },
    contrastThreshold: 3,
    tonalOffset: 0.1,
  },
  shape: {
    borderRadius: 5.1,
  },
  shadows: [
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
  ],
  typography: {
    allVariants: {
      textTransform: 'none',
      display: 'flex',
      alignItems: 'center',
      fontFamily: 'Arial',
      fontStyle: 'normal',
      fontWeight: 'bold',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          background: colors.MAIN_BLUE,
          height: '48px',
          minWidth: 'none',
          '&:hover': {
            color: colors.MAIN_BLUE,
            borderColor: colors.MAIN_BLUE,
            '& .button-icon .plus': {
              stroke: colors.MAIN_BLUE,
            },
          },
          '&.Mui-disabled': {
            opacity: 0.3,
          },
        },
        outlinedSecondary: {
          'color:hover': 'red',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            opacity: 0.3,
          },
        },
      },
    },
  },
});
