import React from 'react';

export const Prestation = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" {...props}>
      <path
        d="M20.727 31.402c.312 0 .576-.09.79-.27.215-.179.358-.421.428-.726l.563-2.39a7.93 7.93 0 0 0 .58-.211c.191-.078.377-.16.557-.246l2.085 1.289c.258.164.528.232.81.205.28-.028.526-.147.737-.358l1.43-1.43c.219-.21.34-.46.363-.75.024-.288-.05-.562-.222-.82l-1.278-2.074c.086-.18.168-.361.246-.545.079-.183.145-.373.2-.568l2.402-.563c.312-.062.555-.203.727-.422a1.25 1.25 0 0 0 .257-.796v-2.004c0-.305-.086-.567-.257-.785-.172-.22-.415-.36-.727-.422l-2.39-.575a7.12 7.12 0 0 0-.206-.597 8.53 8.53 0 0 0-.228-.528l1.277-2.097c.172-.266.246-.537.223-.815a1.173 1.173 0 0 0-.364-.744l-1.453-1.43a1.268 1.268 0 0 0-.72-.351 1.285 1.285 0 0 0-.791.176l-2.121 1.312a6.806 6.806 0 0 0-.54-.24 11.546 11.546 0 0 0-.597-.217l-.563-2.426a1.266 1.266 0 0 0-.427-.72A1.208 1.208 0 0 0 20.727 8h-2.04c-.312 0-.578.088-.796.264a1.253 1.253 0 0 0-.434.72l-.562 2.403c-.204.07-.403.144-.598.222-.195.079-.379.16-.55.246l-2.122-1.3a1.24 1.24 0 0 0-.78-.176 1.268 1.268 0 0 0-.72.351l-1.453 1.43c-.219.219-.34.467-.363.744-.024.278.05.55.222.815l1.278 2.097a8.53 8.53 0 0 0-.229.528c-.074.187-.146.386-.217.597l-2.379.575c-.312.062-.554.203-.726.422-.172.218-.258.48-.258.785v2.004c0 .312.086.578.258.796.172.22.414.36.726.422l2.403.563a5.4 5.4 0 0 0 .193.568c.074.184.154.365.24.545l-1.265 2.074c-.18.258-.256.532-.229.82.028.29.15.54.37.75l1.429 1.43c.21.211.457.33.738.358.282.027.551-.041.809-.205l2.086-1.29a7.861 7.861 0 0 0 1.137.458l.562 2.39c.07.305.215.547.434.727.218.18.484.27.797.27h2.039zm-1.02-7.816a3.761 3.761 0 0 1-1.963-.527 4.008 4.008 0 0 1-1.412-1.413 3.761 3.761 0 0 1-.527-1.962c0-.704.175-1.35.527-1.94.352-.59.822-1.06 1.412-1.412a3.761 3.761 0 0 1 1.963-.527c.711 0 1.361.175 1.951.527.59.352 1.06.822 1.412 1.412.352.59.528 1.236.528 1.94 0 .718-.176 1.373-.528 1.962a4.008 4.008 0 0 1-1.412 1.413 3.74 3.74 0 0 1-1.951.527z"
        fillRule="nonzero"
      />
    </svg>
  );
};

export default Prestation;
