/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Visibility } from '@mui/icons-material';
import {
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  ThemeProvider,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Button } from '../../ui/Button/Button';
import { tableTheme } from '../../../theme';
import { colors } from '../../../theme/shared-theme';
import { CONSTANTS, formatDate, formatDateServiceDelivery } from '../../../utils';
import { CreateInterventionDialog } from '../../dialog/interventionsDialog/createInterventionDialog';
import { SentNotificationDialog } from '../../dialog/notificationsDialog/sentNotificationsDialog';
import { PrestationsDialog } from '../../dialog/prestationsDialog/prestationsDialog';
import { UsersDialog } from '../../dialog/usersDialog/usersDialog';
import OrangeFilledCircle from '../../ui/Icon/icons/OrangeFilledCircle';
import { Head } from '../tableHead/TableHead';
import { DafInterventionDiaolg } from '../../dialog/interventionsDialog/dafInterventionDialog';
import { DeleteDialog } from '../../dialog/deleteDialog/deleteDialog';
import { deleteServiceDelivery } from '../../../store/intervention/action';
import { useDispatch } from 'react-redux';

interface TableFactoryProps {
  name: string;
  headItems: any;
  bodyData: any[];
  rowDescription: any;
  canEdit?: boolean;
  canDelete?: boolean;
  page?: number;
  perPage?: number;
  count?: number;
  handleChangePage: (page: number) => void;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  loading?: boolean;
  handleSelectType?: (value: string) => void;
}

/**
 *component returning all forms of tables except planning table
 *
 * @returns {ExoticComponent<() => JSX.Element>}
 */
export const TableFactory = (props: TableFactoryProps) => {
  const {
    name,
    headItems,
    rowDescription,
    bodyData,
    canEdit = true,
    canDelete = true,
    page = CONSTANTS.DEFAULT_PAGE,
    perPage = CONSTANTS.DEFAULT_PER_PAGE,
    handleChangePage,
    setValue,
    handleChangeRowsPerPage,
    count = 0,
    loading,
    handleSelectType,
  } = props;
  console.log('******* ', props.bodyData)
  console.log('+++++++', props.headItems)
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const empty: string[] = [];
  const [data, setData] = React.useState(empty);

  const deleteOneServiceDelivery = () => {
    dispatch(deleteServiceDelivery(data.id));
    setOpenDelete(!openDelete);
  };
  const showUpdateDialog = (values: Array<string>) => {
    console.log('values == ', values)
    if(setValue){
      setValue(false)
    }
    setData(values);
    setOpenEdit(!openEdit);
  };

  const showDeleteDialog = (values: Array<string>) => {
    setData(values);
    setOpenDelete(!openDelete);
  };

  const getcolor = (value: string) => {
    if (typeof value == 'string') {
      const orange = ['inprogress', 'programmed', 'pending', 'todo'];
      const green = ['done', 'activated', 'validated', 'sent'];
      if (orange.includes(value)) {
        return colors.SECONDARY_ORANGE;
      } else if (green.includes(value)) {
        return colors.SECONDARY_GREEN;
      }
      return colors.SECONDARY_RED;
    } else {
      if (value) {
        return colors.SECONDARY_GREEN;
      } else {
        return colors.SECONDARY_RED;
      }
    }
  };

  return (
    <>
      <Paper elevation={0}>
        <ThemeProvider theme={tableTheme}>
          <TableContainer elevation={0} component={Paper}>
            <Table size={'small'}>
              <Head items={headItems} handleSelectType={handleSelectType} />
              <TableBody>
                {loading ? (
                  <TableRow className={'icon_hover'}>
                    <TableCell align="center" colSpan={headItems?.length}>
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : bodyData && bodyData?.length > 0 ? (
                  <>
                    {bodyData.map((row: any, index: number) => {
                      return (
                        <TableRow className={'icon_hover'} key={`table-row-${index}`}>
                          {headItems.map((cell: any, headIndex: number) => (
                            <TableCell
                              key={`table-cell-${headIndex}`}
                              className={rowDescription[headIndex]}
                              style={{
                                color: rowDescription[headIndex] === CONSTANTS.COLORED ? getcolor(row[cell.value]) : 'black',
                              }}
                            >
                              {rowDescription[headIndex] === CONSTANTS.CIRCLE ? (
                                cell.cellType === 'outofStock' && row[cell.value] != null && row[cell.value] == true ? (
                                  <OrangeFilledCircle></OrangeFilledCircle>
                                ) : (
                                  ''
                                )
                              ) : cell.cellType ? (
                                cell === t('todo') ? (
                                  <></>
                                ) : cell.cellType === 'boolean' ? (
                                  row[cell.value] ? (
                                    t('user.activated')
                                  ) : (
                                    t('user.disactivated')
                                  )
                                ) : cell.cellType === 'type' ? (
                                  t(`user.${row[cell.value]}`)
                                ) : cell.cellType === 'status' ? (
                                  t(`intervention.${row[cell.value]}`)
                                ) : cell.cellType === 'date' && row[cell.value] ? (
                                  formatDate(row[cell.value])
                                ) : cell.cellType === 'serviceDeliveryDate' && row[cell.value] ? (
                                  formatDateServiceDelivery(row[cell.value])
                                ) : (
                                  row[cell.value] || '-'
                                )
                              ) : (
                                row[cell.value] || '-'
                              )}
                            </TableCell>
                          ))}

                          <TableCell align="left">
                            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                              <Grid item>
                                <Grid container direction="row" justifyContent="flex-end" alignItems="center" wrap="nowrap">
                                  {canEdit && (
                                    <Grid item>
                                      <IconButton
                                        disableRipple
                                        className="hidden_cell"
                                        onClick={() => {
                                          showUpdateDialog(row);
                                        }}
                                      >
                                        <Visibility />
                                      </IconButton>
                                    </Grid>
                                  )}
                                  {canDelete && (
                                    <Grid item>
                                      <Button
                                        className="hidden_cell"
                                        onClick={() => showDeleteDialog(row)}
                                        startIcon={{
                                          name: 'trash',
                                          prefix: 'domanys',
                                        }}
                                      />
                                    </Grid>
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </>
                ) : (
                  <TableRow className={'icon_hover'}>
                    <TableCell align="center" colSpan={headItems?.length}>
                      {t('common.no_data')}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {bodyData && bodyData?.length > 0 && (
            <TablePagination
              labelRowsPerPage={t('table.row_per_page')}
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={count || 0}
              rowsPerPage={perPage}
              page={page - 1}
              onPageChange={(_ev, value) => handleChangePage(value + 1)}
              onRowsPerPageChange={(e) => handleChangeRowsPerPage(e)}
            />
          )}
        </ThemeProvider>
      </Paper>
      {/* <DeleteDialog name={name} show={open} changeDialog={(value: boolean) => setOpen(value)}  data={data} />  */}
      {name === CONSTANTS.USER ? (
        <UsersDialog show={openEdit} changeDialog={(value: boolean) => setOpenEdit(value)} type={'update'} data={data} />
      ) : name === CONSTANTS.PATRIMOINS ? (
        <>
          <PrestationsDialog show={openEdit} changeDialog={(value: boolean) => setOpenEdit(value)} type={'update'} data={data} />
        </>
      ) : name === CONSTANTS.NOTIFICATION ? (
        <SentNotificationDialog show={openEdit} changeDialog={(value: boolean) => setOpenEdit(value)} type={'update'} />
      ) : name === CONSTANTS.INTEVENTION ? (
        <>
          <CreateInterventionDialog show={openEdit} changeDialog={(value: boolean) => setOpenEdit(value)} type={'update'} data={data} />
          <DeleteDialog
            show={openDelete}
            changeDialog={(value: boolean) => setOpenDelete(value)}
            name={'intervention'}
            onDelete={deleteOneServiceDelivery}
            data={data}
          />
        </>
      ) : name === CONSTANTS.INTERVENTIONDAF ? (
        <DafInterventionDiaolg show={openEdit} changeDialog={(value: boolean) => setOpenEdit(value)} type={'update'} data={data} />
      ) : (
        <></>
      )}
    </>
  );
};
