import React, { useEffect, useState } from 'react';
import { debounce, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { OnTheTable } from '../table/onTheTable/onTheTable';
import { colabHeader, rowDescriptionCol, rowDescriptionUsers, usersHeader } from './table_data_user';
import { TableFactory } from '../table/tableFactory/TableFactory';
import { CONSTANTS, getUsersTitleByRole, isAdmin } from '../../utils';
import { getUsers } from '../../store/user/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../..';
import { User } from '../../store/user/types';

/**
 * Component usersList
 *
 * @component
 *
 * @example
 * return (
 *   <usersList/>
 * )
 */
const UsersList = () => {
  const title = getUsersTitleByRole();
  // get redux UsersList bar state
  const listUsers = useSelector((state: RootState) => state.users.users);
  const loading = useSelector((state: RootState) => state.users.loading);

  // get dispatch hooks
  const dispatch = useDispatch();

  // translation hooks
  const { t } = useTranslation();

  const [page, setPage] = useState<number>(CONSTANTS.DEFAULT_PAGE);
  const [perpage, setPerpage] = useState<number>(CONSTANTS.DEFAULT_PER_PAGE);
  const [select, setSelect] = useState<boolean>(false);
  const [users, setUSers] = useState<User[]>([]);

  useEffect(() => {
    getPaginatedUsers();
  }, []);

 
  const getPaginatedUsers = (pageValue: number = page, perpageValue: number = perpage) => {
    try {
      dispatch(getUsers(pageValue, perpageValue));
    } catch (error) {
      console.log('error');
    }
  };

  const handelChangePage = debounce((value: number) => {
    setPage(value);
    getPaginatedUsers(value, perpage);
  }, 500);

  const handelChangePerPage = debounce((value: number) => {
    setPerpage(value);
    setPage(CONSTANTS.DEFAULT_PAGE);
    getPaginatedUsers(CONSTANTS.DEFAULT_PAGE, value);
  }, 500);

  const handleSelectType = (value: string) => {
    const listeUsers: User[] = [];
    if (listUsers?.items) {
      for (let i = 0; i < listUsers?.items?.length; i++) {
        listeUsers.push(listUsers?.items[i]);
      }
    }
    const usersGTI = listeUsers.filter((user) => user.role_alias === 'GTI');
      setUSers(usersGTI);
      setSelect(true);
  };

  return (
    <>
      <Grid container direction="column" rowSpacing={5}>
        <Grid item>
          <OnTheTable title={t(`menu.${title}`)} button={t(`user.create_${title}`)} name={'user'} />
        </Grid>
        <Grid item>
          <TableFactory
            loading={loading}
            canDelete={false}
            name="user"
            headItems={isAdmin() ? usersHeader : colabHeader}
            bodyData={select ? users : listUsers?.items}
            handleSelectType={handleSelectType}
            rowDescription={isAdmin() ? rowDescriptionUsers : rowDescriptionCol}
            page={page}
            perPage={perpage}
            count={select ? users.length : listUsers?.totalItems}
            handleChangePage={(value) => handelChangePage(value)}
            handleChangeRowsPerPage={(event) => handelChangePerPage(Number(event.target.value))}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default UsersList;
