import React from 'react';

export const Eye = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <g transform="translate(4 7.2)">
          <path
            d="M8 9.6c2.946 0 5.612-1.6 8-4.8C13.612 1.6 10.946 0 8 0 5.054 0 2.388 1.6 0 4.8 2.388 8 5.054 9.6 8 9.6z"
            stroke="#00AADE"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <circle fill="#00AADE" cx="8" cy="4.8" r="1.6" />
        </g>
      </g>
    </svg>
  );
};

export default Eye;
