import * as React from 'react';

export const Delete = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="50" height="50" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g stroke="red" strokeWidth="4" fill="none" fillRule="evenodd">
      <path strokeLinejoin="round" d="M17 43h16l4-28H13zM19 15V7h12v8" />
      <path strokeLinecap="round" d="M9 15h32" />
    </g>
  </svg>
);
