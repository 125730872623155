import { DialogActions as Actions, SxProps } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../..';
import { CONSTANTS } from '../../../utils';
interface DialogActionsProps {
  type?: string;
  onClose?: () => void;
  onAction: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  secondButtonLabel: string;
  loading?: boolean;
  sx?: SxProps;
}

/**
 * component describing the possible bottom buttons of a dialog
 *
 * @returns {JSX.Element}
 */

export const DialogActions = (props: DialogActionsProps) => {
  const { type, onClose, onAction, secondButtonLabel, loading = false } = props;
  const { t } = useTranslation();

  return (
    <>
      <Actions>
        <Button variant="text" size="large" className={'cancel_button'} label={t('common.cancel')} labelClass="label_font" onClick={onClose} />
        <Button
          sx={props.sx}
          className={
            type === CONSTANTS.CREATE
              ? 'blue_button'
              : type === CONSTANTS.EXPORT
              ? 'blue_button export_blue_button'
              : type === CONSTANTS.DELETE && secondButtonLabel === t('intervention.delete')
              ? 'delete_error_button purge_error_button'
              : type === CONSTANTS.DELETE
              ? 'delete_error_button'
              : 'green_button'
          }
          variant="contained"
          size="large"
          type="error"
          labelClass="label_font"
          label={secondButtonLabel}
          onClick={onAction}
          spinning={loading}
          disabled={loading}
        />
      </Actions>
    </>
  );
};
