import { AgencyActionTypes, GET_AGENCIES, GET_DETAILS_AGENCY_BY_PATRIMONY, GETTING_DETAILS_AGENCY_BY_PATRIMONY, GET_AGENCY_ERROR } from './types';
import { SnackBarActionTypes, SHOW_SNACK_BAR } from '../snackBar/types';
import { APIS } from '../../api';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../index';
import { errorMsg } from '../../utils';

// GET ALL Agencies
export const getAllAgencies = () => async (dispatch: ThunkDispatch<RootState, undefined, AgencyActionTypes>) => {
  const agencies = await APIS.agencies.getAgencies();

  dispatch({
    type: GET_AGENCIES,
    payload: agencies,
  });
};

// GET DETAILS_AGENCY_BY_PATRIMONY
export const getDetailsAgencyByPatrimony =
  (id: string) => async (dispatch: ThunkDispatch<RootState, undefined, AgencyActionTypes | SnackBarActionTypes>) => {
    dispatch({
      type: GETTING_DETAILS_AGENCY_BY_PATRIMONY,
    });
    try {
      const agencyDetails = await APIS.agencies.getDetailsAgencyByPatrimony(id);
      dispatch({
        type: GET_DETAILS_AGENCY_BY_PATRIMONY,
        payload: agencyDetails,
      });
    } catch (error) {
      dispatch({
        type: SHOW_SNACK_BAR,
        payload: { message: errorMsg(error), severity: 'error' },
      });
      dispatch({
        type: GET_AGENCY_ERROR,
      });
    }
  };
