import { AgencyActionTypes, Agency, GET_AGENCIES, AgencyState, GETTING_DETAILS_AGENCY_BY_PATRIMONY, GET_DETAILS_AGENCY_BY_PATRIMONY } from './types';

const INITIAL_STATE: AgencyState = {
  items: [] as Agency[],
  loading: true,
  addLoading: false,
};

export const agencyReducer = (state: AgencyState = INITIAL_STATE, action: AgencyActionTypes): AgencyState => {
  switch (action.type) {
    case GET_AGENCIES:
      return {
        ...state,
        items: action.payload,
        loading: false,
      };
    case GETTING_DETAILS_AGENCY_BY_PATRIMONY:
      return {
        ...state,
        loading: true,
      };
    case GET_DETAILS_AGENCY_BY_PATRIMONY:
      return {
        ...state,
        items: action.payload.items,
        loading: false,
      };

    default:
      return state;
  }
};
