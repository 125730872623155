import { createTheme } from '@mui/material/styles';
import { colors } from './shared-theme';

export const dialogTabsTheme = createTheme({
  palette: {
    contrastThreshold: 3,
    tonalOffset: 0.1,
  },
  shadows: [
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
  ],
  typography: {
    allVariants: {
      textTransform: 'none',
      display: 'flex',
      alignItems: 'center',
      fontFamily: 'ArialMT',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '17px',
      letterSpacing: 'normal',
      lineHeight: 'normal',
      color: colors.MAIN_GREY,
    },
  },

  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          paddingTop: 30,
          textTransform: "none"
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: '0px 10px',
          textTransform: "none"
        },
      },
    },
  },
});
