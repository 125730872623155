import { createTheme } from '@mui/material/styles';
import { colors } from './shared-theme';

export const calendarTheme = createTheme({
  palette: {
    contrastThreshold: 3,
    tonalOffset: 0.1,
  },

  components: {
    MuiTextField: {
      styleOverrides: {
        root: {},
      },
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          height: '325px',
          width: '312px',
          '& .MuiCalendarPicker-root': {},
          '& .css-l0iinn': {
            fontFamily: 'ArialMT',
            fontSize: '16px',
            fontWeight: 'normal',
            textTransform: 'capitalize',
            color: colors.BLACK,
          },
          '.Mui-selected': {
            backgroundColor: colors.MAIN_BLUE + ' !important',
          },
        },
      },
    },

    MuiButtonBase: {
      styleOverrides: {
        root: {
          // "&.Mui-selected": {
          //   backgroundColor: colors.MAIN_BLUE+" !important",
          // },
          '&.MuiPickersDay-root': {
            fontSize: '13px',
            fontFamily: 'ArialMT',
            color: colors.DARK_GREY,
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'ArialMT',
          fontSize: '12px',
          fontWeight: 'normal',
          textTransform: 'capitalize',
          color: colors.MAIN_GREY,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-input': {
            // paddingLeft: "30px",
            padding: '0px 0px 0px 20px',
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          // padding: '0px 0px 0px 0px',
          margin: '0px',
        },
      },
    },
    // to align the calendar icon
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          marginLeft: '-8px',
        },
      },
    },
  },
});
