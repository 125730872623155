import React from 'react';

export const Active = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <filter x="-14.6%" y="-9.7%" width="129.2%" height="119.4%" filterUnits="objectBoundingBox" id="a">
          <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur stdDeviation="8" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" in="shadowBlurOuter1" result="shadowMatrixOuter1" />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <g filter="url(#a)" transform="translate(-15 -123)" fill="inherit" stroke="#FFF" strokeWidth="1.25" fillRule="evenodd">
        <circle cx="5" cy="5" r="4.375" transform="translate(15 123)" />
      </g>
    </svg>
  );
};

export default Active;
