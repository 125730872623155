import * as React from 'react';

export const StockExit = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15.39 11.831c0 .213.076.396.226.549.15.153.328.23.534.23h2.625l1.313-.066-.638.572-1.36 1.284a.658.658 0 0 0-.234.516c0 .2.063.367.188.501a.624.624 0 0 0 .478.202.67.67 0 0 0 .497-.225l2.887-2.981c.1-.1.169-.197.207-.291a.776.776 0 0 0 0-.581.809.809 0 0 0-.207-.282L19.02 8.27a.67.67 0 0 0-.497-.225.631.631 0 0 0-.478.197.692.692 0 0 0-.188.497c0 .212.078.387.235.525l1.359 1.284.647.581-1.322-.065H16.15a.73.73 0 0 0-.534.225.74.74 0 0 0-.225.543zm-13.396 5.09c0 .95.237 1.665.712 2.143.475.478 1.178.717 2.11.717h8.568c.932 0 1.636-.239 2.114-.717.479-.478.718-1.192.718-2.142v-4.097H9.738a.919.919 0 0 1-.675-.295.962.962 0 0 1-.291-.699c0-.262.097-.492.29-.689a.919.919 0 0 1 .676-.295h6.478V6.73c0-.943-.238-1.656-.713-2.137-.475-.481-1.181-.722-2.119-.722H4.816c-.932 0-1.635.24-2.11.722-.475.481-.712 1.194-.712 2.137v10.19z"
      fill="#72706F"
      fillRule="nonzero"
    />
  </svg>
);
