import {Box, Grid, SelectChangeEvent, ThemeProvider} from '@mui/material';
import React, {ChangeEvent, ChangeEventHandler, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {dialogTheme} from '../../../theme';
import {colors} from '../../../theme/shared-theme';
import {DialogTitle} from '../../ui/Dialog/DialogLabel/DialogLabel';
import {DialogSelect} from '../../ui/Dialog/DialogSelect/DialogSelect';
import Clock from '../../ui/Icon/icons/Clock';
import ListIcon from '../../ui/Icon/icons/list';
import Location from '../../ui/Icon/icons/location';
import Profile from '../../ui/Icon/icons/Profile';
import {useDispatch, useSelector} from 'react-redux';
import {getPatrimonies, getPatrominyDetail} from '../../../store/patrimonies/actions';
import {RootState} from '../../..';
import {getUsers} from '../../../store/user/actions';
import {MultiSelectItem, SelectMulti} from '../../SelectMulti/SelectMulti';
import {addIntervention, editServiceDelivery} from '../../../store/intervention/action';
import {DialogActions} from '../dialogActions/dialogActions';
import {ServiceDeliveryTypeAvailable} from '../../../store/serviceDeleviryTypes/types';
import {ServiceDelivery} from '../../../store/intervention/type';
import {MyCalendarType} from "../../../store/myCalendar/type";
import {APIS} from "../../../api";
import {ENDPOINTS} from "../../../utils";
import {addJobScheduleProps} from "../../../api/calendarService";
import {showSnackBar} from "../../../store/snackBar/actions";
import {Calendar} from "../../ui/Calendar/calendar";
import {PatrimoniesSelect} from "../../PatrimoniesSelect/PatrimoniesSelect";
import GlobalContext from "../../../context/GlobalContext";

let weekTemplateIdVal = '';

interface createIntervention {
    patrimony: string;
    day: string;
    startHour: string;
    endHour: string;
    state: string;
    comment: string;
    type: string;
    creatorId: string;
    date: string | Date;
    collaborators: MultiSelectItem[];
    collaboratorIds: string[];
    id: string;
}


const initialValues: createIntervention = {
    id: '',
    patrimony: '',
    day: '',
    startHour: '07:00',
    endHour: '10:00',
    collaborators: [],
    state: '',
    comment: '',
    type: '',
    creatorId: '',
    collaboratorIds: [],
    date: new Date().toLocaleDateString().toString(),
};

const initialErrorsValues = {
    patrimony: '',
    day: '',
    type: '',
    endHour: '',
    collaborators: '',
};

interface CreateInterventionsBodyProps {
    servicesAvailables?: ServiceDeliveryTypeAvailable;
    getInterventionValues?: (values: createIntervention) => void;
    onClose?: () => void;
    show?: boolean;
    loading?: boolean;
    data?: ServiceDelivery;
    type: string;
    source?: string;

    onCloseModal: () => void;
}

/**
 * component describing pending/validated interventions dialog
 *
 *
 * @returns {JSX.Element}
 */

export const CreateInterventionsBody = (props: CreateInterventionsBodyProps) => {
    const {t} = useTranslation();
    const {onClose, onCloseModal, show, data, type} = props;
    console.log('in body, data is ', data);
    const dispatch = useDispatch();
    const [values, setValues] = useState(initialValues);
    const [patCollab, setPatCollab] = useState<{ label: any, value: string, id: string }[]>([])
    const [patServiceTypes, setPatServiceTypes] = useState<{
        label: string,
        value: string,
        secondaryText: string,
        id: string
    }[]>([]);
    const serviceTypes = useSelector((state: RootState) => state.patrimonies.patrimony.sdTypes);
    console.log('serviceTypes = ', serviceTypes)
    const patrimonyInfos = useSelector((state: RootState) => state.patrimonies.patrimony);
    const patrimoniesByAgency = useSelector((state: RootState) => state.patrimonies.patrimonies);
    const listUsers = useSelector((state: RootState) => state.users.users?.items);
    console.log('list users == ', listUsers)
    const [collabsList, setCollabsList] = useState<MultiSelectItem[]>([]);
    const [errors, setErrors] = useState({...initialErrorsValues});
    const [calendarSelectedFromToday, setCalendarSelectedFromToday] = React.useState<boolean>(true);
    const validateWeekTemplate = () => {
        const myUrl = ENDPOINTS.WeekTemplates + '/' + weekTemplateIdVal + ENDPOINTS.JobSchedule;
        const requestBody: addJobScheduleProps = {
            weekday: values.day,
            initialTimeRange: [values.startHour, values.endHour],
            isIntervention: true,
            status: 'todo',
            comment: values.comment,
            creatorId: localStorage.getItem('userId'),
            dayScheduleId: 0, //todo: ask po about
            refPat: values.patrimony,
            serviceDeliveryTypeIds: [values.type],
            collabIds: values.collaboratorIds,
        }
        if (values.day != ''
            && values.collaboratorIds.length > 0
            && values.patrimony != '') {
            APIS.calendarService.addJobSchedule(requestBody, myUrl).then((response) => {
                dispatch(showSnackBar(t('intervention.jobCreated'), 'success'));
                props.onCloseModal();
                onCloseModal()
            });
        }
    }
    const getWeekDaysList = () => {
        const aux: MyCalendarType = useSelector((state: RootState) => state.myCalendar.calendar);
        console.log('aux === ', aux)
        if (aux && aux.calendar) {
            const daysList = aux.calendar.map((item) => {
                return {label: t('intervention.' + item.weekDay), value: item.weekDay};
            })
            weekTemplateIdVal = aux.weekTemplateId;
            return daysList;
        }
    }

    const validateServiceDelivery = () => {
        errors.patrimony = values.patrimony ? '' : t('error.empty_field');
        errors.type = values.type ? '' : t('error.empty_field');
        errors.endHour = values.endHour < values.startHour ? t('error.endDate_field') : '';
        errors.collaborators = values.collaborators[0] ? '' : t('error.empty_field');
        setErrors({
            ...errors,
        });
        return Object.values(errors).every((x) => x === '');
    };

    const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        if (props.source && props.source == 'week-type') {
            validateWeekTemplate();
        } else {
            if (validateServiceDelivery()) {
                try {
                    await dispatch(
                        addIntervention(
                            values.date,
                            [values.startHour, values.endHour],
                            true,
                            values.patrimony,
                            values.comment,
                            [values.type],
                            values.collaboratorIds,
                        ),
                    );
                    onCloseModal();
                } catch (err) {
                    console.log('eero accured');
                }
            }
        }
    };
    const handleSubmitModifier = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        if (validateServiceDelivery()) {
            try {
                await dispatch(
                    editServiceDelivery(
                        values.id,
                        values.date,
                        [values.startHour, values.endHour],
                        [values.startHour, values.endHour],
                        true,
                        values.patrimony,
                        values.comment,
                    ),
                );
                onClose();
            } catch (err) {
                console.log('eero accured');
            }
        }
    };
    const setPatrimony = (value: any) => {
        console.log("users", listUsers)
        setValues({...values, patrimony: value});
        const pat = patrimoniesByAgency?.find((item) => item.refPat === value);
        const filtredUsers = listUsers;
        setCollabsList(
            filtredUsers.map((item) => ({
                label: (
                    <Grid container wrap="nowrap" alignItems="center">
                        <Grid item className="role-alias">
                            {item.role_alias?.substring(0, 2)}
                        </Grid>
                        <Grid item className="role-alias-text">{`${item.firstname} ${item.lastname}`}</Grid>
                    </Grid>
                ),
                value: item.id,
                id: item.id,
            })),
        );
        if (pat) {
            dispatch(getPatrominyDetail(pat?.id));
        } else if (value) {
            dispatch(getPatrominyDetail(value));

        }
    }
    const handleChange =
        (prop: string) => (event: SelectChangeEvent<HTMLSelectElement | string | ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>>) => {
            setValues({...values, [prop]: event.target.value});
        };

    const handleChangeCollabs = (event: MultiSelectItem[]) => {
        const array: string[] = [];
        event.map((el) => {
            array.push(el.id);
        });
        setValues({...values, collaborators: event || [], collaboratorIds: array});
    };
    const gContext = useContext(GlobalContext);
    const getServices = () => {
        let list: string[] = [];
        if (serviceTypes) {
            const infos = Object.entries(serviceTypes);
            console.log('infos === ', infos)
            if (infos && infos.length > 0) {
                list = infos
                    .filter((el) => el[1].status)
                    .map((item) => ({
                        label: item[1].ref_obj.type,
                        value: item[1].ref_obj.id,
                        secondaryText: item[1].ref_obj.duration,
                        id: item[1].ref_obj.id,
                    }));
            }
        }
        console.log('list ===== ', list)
        return list;
    };

    const getAllPatrimonies = () => {
        dispatch(getPatrimonies());
    };
    const resetData = () => {
        getAllPatrimonies();
        dispatch(getUsers());
    }
    useEffect(() => {
        if (data) {
            setValues({
                ...data,
                patrimony: data.refPat,
                type: data.type,
                startHour: data.initialTimeRange[0],
                endHour: data.initialTimeRange[1],
                collaboratorIds: data.collaborators[0],
                id: data.id ? data.id : ''
            });
            console.log('collab == ', data.collaborators[0])

        } else {
            setValues({...initialValues});
        }
        setErrors({...initialErrorsValues});
    }, [show]);

    useEffect(() => {
        getAllPatrimonies();
        dispatch(getUsers());
        if (gContext?.selectedPatrominy != null) {
            setPatrimony(gContext?.selectedPatrominy.refPat);
        }
    }, []);
    useEffect(() => {
        if (data && data.refPat)
            APIS.patrimonies.getPatrimonyServiceTypes(data.refPat).then((response) => {
                console.log('response == ', response)
                const myTypes: {
                    label: string,
                    value: string,
                    secondaryText: string,
                    id: string
                }[] = []
                response.forEach(item => {
                    myTypes.push({
                        label: item.type,
                        value: item.type,
                        secondaryText: item.duration,
                        id: item.id,
                    })
                })
                setPatServiceTypes(myTypes)
                setPatrimony(data.refPat)
            });
    }, [data?.refPat])

    useEffect(() => {
        if(data && data.collaborators.length > 0) {
            APIS.users.getUsersList().then((response) => {
                if (response) {
                    setCollabsList(
                        response.map((item) => ({
                            label: (
                                <Grid container wrap="nowrap" alignItems="center">
                                    <Grid item className="role-alias">
                                        {item.role_alias?.substring(0, 2)}
                                    </Grid>
                                    <Grid item className="role-alias-text">{`${item.firstname} ${item.lastname}`}</Grid>
                                </Grid>
                            ),
                            value: item.id,
                            id: item.id,
                        })),
                    );
                    const myCollabData = response.find(item =>
                        item.firstname === data.collaborators[0].firstname
                        && item.lastname === data.collaborators[0].lastname)
                    if (myCollabData) {
                        const auxList = []
                        auxList.push({
                            label: (
                                <Grid container wrap="nowrap" alignItems="center">
                                    <Grid item
                                          className="role-alias-text">{`${myCollabData.firstname} ${myCollabData.lastname}`}
                                    </Grid>
                                </Grid>
                            ),
                            value: myCollabData.id,
                            id: myCollabData.id,
                        })
                        setPatCollab(auxList)
                    }
                }
            })
        }
    }, [data?.collaborators])

    useEffect(() => {
        if (values.patrimony) {
            // getPatrominy();
            generateHours();
        }
    }, [values.patrimony]);

    const generateHours = () => {
        const list = [];
        for (let i = 7; i <= 9; i++) {
            list.push({label: `0${i}:00`, value: `0${i}:00`});
            list.push({label: `0${i}:05`, value: `0${i}:05`});
            list.push({label: `0${i}:10`, value: `0${i}:10`});
            list.push({label: `0${i}:15`, value: `0${i}:15`});
            list.push({label: `0${i}:20`, value: `0${i}:20`});
            list.push({label: `0${i}:25`, value: `0${i}:25`});
            list.push({label: `0${i}:30`, value: `0${i}:30`});
            list.push({label: `0${i}:35`, value: `0${i}:35`});
            list.push({label: `0${i}:40`, value: `0${i}:40`});
            list.push({label: `0${i}:45`, value: `0${i}:45`});
            list.push({label: `0${i}:50`, value: `0${i}:50`});
            list.push({label: `0${i}:55`, value: `0${i}:55`});
        }
        for (let i = 10; i < 19; i++) {
            list.push({label: `${i}:00`, value: `${i}:00`});
            list.push({label: `${i}:05`, value: `${i}:05`});
            list.push({label: `${i}:10`, value: `${i}:10`});
            list.push({label: `${i}:15`, value: `${i}:15`});
            list.push({label: `${i}:20`, value: `${i}:20`});
            list.push({label: `${i}:25`, value: `${i}:25`});
            list.push({label: `${i}:30`, value: `${i}:30`});
            list.push({label: `${i}:35`, value: `${i}:35`});
            list.push({label: `${i}:40`, value: `${i}:40`});
            list.push({label: `${i}:45`, value: `${i}:45`});
            list.push({label: `${i}:50`, value: `${i}:50`});
            list.push({label: `${i}:55`, value: `${i}:55`});
        }
        return list;
    };

    return (
        <>
            <ThemeProvider theme={dialogTheme}>
                <Grid container direction="column" alignItems="flex-start" rowSpacing={1.5}>
                    <Grid item>
                        <PatrimoniesSelect
                            popup={true}
                            setPatrimony={setPatrimony}
                            selectedPat={data?.refPat}
                        />
                    </Grid>
                    {(!values.id || (data && data.type && patServiceTypes.length > 0)) ? <>
                            <Grid item>
                                <DialogSelect
                                    error={errors.type}
                                    label={t('intervention.select_prestation_type')}
                                    value={(data && data.type) ? data.type : values.type}
                                    onChange={handleChange('type')}
                                    icon={ListIcon}
                                    items={(data && data.type) ? patServiceTypes : getServices()}
                                />
                            </Grid></>
                        : <></>
                    }
                    <Grid item>
                        <DialogTitle
                            // fake data
                            label={patrimonyInfos.streetName ? patrimonyInfos.streetName : 'Nom de rue'}
                            icon={Location}
                        />
                    </Grid>
                    <Grid item>
                        {gContext?.weekTypeSelected ?
                            <DialogSelect
                                error={errors.day}
                                label={t('intervention.selectOneDay')}
                                onChange={handleChange('day')}
                                value={values.day}
                                icon={ListIcon}
                                items={getWeekDaysList()}
                            /> :
                            <Calendar
                                value={values.date}
                                onChange={(newValue: ChangeEvent<Date>) => {
                                    setValues({...values, ['date']: new Date(newValue.toString())});
                                }}
                                disabled={false}
                                calendarSelectedFromToday={calendarSelectedFromToday}
                            />
                        }
                    </Grid>
                    <Grid item>
                        <DialogSelect
                            iconColor={colors.SECONDARY_GREEN}
                            value={values.startHour}
                            onChange={handleChange('startHour')}
                            icon={Clock}
                            items={generateHours()}
                        />
                    </Grid>

                    <Grid item>
                        <DialogSelect
                            error={errors.endHour}
                            iconColor={colors.SECONDARY_RED}
                            value={values.endHour}
                            onChange={handleChange('endHour')}
                            icon={Clock}
                            items={generateHours()}
                        />
                    </Grid>
                    {(!values.id || (data && data.collaborators.length > 0 && patCollab.length > 0)) ?
                        <Grid item>
                            <SelectMulti
                                error={errors.collaborators}
                                disabled={!values.patrimony}
                                label={t('intervention.add_collaborator')}
                                onChange={handleChangeCollabs}
                                icon={Profile}
                                items={collabsList}
                                values={(data && data.collaborators.length > 0) ? patCollab : values.collaborators}
                            />
                        </Grid>
                        : <></>
                    }
                </Grid>
                <Box sx={{mt: 17}}/>

                <DialogActions
                    secondButtonLabel={type === 'create' ? 'Valider' : 'Enregistrer'}
                    type={type}
                    onClose={onCloseModal}
                    onAction={type === 'create' ? handleSubmit : handleSubmitModifier}
                ></DialogActions>
            </ThemeProvider>
        </>
    );
};
