import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../index';
import { APIS } from '../../api';
import { AuthActionTypes, LOGIN } from './types';

export const login = (loginValue: string, password: string) => async (dispatch: ThunkDispatch<RootState, undefined, AuthActionTypes>) => {
  const response = await APIS.auth.login({ login: loginValue, password });

  dispatch({
    type: LOGIN,
    payload: response.data,
  });
};

export const logout = () => {
  localStorage.clear();
};
