import { createTheme } from '@mui/material/styles';
import { colors } from './shared-theme';

export const scheduleCalendarTheme = createTheme({
  palette: {},

  components: {
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: '0 !important',
          borderBottom: 'none !important',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: 2,
          '& .MuiLinearProgress': {
            backgroundColor: colors.MAIN_BLUE,
          },
        },
      },
    },
  },
});
