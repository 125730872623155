import React from 'react';
interface FunctionAbreviationProps {
  title?: string;
}

export const FunctionAbreviation = (props: FunctionAbreviationProps) => {
  return (
    <svg width="34" height="34" viewBox="0 0 34 34" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <circle fill="#F9F9F9" cx="17" cy="17" r="14" />
        <text fontFamily="ArialMT, Arial" fontSize="11" fill="#72706F">
          <tspan x="9.663" y="21">
            {props.title}
          </tspan>
        </text>
      </g>
    </svg>
  );
};

export default FunctionAbreviation;
