import { createTheme } from '@mui/material/styles';
import { colors } from './shared-theme';

export const alertTheme = createTheme({
  palette: {
    primary: {
      main: colors.WHITE,
      contrastText: colors.WHITE,
    },
    contrastThreshold: 3,
    tonalOffset: 0.1,
  },
  shape: {
    borderRadius: 5.1,
  },
  typography: {
    allVariants: {
      textTransform: 'none',
      display: 'flex',
      alignItems: 'center',
      fontFamily: 'Arial-BoldMT',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: 16,
      letterSpacing: 'normal',
    },
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          height: 48,
          padding: 0,
          '.MuiAlert-message': {
            padding: '0 21px',
          },
          '&.MuiAlert-filledSuccess': {
            backgroundColor: colors.SECONDARY_GREEN,
          },
          '&.MuiAlert-filledError': {
            backgroundColor: colors.SECONDARY_RED,
          },
          '&.MuiAlert-filledWarning': {
            backgroundColor: colors.SECONDARY_ORANGE,
          },
          '&.MuiAlert-filledInfo': {
            backgroundColor: colors.MAIN_BLUE,
          },
        },
      },
    },
  },
});
