import { Box, Dialog, DialogContent, Grid, ThemeProvider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { dialogTheme } from '../../../theme';
import { CONSTANTS } from '../../../utils';
import { DialogTitle } from '../../ui/Dialog/DialogLabel/DialogLabel';
import FunctionAbreviation from '../../ui/Icon/icons/functionAbreviation';
import Clock from '../../ui/Icon/icons/Clock';
import Profile from '../../ui/Icon/icons/Profile';
import Table from '../../ui/Icon/icons/table';
import { DialogHeader } from '../dialogTitle/dialogTitle';

interface SentNotificationDialogProps {
  show?: boolean;
  type?: string;
  changeDialog: (value: boolean) => void;
}
const useStyles = makeStyles({
  dialog_height: {
    '& .MuiDialog-paper': {
      height: '434px',
    },
  },
});
/**
 * component describing an already sent notification
 *
 * @returns {JSX.Element}
 */

export const SentNotificationDialog = (props: SentNotificationDialogProps) => {
  const { t } = useTranslation();
  const { show = false, changeDialog } = props;
  const [open, setOpen] = React.useState(show);
  const classes = useStyles();

  useEffect(() => {
    setOpen(show);
  }, [show]);

  const handleClose = () => {
    setOpen(false);
    changeDialog(false);
  };
  return (
    <>
      <ThemeProvider theme={dialogTheme}>
        <Dialog open={open} className={classes.dialog_height}>
          <DialogHeader type={CONSTANTS.SUCCESS} titleText={t('notification.sent')} onClose={handleClose} />
          <DialogContent sx={{ mt: 5 }}>
            <Grid container direction="column" alignItems="flex-start" rowSpacing={1.5}>
              <Grid item>
                <DialogTitle
                  // fake data
                  label={'Information Congés'}
                  icon={Profile}
                  withoutIcon
                  bigSize
                />
              </Grid>
              <Grid item>
                <DialogTitle
                  // fake data
                  label={"voici la description concernant la notification que j'envoie à mes collaborateurs"}
                  icon={Profile}
                  withoutIcon
                  textArea
                />
              </Grid>
              <Box sx={{ m: 0.5 }} />
              <Grid item>
                <DialogTitle
                  // fake data
                  label={'Vendredi, 12 Décembre'}
                  icon={Table}
                  textArea
                />
              </Grid>
              <Grid item>
                <DialogTitle label={'10:00'} icon={Clock} />
              </Grid>
              <Grid item>
                <DialogTitle label={t('notification.targeted_collaborators')} icon={Profile} />
              </Grid>
              <Grid item>
                <Box sx={{ paddingLeft: '45px' }}>
                  {/* fake data   */}
                  <DialogTitle label={'Celia Lobrega'} icon={FunctionAbreviation} abreviationText="AR" />
                </Box>
              </Grid>
              <Box sx={{ m: -0.75 }} />
              <Grid item>
                <Box sx={{ paddingLeft: '45px' }}>
                  {/* fake data   */}
                  <DialogTitle label={'Anne Lejeune'} icon={FunctionAbreviation} abreviationText="AR" />
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </ThemeProvider>
    </>
  );
};
