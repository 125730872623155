import { createTheme } from '@mui/material/styles';

export const calendarTableTheme = createTheme({
  palette: {
    contrastThreshold: 3,
    tonalOffset: 0.1,
  },
  shadows: [
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
  ],
  typography: {
    allVariants: {
      textTransform: 'none',
      display: 'flex',
      alignItems: 'center',
      fontFamily: 'ArialMT',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '17px',
      letterSpacing: 'normal',
      lineHeight: 'normal',
    },
  },

  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          '&.MuiTableContainer-root': {
            overflow: 'visible',
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {},
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          height: '50px !important',
          minWidth: '151px',
          // borderWidth: "1px",
          padding: '0px',
          borderLeft: '1px solid rgba(224, 224, 224, 1)',
          borderRight: '1px solid rgba(224, 224, 224, 1)',
          borderBottom: '1px solid rgba(224, 224, 224, 1)',
          borderTop: '1px solid rgba(224, 224, 224, 1)',
          //  maxWidth: "200px",
        },
      },
    },
  },
});
