import {combineReducers} from 'redux';
import {authReducer} from './auth/reducer';
import {ServiceDeliveryReducer} from './intervention/reducer';
import {snackBarReducer} from './snackBar/reducer';
import {ServiceDeliverTypeReducer} from './serviceDeleviryTypes/reducer';
import {WeekendsReducer} from './weekends/reducer';
import {userReducer} from './user/reducer';
import {agencyReducer} from './agency/reducer';
import {PatrimoniesReducer} from './patrimonies/reducer';
import {MyCalendarReducer} from "./myCalendar/reducer";

const rootReducer = combineReducers({
    auth: authReducer,
    snackBar: snackBarReducer,
    users: userReducer,
    agencies: agencyReducer,
    weekends: WeekendsReducer,
    interventions: ServiceDeliveryReducer,
    serviceDeliveryType: ServiceDeliverTypeReducer,
    patrimonies: PatrimoniesReducer,
    myCalendar: MyCalendarReducer,
});

export default rootReducer;
