import jwt from 'jwt-decode';
import { history, CONSTANTS } from '../utils';
import { Action } from 'redux';

interface MyToken {
  name: string;
  exp: number;
  // whatever else is in the JWT.
}

/**
 * ws gaurd that logs out the user in case of token expiration
 *
 * @param {*} store
 */
export const checkTokenExpirationMiddleware = () => (next: unknown) => (action: Action) => {
  const token: string = localStorage.getItem(CONSTANTS.TOKEN) || '';
  let decodedToken = null;
  try {
    decodedToken = jwt<MyToken>(token);
  } catch (e) {
    localStorage.clear();
    history.push('/login');
  }

  if (token && decodedToken && decodedToken.exp < Date.now() / 1000) {
    localStorage.clear();
    history.push('/login');
  }

  next(action);
};
