import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { colors } from '../../../../theme/shared-theme';

interface DialogTitleProps {
  label: string;
  icon?: React.ReactNode;
  withoutIcon?: boolean;
  bigSize?: boolean;
  size?: boolean;
  color?: boolean;
  colorHours?: boolean;
  textArea?: boolean;
  secondaryText?: string | string[];
  // in case of functionAbreviation icon use
  abreviationText?: string;
  iconColor?: string
}

export const DialogTitle = (props: DialogTitleProps) => {
  const { label, withoutIcon, bigSize, size, colorHours, color, textArea, secondaryText, abreviationText, iconColor } = props;
  const LabelIcon = props.icon;
  return (
    <>
      <Grid container direction="row" alignItems="center" wrap="nowrap">
        <Grid item>{withoutIcon ? <Box sx={{ width: '24px' }}></Box> : <LabelIcon title={abreviationText} className={'icon'} color={props.iconColor} />}</Grid>
        <Box sx={{ width: 28 }}></Box>
        <Grid item>
          <>
            <Typography
              style={{
                fontSize: bigSize ? '17px' : size ? '24px' : '14px',
                width: textArea ? '220px' : '',
                color: color ? colors.BLACK : colorHours ? colors.MAIN_BLUE : colors.MAIN_GREY,
              }}
            >
              {label}
            </Typography>
            <Typography
              style={{
                fontSize: '11px',
                color: colors.MEDIUM_LIGHT_GREY,
              }}
            >
              {secondaryText}
            </Typography>
          </>
        </Grid>
      </Grid>
    </>
  );
};
