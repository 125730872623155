import React from 'react';
interface ClockProps {
  color?: string;
}

export const Clock = (props: React.SVGProps<SVGSVGElement> | ClockProps) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g transform="translate(2.4 2.4)" stroke={props.color ? props.color : '#72706F'} strokeWidth="1.5" fill="none" fillRule="evenodd">
        <circle cx="9.6" cy="9.6" r="8.85" />
        <path strokeLinecap="round" strokeLinejoin="round" d="M9.6 4.8v4.8L12 12" />
      </g>
    </svg>
  );
};

export default Clock;
