import React from 'react';

export const OrangeFilledCircle = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="8" cy="8" r="7" fill="#FF7000" stroke="#FFF" strokeWidth="2" fillRule="evenodd" />
    </svg>
  );
};

export default OrangeFilledCircle;
