import { User } from './model';

export const LOGOUT = 'auth/logout';
export const LOGIN = 'auth/login';
export const UPDATE_AUTH = 'auth/update';

// logout action
export interface LogoutAction {
  type: typeof LOGOUT;
}

// login action
export interface LoginAction {
  type: typeof LOGIN;
  payload: User;
}

// login action
export interface UpdateAuthAction {
  type: typeof UPDATE_AUTH;
  payload: User;
}

// reducer interface
export interface AuthState {
  user: User;
}

export type AuthActionTypes = LoginAction | LogoutAction | UpdateAuthAction;
