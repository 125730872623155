export const HIDE_SNACK_BAR = 'snack-bar/hide';
export const SHOW_SNACK_BAR = 'snack-bar/show';

// reducer interface
export interface SnackBarState {
  open: boolean;
  severity: 'error' | 'warning' | 'info' | 'success';
  message: string;
}

// fetch SnackBar action
interface HideSnackBarAction {
  type: typeof HIDE_SNACK_BAR;
}

interface ShowSnacBarAction {
  type: typeof SHOW_SNACK_BAR;
  payload: {
    severity: 'error' | 'warning' | 'info' | 'success';
    message: string;
  };
}

export type SnackBarActionTypes = HideSnackBarAction | ShowSnacBarAction;
