import React from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { OnTheTable } from '../table/onTheTable/onTheTable';
import { TableFactory } from '../table/tableFactory/TableFactory';
import { fake_data_notifications } from './fake_data';

/**
 * component returning the notifications list
 *
 * @returns {ExoticComponent<() => JSX.Element>}
 */

export const NotificationsList = () => {
  const { t } = useTranslation();
  const row_description = ['black', 'grey', 'grey', 'colored'];
  const headItems = [
    {
      label: t('notification.title'),
      type: 'normal',
      height: 50,
      width: 300,
    },
    {
      label: t('notification.sending_date'),
      type: 'normal',
      height: 50,
      width: 250,
    },
    {
      label: t('notification.created_by'),
      type: 'normal',
      height: 50,
      width: 250,
    },
    {
      label: t('notification.status'),
      type: 'select',
      height: 50,
      width: 200,
      choices: [
        { label: t('notification.sent'), value: '10' },
        { label: t('notification.programmed'), value: '20' },
      ],
    },
  ];

  return (
    <>
      <Grid container rowSpacing={5} direction="column">
        <OnTheTable title={t('notification.notifications')} button={t('notification.create_notification')} name={'notification'} />
        <Grid item>
          <TableFactory
            name="notification"
            headItems={headItems}
            bodyData={fake_data_notifications}
            rowDescription={row_description}
            handleChangePage={function (): void {
              throw new Error('Function not implemented.');
            }}
            handleChangeRowsPerPage={function (): void {
              throw new Error('Function not implemented.');
            }}
          ></TableFactory>
        </Grid>
      </Grid>
    </>
  );
};
