import { Box, Checkbox, Grid, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import styles from '../../../assets/sass/checkBoxListItem.module.scss';
import { CollaboratorDetailsMenu } from '../../menu/collaboratorDetailsMenu';
import FunctionAbreviation from '../../ui/Icon/icons/functionAbreviation';
import MenuDots from '../../ui/Icon/icons/Menu';
import { User } from '../../../store/user/types';

interface CheckBoxListItemProps {
  user: User;
  userFromSd: Object;
  handlechecked: (value: boolean) => void;
  value?: boolean;
  updateWeek: () => void;
}

export const CheckBoxListItem = (props: CheckBoxListItemProps) => {
  const { user, userFromSd, value = false, handlechecked } = props;
  const [openMenu, SetOpenDetailsMenu] = React.useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleMenu = (event: any) => {
    SetOpenDetailsMenu(event.currentTarget);
  };

  return (
    <>
      <Grid
        container
        sx={{
          minWidth: '250px',
          maxWidth: '250px',
        }}
        className={styles.icon_hover}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Grid container direction="row" alignItems="center" columnSpacing={1}>
            <Grid item>
              <Checkbox
                value={value}
                onChange={(e) => handlechecked(e.target.checked)}
                style={{
                  color: user.distinctColor,
                }}
                disableRipple
              />
            </Grid>
            <Grid item>
              <Typography>{user.firstname + ' ' + user.lastname}</Typography>
            </Grid>
          </Grid>
        </Grid>

        {user.role_alias && (
          <Grid item>
            <Stack direction="row">
              <Box className={styles.hidden_cell} onClick={handleMenu} sx={{ height: '24px', marginBottom: '10px' }}>
                <MenuDots />
              </Box>
              <Box sx={{ height: '24px', marginBottom: '10px' }}>
                <FunctionAbreviation title={user.role_alias} />
              </Box>
            </Stack>
          </Grid>
        )}
      </Grid>
      <CollaboratorDetailsMenu
        changeDialog={(changeValue: boolean) => SetOpenDetailsMenu(changeValue)}
        collaboratorDetails={user}
        userFromSd={userFromSd}
        open={openMenu}
        updateWeek={props.updateWeek}
      />
    </>
  );
};
