import { auth } from './auth';
import { serviceDelivery } from './serviceDelivery';
import { users } from './users';
import { servicesDeliveryType } from './servicesDeliveryType';
import { agencies } from './agencies';
import { patrimonies } from './patrimonies';
import {calendarService} from "./calendarService";
export const APIS = {
  auth,
  users,
  serviceDelivery,
  agencies,
  servicesDeliveryType,
  patrimonies,
  calendarService,
};
