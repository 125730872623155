import React from 'react';

export const ListIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g stroke="#72706F" strokeWidth="1.5" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <path d="M4.8 8h.8M4.8 12h.8M4.8 16h.8M8.8 8h10.4M8.8 12h10.4M8.8 16h10.4" />
      </g>
    </svg>
  );
};

export default ListIcon;
