import { Dialog, DialogContent, Grid,Box,ThemeProvider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { dialogTheme } from '../../../theme';
import { DialogHeader } from '../dialogTitle/dialogTitle';
import { CreateInterventionsBody } from './createInterventionBody';
import { getServiceDeliveryTypesAvailable } from '../../../store/serviceDeleviryTypes/actions';
import { getPatrimonies } from '../../../store/patrimonies/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../..';
import { ServiceDelivery } from '../../../store/intervention/type';
import { DialogTitle } from '../../ui/Dialog/DialogLabel/DialogLabel';
import FunctionAbreviation from '../../ui/Icon/icons/functionAbreviation';
import { Calendar } from '../../ui/Calendar/calendar';
import ListIcon from '../../ui/Icon/icons/list';
import Location from '../../ui/Icon/icons/location';
import Patrimony from '../../ui/Icon/icons/Patrimony';
import { StockExit } from '../../ui/Icon/icons/stockExit';
import TextArea from '../../ui/Icon/icons/TextArea';
import { View } from '../../ui/Icon/icons/View';
interface CreateInterventionDialogProps {
  show?: boolean;
  changeDialog: (value: boolean) => void;
  type: string;
  data?: ServiceDelivery;
}
const useStyles = makeStyles({
  dialog_height: {
    '& .MuiDialog-paper': {
      minHeight: '680px',
    },
  },
});
/**
 * component describing pending/validated interventions dialog
 *
 *
 * @returns {JSX.Element}
 */

export const CreateInterventionDialog = (props: CreateInterventionDialogProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { show = false, changeDialog, type, data } = props;
  console.log('data == ', data)
  const [open, setOpen] = React.useState(show);
  const servicesAvailables = useSelector((state: RootState) => state.serviceDeliveryType.items);

  const classes = useStyles();

  useEffect(() => {
    setOpen(show);
    if (show) {
      getServicesAvailables();
      getAllPatrimonies();
    }
  }, [show]);

  const getServicesAvailables = () => {
    dispatch(getServiceDeliveryTypesAvailable());
  };

  const getAllPatrimonies = () => {
    dispatch(getPatrimonies());
  };

  const handleClose = () => {
    setOpen(false);
    changeDialog(false);
  };

  return (
    <>
      <ThemeProvider theme={dialogTheme}>
        <Dialog open={open} className={classes.dialog_height}>
          <DialogHeader type={type} titleText={t('intervention.create_intervention')} onClose={handleClose} data={data} />
     
    
          <DialogContent sx={{ mt: 5 }}>
            <CreateInterventionsBody
              servicesAvailables={servicesAvailables}
              show={show}
              onClose={handleClose}
              loading={false}
              data={data}
              type={type}
            />
          </DialogContent>
        </Dialog>
      </ThemeProvider>
    </>
  );
};
