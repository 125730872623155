import React from 'react';

export const OutlineIdentifier = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <g transform="translate(6.4 6.4)" stroke="#72706F" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
          <path d="M11.2 12c0-3.733-1.867-5.6-5.6-5.6C1.867 6.4 0 8.267 0 12h11.2z" />
          <circle cx="5.6" cy="3.2" r="3.2" />
        </g>
      </g>
    </svg>
  );
};

export default OutlineIdentifier;
