export const prestation = {
  prestation: 'Prestation',
  prestation_type: 'TYPE DE PRESTATION',
  manage_prestation: 'Gérer mes prestations',
  create_prestation: 'Créer une prestation',
  ref_patrimony: 'Ref. Patrimoine',
  delete_message: 'Voulez-vous supprimer cette prestation ?',
  delete: 'Supprimer',
  type_prestations: 'Types de prestation',
  add_success: 'Préstation ajouté avec succès',
  update_success: 'Préstation modifié avec succès',
  delete_success: 'Préstation Supprimé avec succès',
};
