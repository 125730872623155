export const notification = {
  notifications: 'Notifications',
  notification: 'Notification',
  create_notification: 'Créer une notification',
  sending_date: "DATE D'ENVOI",
  created_by: 'CRÉÉE PAR',
  status: 'STATUT',
  programmed: 'Programmée',
  sent: 'Envoyée',
  title: 'Titre',
  add_title: 'Ajouter un titre',
  now: 'Maintenant',
  program: 'Programmer',
  add_description: 'Ajouter une description',
  previous_month: 'Mois précédent',
  next_month: 'Mois suivant',
  delete_message: 'Voulez-vous supprimer cette notification ?',
  delete: 'Supprimer',
  validate: 'Valider',
  add_collaborators: 'Ajouter des collaborateurs',
  targeted_collaborators: 'collaborateurs ciblés',
};
