export const colors = {
  WHITE: '#ffffff',
  BLACK: '#000',
  SECONDARY_RED: '#ff0000',
  SECONDARY_GREEN: '#00a13a',
  MAIN_GREY: '#72706f',
  LIGHT_GREY: '#ececec',
  LIGHTER_GREY: '#f9f9f9',
  DARK_GREY: '#3e3f40',
  MEDIUM_GREY: '#d3d3d3',
  MEDIUM_LIGHT_GREY: '#888a8b',
  SECONDARY_GREY: '#abb6cc',
  MAIN_BLUE: '#00aade',
  SECONDARY_ORANGE: '#ff7000',
  TERTIARY_BLUE: '#ff7000',
  TERTIARY_GREEN: '#7bb342',
  TERTIARY_PINK: '#d81a60',
  TERTIARY_YELLOW: '#e5ac0d',
  TERTIARY_PURPLE: '#7986cb',
  TERTIARY_DARK_GREEN: '#0c8043',
  TERTIARY_ORANGE: '#f16f40',
  TERTIARY_RED: '#f64747',
  HOVER_COLOR: '#0000000a',
  EXTRA_LIGHT_GREY: '#80808063',
};
