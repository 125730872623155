import { createTheme } from '@mui/material/styles';
import { colors } from './shared-theme';

export const onTheTableTheme = createTheme({
  typography: {
    allVariants: {
      fontFamily: 'Arial',
      fontSize: '34px',
      fontWeight: 'normal',
      color: '#1d1a39',
    },
  },
  components: {
    MuiGrid: {
      styleOverrides: {
        root: {
          '.button': {
            fontFamily: 'Arial !important',
            fontSize: '16px !important',
            fontWeight: ' !important',
            minWidth: 189,
          },
          '.error_button': {
            backgroundColor: colors.SECONDARY_RED,
            color: 'white !important',
            fontSize: '16px !important',
            fontFamily: 'Arial',
            fontWeight: 'bold !important',
            width: '94px',
          },
          '.success_button': {
            backgroundColor: colors.SECONDARY_GREEN + '!important',
            color: 'white !important',
            width: '108px',
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          borderTop: '1px solid',
          borderTopColor: colors.LIGHT_GREY,
          paddingTop: 15,
        },
      },
    },
  },
});
