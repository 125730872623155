import React, { FunctionComponent, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/images/logo-domanys.png';
import { Button, Input } from '../../components/';
import { colors } from '../../theme/shared-theme';
import { CONSTANTS, PATHS } from '../../utils';
import { login } from '../../store/auth/actions';

const useStyles = makeStyles({
  failure_message: {
    textAlign: 'center',
    fontSize: '13px',
    color: colors.SECONDARY_RED,
    fontStyle: 'italic',
    fontFamily: 'Arial-ItalicMT !important',
    fontWeight: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal !important',
  },
  login_button: {
    marginTop: '40px !important',
    width: '327px !important',
    fontFamily: 'Arial-BoldMT !important',
    fontSize: '16px !important',
  },
  login_logo: { height: 60, width: 133, marginBottom: 50 },
});

export const LoginForm: FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [values, setValues] = useState({
    password: '',
    login: '',
    showPassword: false,
  });
  const [errors, setErrors] = useState({
    password: '',
    login: '',
  });
  const [loginError, setLoginError] = useState('');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleChange = (prop: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoginError('');
    setErrors({ ...errors, [prop]: '' });
    setValues({ ...values, [prop]: event.target.value });
  };

  const validateForm = () => {
    let valid = true;

    if (!values.password && !values.login) {
      valid = false;
      setErrors({
        login: 'error.login_empty',
        password: 'error.password_empty',
      });
    } else if (!values.login) {
      valid = false;
      setErrors({
        ...errors,
        login: 'error.login_empty',
      });
    } else if (!values.password) {
      valid = false;
      setErrors({
        ...errors,
        password: 'error.password_empty',
      });
    }
    return valid;
  };

  const logInUser = async () => {
    if (validateForm()) {
      setLoading(true);
      setLoginError('');
      try {
        await dispatch(login(values.login, values.password));
        setLoading(false);
        navigate(PATHS.Planning);
      } catch (error) {
        const token = localStorage.getItem(CONSTANTS.TOKEN);
        setLoading(false);
        if (token) {
          navigate(PATHS.Planning);
        } else {
          setLoginError('input.message');
        }
      }
    }
  };

  return (
    <Box sx={{ p: 3, width: 328, height: 401 }} bgcolor="white">
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <Grid container justifyContent="center" alignItems="center">
          <img src={logo} alt="logo" className={classes.login_logo} />
        </Grid>
        {loginError && <Typography className={classes.failure_message}>{t(loginError)}</Typography>}
        <Grid container>
          <Input
            className={errors.login ? 'input_error' : ''}
            helperText={t(errors.login)}
            value={values.login}
            onChange={handleChange('login')}
            variant="filled"
            label={t('input.identifier')}
            fullWidth
            shrink
            inputId={'login-id'}
          />
        </Grid>
        <Grid container>
          <Input
            className={errors.password ? 'input_error' : ''}
            helperText={t(errors.password)}
            type={values.showPassword ? 'text' : 'password'}
            value={values.password}
            onChange={handleChange('password')}
            variant="filled"
            label={t('input.password')}
            fullWidth
            shrink
            inputId={'password-id'}
            endIcon={
              <Button
                disabled={!values.password}
                onClick={handleClickShowPassword}
                startIcon={{
                  name: values.showPassword || !values.password ? 'closedEye' : 'eye',
                  prefix: 'domanys',
                }}
              />
            }
          />
        </Grid>

        <Grid container justifyContent="center" alignItems="center">
          <Button
            onClick={() => logInUser()}
            variant="outlined"
            type="primary"
            size="large"
            fullWidth
            className={classes.login_button}
            spinning={loading}
            label={t('common.connect')}
            disabled={!values.password || !values.login || loading}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
