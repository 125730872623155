import * as React from 'react';

export const SmallDelete = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g stroke="#FFF" strokeWidth="1.5" fill="none" fillRule="evenodd">
      <path strokeLinejoin="round" d="M8.8 19.2h6.4L16.8 8H7.2zM9.6 8V4.8h4.8V8" />
      <path strokeLinecap="round" d="M5.6 8h12.8" />
    </g>
  </svg>
);
