import { createTheme } from '@mui/material';

export const switchTheme = createTheme({
  typography: {
    allVariants: {
      textTransform: 'none',
      display: 'flex',
      alignItems: 'center',
      fontFamily: 'Arial',
      fontStyle: 'normal',
      fontSize: '14px',
    },
  },
});
