import {
  patrimoniesActionType,
  PatrimoniesByAgency,
  patrimonyState,
  Patrimony,
  patrimonies,
  GET_PATRIMONIES,
  GETTING_PATRIMONIES,
  GET_PATRIMONIES_BY_AGENCY,
  GET_PATRIMONIES_ERROR,
  GET_PATRIMONY_DETAIL_SUCCESS,
  GET_PATRIMONY_DETAIL,
  GET_PATRIMONY_DETAIL_ERROR,
} from './types';

const INITIAL_STATE: patrimonyState = {
  patrimony: {} as Patrimony,
  patrimonies: [] as patrimonies[],
  patrimonyAgency: [] as PatrimoniesByAgency[],
  loading: true,
  addLoading: false,
  loadingPatrimonyDetail: false,
};

export const PatrimoniesReducer = (state: patrimonyState = INITIAL_STATE, action: patrimoniesActionType): patrimonyState => {
  switch (action.type) {
    case GETTING_PATRIMONIES:
      return {
        ...state,
        loading: true,
      };
    case GET_PATRIMONIES:
      return {
        ...state,
        patrimonies: action.payload,
        loading: false,
      };
    case GET_PATRIMONIES_BY_AGENCY:
      return {
        ...state,
        patrimonyAgency: action.payload,
        loading: false,
      };
    case GET_PATRIMONIES_ERROR:
      return {
        ...state,
        patrimonies: [] as patrimonies[],
        loading: false,
      };
    case GET_PATRIMONY_DETAIL:
      return {
        ...state,
        loadingPatrimonyDetail: true,
      };
    case GET_PATRIMONY_DETAIL_SUCCESS:
      return {
        ...state,
        patrimony: action.payload,
        loadingPatrimonyDetail: false,
      };
    case GET_PATRIMONY_DETAIL_ERROR:
      return {
        ...state,
        loadingPatrimonyDetail: false,
      };
    default:
      return state;
  }
};
