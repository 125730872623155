export const menu = {
  users: 'Utilisateurs',
  forms: 'Formulaires',
  notifications: 'Notifications',
  articles: 'Articles',
  contacts: 'Contacts',
  interventions: 'Interventions',
  collaborators: 'Collaborateurs',
  my_collaborators: 'Mes Collaborateurs',
  services: 'Prestations',
  planning: 'Planning',
  patrimony: 'Patrimoine',
};
