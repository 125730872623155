import * as React from 'react';

export const Trash = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs>
      <filter x="-3%" y="-70%" width="105.9%" height="240%" filterUnits="objectBoundingBox" id="a">
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="8" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" in="shadowBlurOuter1" result="shadowMatrixOuter1" />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <g filter="url(#a)" transform="translate(-1132 -5)" fill="none" fillRule="evenodd">
      <path d="M1132 5h40v40h-40z" />
      <path
        d="M1156.5 15v3.076h4.5v1.54l-1.636-.001L1158 35h-12l-1.364-15.385H1143v-1.538l4.5-.001V15h9zm-3.75 7.692h-1.5v9.231h1.5v-9.23zm-3.75 0h-1.5l.75 9.231h1.5l-.75-9.23zm7.5 0h-1.5l-.75 9.231h1.5l.75-9.23zm-1.5-6.154h-6v1.539h6v-1.539z"
        fill="#D8D8D8"
      />
    </g>
  </svg>
);
