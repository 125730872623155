import React from 'react';

export const Close = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="34" height="34" viewBox="0 0 34 34" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd" opacity=".5">
        <path d="M5 5h24v24H5z" />
        <g stroke="#72706F" strokeLinecap="round" strokeWidth="1.5">
          <path d="m12.43 21.634 9.05-9.051M12.43 12.583l9.05 9.051" />
        </g>
      </g>
    </svg>
  );
};

export default Close;
