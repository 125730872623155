import { Button as MaterialButton, CircularProgress, IconButton } from '@mui/material';
import { SxProps, ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { Icon } from '../..';
import { IconData } from '../../../interfaces/icon-data';
import { buttonTheme } from '../../../theme';

declare type ButtonVariant = 'contained' | 'outlined' | 'text';
declare type ButtonType = 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
declare type ButtonSize = 'large' | 'medium' | 'small';
declare type ButtonHtmlType = 'submit' | 'reset' | 'button';

interface ButtonProps {
  label?: string;
  labelClass?: string;
  startIcon?: IconData;
  endIcon?: IconData;
  disabled?: boolean;
  spinning?: boolean;
  variant?: ButtonVariant;
  type?: ButtonType;
  fullWidth?: boolean;
  size?: ButtonSize;
  htmlType?: ButtonHtmlType;
  className?: string;
  ariaRef?: React.RefObject<HTMLButtonElement>;
  ariaHaspopup?: boolean;
  ariaControls?: string;
  sx?: SxProps;
  onClick?(event?: React.MouseEvent<HTMLButtonElement>): void;
  style?: React.CSSProperties;
}

export function Button(props: ButtonProps): JSX.Element {
  if (props.label) {
    return (
      <ThemeProvider theme={buttonTheme}>
        <MaterialButton
          style={props.style}
          sx={props.sx}
          ref={props.ariaRef}
          size={props.size || 'large'}
          fullWidth={props.fullWidth}
          variant={props.variant}
          color={props.type}
          disabled={props.disabled}
          className={props.className}
          startIcon={props.startIcon ? <Icon {...props.startIcon} /> : null}
          endIcon={props.endIcon ? <Icon {...props.endIcon} /> : null}
          type={props.htmlType || 'button'}
          onClick={props.onClick}
          aria-haspopup={props.ariaHaspopup}
          aria-controls={props.ariaControls}
        >
          <span className={props.labelClass}>{props.label}</span>
          {props.spinning && <CircularProgress style={{ marginLeft: '0.5rem' }} size={20} color="inherit" />}
        </MaterialButton>
      </ThemeProvider>
    );
  }
  return (
    <ThemeProvider theme={buttonTheme}>
      <IconButton color={props.type} style={props.style} disabled={props.disabled} className={props.className} onClick={props.onClick}>
        {props.spinning ? <CircularProgress size={20} color="inherit" /> : props.startIcon && <Icon {...props.startIcon} />}
      </IconButton>
    </ThemeProvider>
  );
}

Button.defaultProps = {
  variant: 'contained',
  type: 'primary',
};
