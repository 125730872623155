import { Grid } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { InterventionsList } from '../../components/interventionList.tsx/interventionDafList/interventionList';
import { InterventionsGtiList } from '../../components/interventionList.tsx/interventionGtiList/interventionGtiList';
import { CONSTANTS, getRole } from '../../utils';

/**
 * Component InterventionsPage
 *
 * @component
 *
 * @example
 * return (
 *   <InterventionsPage localData={localData}/>
 * )
 */
const InterventionsPage: FunctionComponent = () => {
  return (
    <Grid container justifyContent="center" alignItems="center" className="listing_page_container">
      {getRole() === CONSTANTS.DAF_ROLE ? <InterventionsList /> : <InterventionsGtiList />}
    </Grid>
  );
};

export default InterventionsPage;
