import { FormControl, Input as MaterialInput, InputAdornment, InputBaseComponentProps } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { inputTheme } from '../../../theme';
import FormHelperText from '@mui/material/FormHelperText';

declare type InputType = 'text' | 'password';
declare type InputSize = 'small' | 'medium';
declare type InputVariant = 'standard' | 'outlined' | 'filled' | undefined;
declare type InputColor = 'secondary' | 'success' | 'error' | 'info' | 'warning' | 'primary' | undefined;

interface InputProps {
  label?: string;
  placeholder?: string;
  helperText?: string;
  defaultValue?: string;
  name?: string;
  readOnly?: boolean;
  multiline?: boolean;
  color?: InputColor;
  size?: InputSize;
  labelClass?: string;
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  disabled?: boolean;
  type?: InputType;
  fullWidth?: boolean;
  className?: string;
  helperTextClassName?: string;
  ariaRef?: React.RefObject<HTMLInputElement | HTMLTextAreaElement>;
  onChange?(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void;
  autoFocus?: boolean;
  shrink?: boolean;
  value?: unknown;
  inputId?: string;
  inputProps?: InputBaseComponentProps | undefined;
  variant: InputVariant;
}

export function Input(props: InputProps): JSX.Element {
  return (
    <ThemeProvider theme={inputTheme}>
      <FormControl fullWidth={props.fullWidth} className={props.className}>
        <label className={props.labelClass} htmlFor={props.inputId}>
          {props.label}
        </label>
        <MaterialInput
          autoComplete={'new-password'}
          id={props.inputId}
          size={props.size || 'medium'}
          autoFocus={props.autoFocus}
          color={props.color}
          disabled={props.disabled}
          type={props.type || 'text'}
          onChange={props.onChange}
          value={props.value}
          inputRef={props.ariaRef}
          startAdornment={props.startIcon ? <InputAdornment position="start">{props.startIcon}</InputAdornment> : null}
          endAdornment={props.endIcon ? <InputAdornment position="end">{props.endIcon}</InputAdornment> : null}
          placeholder={props.placeholder}
          name={props.name}
          readOnly={props.readOnly}
          multiline={props.multiline}
          defaultValue={props.defaultValue}
          inputProps={props.inputProps}
        />
        <FormHelperText className={props.helperTextClassName}>{props.helperText}</FormHelperText>
      </FormControl>
    </ThemeProvider>
  );
}
