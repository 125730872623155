import React from 'react';

export const Plus = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <g stroke="#FFF" strokeLinecap="round" strokeWidth="1.5" className="plus">
          <path d="M12 18.4V5.6M5.6 12h12.8" />
        </g>
      </g>
    </svg>
  );
};

export default Plus;
