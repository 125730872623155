import React from 'react';

export const Patrimony = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path stroke="#72706F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" d="M2.4 3.2H12v17.6H2.4zM12 8h9.6v12.8H12z" />
        <path fill="#72706F" d="M5.6 16.8h3.2v4H5.6zM6 11.2h2.4v2.4H6zM15.6 11.2H18v2.4h-2.4zM15.2 16.8h3.2V20h-3.2zM6 6.4h2.4v2.4H6z" />
      </g>
    </svg>
  );
};

export default Patrimony;
