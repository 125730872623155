export const error = {
  login_empty: "L'identifiant est obligatoire",
  password_empty: 'Le mot de passe est obligatoire',
  empty_field: 'Ce champ est vide',
  email_format: 'Email non valide',
  login_field: 'Le login doit contenir entre 10 et 30 caractères',
  unknow: 'Server error',
  network: 'Please check your internet connection and try again',
  endDate_field: "L'heure de fin doit être supérieure à l'heure de début",
};
