import React from 'react';

export const Planning = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="34" height="34" viewBox="0 0 34 34" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M23.803 27c1.055 0 1.852-.274 2.39-.823.538-.548.807-1.36.807-2.436V11.26c0-1.076-.269-1.888-.807-2.436-.538-.549-1.335-.823-2.39-.823H10.197c-1.055 0-1.852.272-2.39.817C7.27 9.362 7 10.177 7 11.26V23.74c0 1.083.269 1.897.807 2.442.538.545 1.335.817 2.39.817h13.606zm.063-1.851H10.124c-.427 0-.753-.094-.98-.282-.228-.188-.341-.47-.341-.845v-7.747c0-.375.113-.657.34-.845.228-.187.554-.281.98-.281h13.743c.426 0 .755.094.985.281.23.188.346.47.346.845v7.747c0 .376-.115.657-.346.845-.23.188-.559.282-.985.282z"
        fillRule="nonzero"
      />
    </svg>
  );
};

export default Planning;
