import React, { useEffect, useState } from 'react';
import { Box, Dialog, DialogContent, Grid, ThemeProvider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { dialogTheme } from '../../../theme';
import { getUsersTitleByRole, isValidEmail, isValidLogin } from '../../../utils';
import { DialogRadioGroup } from '../../ui/Dialog/DialogRadioGroup/DialogRadioGroup';
import { DialogSelect } from '../../ui/Dialog/DialogSelect/DialogSelect';
import { DialogTextField } from '../../ui/Dialog/DialogTextField/DialogTextField';
import Code from '../../ui/Icon/icons/Code';
import Function from '../../ui/Icon/icons/Function';
import OutlineIdentifier from '../../ui/Icon/icons/outlineIdentifier';
import ShutDown from '../../ui/Icon/icons/shutDown';
import UserInputIcon from '../../ui/Icon/icons/UserInputIcon';
import Email from '../../ui/Icon/icons/EmailIcon';
import { DialogActions } from '../dialogActions/dialogActions';
import { DialogHeader } from '../dialogTitle/dialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { addUser, editUser, getAllRoles } from '../../../store/user/actions';
import { getAllAgencies } from '../../../store/agency/actions';
import { RootState } from '../../..';

const initialValues = {
  id: '',
  civility: 'Mme',
  firstname: '',
  lastname: '',
  login: '',
  active: true,
  email: '',
  agencyId: '',
  function: '',
};

const initialErrorsValues = {
  firstname: '',
  lastname: '',
  login: '',
  function: '',
  active: '',
  email: '',
  agencyId: '',
};

interface UsersDialogProps {
  show?: boolean;
  changeDialog: (value: boolean) => void;
  type: string;
  data?: Array<string>;
}

/**
 * component describing an update/create user dialog
 *
 * @returns {JSX.Element}
 */

export const UsersDialog = (props: UsersDialogProps) => {
  const { t } = useTranslation();
  const { show = false, changeDialog, type, data } = props;
  const roles = useSelector((state: RootState) => state.users.roles);
  const addLoading = useSelector((state: RootState) => state.users.addLoading);
  const listeagency = useSelector((state: RootState) => state.agencies.items);
  const listeagencyLoading = useSelector((state: RootState) => state.agencies.loading);
  const [open, setOpen] = React.useState<boolean>(show);
  const title = getUsersTitleByRole();
  const dispatch = useDispatch();
  const [values, setValues] = useState({ ...initialValues });
  const [errors, setErrors] = useState({ ...initialErrorsValues });

  useEffect(() => {
    if (data) {
      setValues({ ...data, function: data && data.roleId ? data.roles.role : '' });
    } else {
      setValues({ ...initialValues });
      // console.log('values', values);
    }
    setOpen(show);
    setErrors({ ...initialErrorsValues });
    if (show) {
      getAgencies();
      getRoles();
    }
  }, [show]);

  const getAgencies = () => {
    dispatch(getAllAgencies());
  };
  const getRoles = () => {
    dispatch(getAllRoles());
  };
  const handleClose = () => {
    setOpen(false);
    changeDialog(false);
  };
  const validate = () => {
    errors.firstname = values.firstname ? '' : t('error.empty_field');
    errors.lastname = values.lastname ? '' : t('error.empty_field');
    errors.login = !values.login ? t('error.empty_field') : !isValidLogin(values.login) ? t('error.login_field') : '';
    errors.agencyId = values.agencyId ? '' : t('error.empty_field');
    errors.function = values.function ? '' : t('error.empty_field');
    errors.email = !values.email ? t('error.empty_field') : !isValidEmail(values.email) ? t('error.email_format') : '';
    setErrors({
      ...errors,
    });
    return Object.values(errors).every((x) => x === '');
  };

  const handleSubmitValider = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (validate()) {
      try {
        await dispatch(
          addUser(values.civility, values.firstname, values.lastname, values.agencyId, values.function, values.login, values.email, values.active),
        );
        setOpen(false);
        changeDialog(false);
      } catch (err) {
        console.log('eero accured');
      }
    }
  };

  const handleSubmitModifier = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (validate()) {
      try {
        await dispatch(
          editUser(
            data.id,
            values.civility,
            values.firstname,
            values.lastname,
            values.agencyId,
            values.function,
            values.login,
            values.email,
            values.active,
          ),
        );
        setOpen(false);
        changeDialog(false);
      } catch {
        console.log('eero accured');
      }
    }
  };

  const handleChangeCivility = (value: string) => {
    setValues({ ...values, civility: value });
  };

  const handleChangeActivate = (value: string) => {
    setValues({ ...values, active: value == 'true' ? true : false });
  };

  const handleChange = (prop: string) => (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    setErrors({ ...errors, [prop]: '' });
    setValues({ ...values, [prop]: event.target.value });
  };

  const getServices = () => {
    let list: string[] = [];
    if (listeagency) {
      list = listeagency?.map((item) => ({ label: item.name, value: item.id, id: item.id }));
    }
    return list;
  };

  return (
    <>
      <ThemeProvider theme={dialogTheme}>
        <Dialog open={open}>
          <DialogHeader type={type} onClose={handleClose} titleText={t(`user.create_${title}`)} canDelete={false} />
          <DialogContent sx={{ mt: 3 }}>
            <Grid container direction="column" alignItems="flex-start" rowSpacing={1}>
              <Box sx={{ m: 1 }} />
              <Grid item>
                <DialogRadioGroup
                  icon={UserInputIcon}
                  value={values.civility}
                  items={[
                    { id: 'Mme', title: t('common.madam') },
                    { id: 'Mr', title: t('common.mister') },
                  ]}
                  onchooseOption={(value: string) => handleChangeCivility(value)}
                ></DialogRadioGroup>
              </Grid>
              <Box sx={{ m: -1 }} />
              <Grid item>
                <DialogTextField
                  error={errors.firstname}
                  label="Prénom"
                  name="firsname"
                  value={values.firstname}
                  onChange={handleChange('firstname')}
                  withIcon={false}
                />
              </Grid>
              <Box sx={{ m: -0.6 }} />
              <Grid item>
                <DialogTextField
                  error={errors.lastname}
                  label="Nom"
                  name="lastname"
                  value={values.lastname}
                  onChange={handleChange('lastname')}
                  withIcon={false}
                />
              </Grid>
              <Box sx={{ m: 0.2 }} />
              <Grid item>
                <DialogSelect
                  loading={listeagencyLoading}
                  error={!!errors.agencyId}
                  onChange={handleChange('agencyId')}
                  label={t('common.agency')}
                  icon={Code}
                  value={values.agencyId}
                  items={getServices()}
                ></DialogSelect>
              </Grid>
              <Grid item>
                <DialogSelect
                  error={!!errors.function}
                  onChange={handleChange('function')}
                  label={'Fonction'}
                  icon={Function}
                  value={values.function}
                  items={roles.map((item) => ({ label: item.role, value: item.id, id: item.id }))}
                ></DialogSelect>
              </Grid>
              <Box sx={{ m: -0.8 }} />
              <Grid item>
                <DialogTextField
                  error={errors.login}
                  label="Login"
                  name="login"
                  icon={OutlineIdentifier}
                  value={values.login}
                  onChange={handleChange('login')}
                  withIcon={true}
                />
              </Grid>
              <Box sx={{ m: -0.4 }} />
              <Grid item>
                <DialogTextField
                  error={errors.email}
                  label="Email"
                  name="email"
                  value={values.email}
                  onChange={handleChange('email')}
                  withIcon={true}
                  icon={Email}
                />
              </Grid>
              <Box sx={{ m: 0.2 }} />
              <Grid item>
                <DialogRadioGroup
                  icon={ShutDown}
                  value={values.active}
                  items={[
                    { id: true, title: t('common.activate') },
                    {
                      id: false,
                      title: t('common.disable'),
                    },
                  ]}
                  onchooseOption={(value: string) => handleChangeActivate(value)}
                ></DialogRadioGroup>
              </Grid>
              <Box sx={{ m: 2 }} />
            </Grid>

            <Grid container direction="row" justifyContent="space-between" alignItems="center"></Grid>
          </DialogContent>
          <DialogActions
            secondButtonLabel={type === 'create' ? 'Valider' : 'Enregistrer'}
            type={type}
            onClose={handleClose}
            onAction={type === 'create' ? handleSubmitValider : handleSubmitModifier}
            loading={addLoading}
          />
        </Dialog>
      </ThemeProvider>
    </>
  );
};
