import {
  ServiceDeliveryTypeActionTypes,
  GET_SERVICES_DELIVERY_TYPE,
  GET_SERVICES_DELIVERY_TYPE_AVAILABLE,
  ADD_SERVICE_DELIVERY_TYPE,
  ADDING_SERVICE_DELIVERY_TYPE,
  DELETE_SERVICE_DELIVERY_TYPE,
  UPDATE_SERVICE_DELIVERY_TYPE,
  UPDATING_SERVICE_DELIVERY_TYPE,
  GETTING_SERVICES_DELIVERY_TYPE,
  GET_SERVICES_DELIVERY_TYPE_ERROR,
  ServiceDeliveryTypeWS,
} from './types';
import { APIS } from '../../api';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../index';
import { SnackBarActionTypes, SHOW_SNACK_BAR } from '../snackBar/types';
import { showSnackBar } from '../snackBar/actions';
import { errorMsg } from '../../utils';

// GET ALL SERVICES_DELIVERY_TYPE
export const getServiceDeliveryType =
  (page?: number, perPage?: number) =>
  async (dispatch: ThunkDispatch<RootState, undefined, ServiceDeliveryTypeActionTypes | SnackBarActionTypes>) => {
    dispatch({
      type: GETTING_SERVICES_DELIVERY_TYPE,
    });
    try {
      const allServicesPaginated = await APIS.servicesDeliveryType.getServicesDeliveryTypeList(page, perPage);
      let data = allServicesPaginated?.items;
      if (allServicesPaginated && allServicesPaginated?.items) {
        data = [];
        allServicesPaginated.items.map((item: ServiceDeliveryTypeWS) => {
          data.push({
            ...item,
            patrimony: item.authorizedPatrimonies && item.authorizedPatrimonies[0] ? item.authorizedPatrimonies[0] : null,
            refPat: item.authorizedPatrimonies && item.authorizedPatrimonies[0] ? item.authorizedPatrimonies[0].refPat : '',
            agencyId: item.authorizedPatrimonies && item.authorizedPatrimonies[0] ? item.authorizedPatrimonies[0].agencyId : '',
          });
        });
      }
     console.log("data",data)
       const removedEmpty = data.filter((item) => item.id !== "9");
      dispatch({
        type: GET_SERVICES_DELIVERY_TYPE,
        payload: { ...allServicesPaginated, items: removedEmpty },
      });
    } catch (error) {
      dispatch({
        type: SHOW_SNACK_BAR,
        payload: { message: errorMsg(error), severity: 'error' },
      });
      dispatch({
        type: GET_SERVICES_DELIVERY_TYPE_ERROR,
      });
    }
  };

// ADD NEW SERVICE_DELIVERY_TYPE
export const addServiceDeliveryType =
  (type: string, duration: number, refPat: string) =>
  async (dispatch: ThunkDispatch<RootState, undefined, ServiceDeliveryTypeActionTypes | SnackBarActionTypes>) => {
    dispatch({
      type: ADDING_SERVICE_DELIVERY_TYPE,
    });
    try {
      await APIS.servicesDeliveryType.addNewServiceDeliveryType({ type, duration, refPat });
      dispatch(showSnackBar('prestation.add_success', 'success'));
      dispatch(getServiceDeliveryType(1, 5));
      dispatch({
        type: ADD_SERVICE_DELIVERY_TYPE,
      });
      return true;
    } catch (error) {
      dispatch({
        type: SHOW_SNACK_BAR,
        payload: { message: errorMsg(error), severity: 'error' },
      });
      dispatch({
        type: ADD_SERVICE_DELIVERY_TYPE,
      });
      throw new Error('error');
    }
  };

// DELETE SERVICE_DELIVERY_TYPE
export const deleteServiceDeliveryType =
  (id: string) => async (dispatch: ThunkDispatch<RootState, undefined, ServiceDeliveryTypeActionTypes | SnackBarActionTypes>) => {
    await APIS.servicesDeliveryType.deleteServiceDelivery(id);
    dispatch(showSnackBar('prestation.delete_success', 'success'));
    dispatch(getServiceDeliveryType(1, 5));
    dispatch({
      type: DELETE_SERVICE_DELIVERY_TYPE,
      payload: id,
    });
    return true;
  };

// EDIT SERVICE_DELIVERY_TYPE
export const editServiceDeliveryType =
  (id: string, type: string, duration: number, refPat: string) =>
  async (dispatch: ThunkDispatch<RootState, undefined, ServiceDeliveryTypeActionTypes | SnackBarActionTypes>) => {
    try {
      await APIS.servicesDeliveryType.editServiceDelivery(id, { type, duration, refPat });
      dispatch(showSnackBar('prestation.update_success', 'success'));
      dispatch(getServiceDeliveryType(1, 5));
      dispatch({
        type: UPDATING_SERVICE_DELIVERY_TYPE,
      });
      return true;
    } catch (error) {
      dispatch({
        type: SHOW_SNACK_BAR,
        payload: { message: errorMsg(error), severity: 'error' },
      });
      dispatch({
        type: UPDATE_SERVICE_DELIVERY_TYPE,
      });
      throw new Error('error');
    }
  };

// GET EDIT SERVICE_DELIVERY_TYPE_AVAILABLE

export const getServiceDeliveryTypesAvailable = () => async (dispatch: ThunkDispatch<RootState, undefined, ServiceDeliveryTypeActionTypes>) => {
  const serviceDeliveryTypesAvailable = await APIS.servicesDeliveryType.getServiceDeliveryTypeAvailable();
  dispatch({
    type: GET_SERVICES_DELIVERY_TYPE_AVAILABLE,
    payload: serviceDeliveryTypesAvailable,
  });
};
