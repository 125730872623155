import { Box, Dialog, DialogContent, Stack, ThemeProvider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { exportServiceDelivery } from '../../../store/intervention/action';
import { dialogTheme } from '../../../theme';
import { Calendar } from '../../ui/Calendar/calendar';
import { DialogRadioGroup } from '../../ui/Dialog/DialogRadioGroup/DialogRadioGroup';
import Export from '../../ui/Icon/icons/Export';
import Table from '../../ui/Icon/icons/table';
import { DialogActions } from '../dialogActions/dialogActions';
import { DialogHeader } from '../dialogTitle/dialogTitle';
import { formatStartEndDate, getCurrentDate } from '../../../utils';

interface ExportDialogProps {
  show?: boolean;
  disabled: boolean;
  changeDialog: (value: boolean) => void;
  exportType: string;
  startDate: Date;
  endDate: Date;
  active: boolean;
}

/**
 * component describing the export interventions dialog
 *
 * @returns {JSX.Element}
 */

const useStyles = makeStyles({
  export_dialog: {
    '& .MuiDialog-paper': {
      height: '297px',
    },
    '& .MuiOutlinedInput-input': {
      paddingLeft: '5px !important',
      // paddingTop: "0px !important",
    },
  },
});
export const ExportDialog = (props: ExportDialogProps) => {
  const { t } = useTranslation();
  const { show = false, changeDialog, disabled } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState<boolean>(show);
  const [download, setDownload] = React.useState<boolean>(false);
  const [exportType, setExportType] = React.useState('');
  const today = new Date(getCurrentDate());
  console.log('today == ', today)
  const [startDate, setStartDate] = React.useState(formatStartEndDate(today));
  const [endDate, setEndDate] = React.useState(formatStartEndDate(today));
  const [calendarSelectedFromToday, setCalendarSelectedFromToday] = React.useState<boolean>(false);
  const handlechangeStartDate = (newValue: Date) => {
    const format: string = formatStartEndDate(newValue);
    if (disabled) {
      setStartDate(today);
    } else {
      setStartDate(format);
    }
  };

  const handlechangeEndtDate = (newValue: Date) => {
    const format: string = formatStartEndDate(newValue);
    if (disabled) {
      setEndDate(today);
    } else {
      setEndDate(format);
    }
  };

  const getInterventionsList = async () => {
    try {
      dispatch(exportServiceDelivery(exportType, startDate, endDate));
    } catch (error) {
      console.log('error');
    }
  };

  const handleExport = () => {
    setDownload(!download);
    getInterventionsList();
    setOpen(false);
    changeDialog(false);
    setExportType('');
  };

  const handleClose = () => {
    setOpen(false);
    changeDialog(false);
  };

  useEffect(() => {
    setOpen(show);
  }, [show]);

  return (
    <>
      <ThemeProvider theme={dialogTheme}>
        <Dialog className={classes.export_dialog} open={open}>
          <DialogHeader type={'export'} onClose={handleClose} titleText={t('intervention.export')} />
          <DialogContent sx={{ mt: 7 }}>
            <Stack spacing={1} direction="column">
              <DialogRadioGroup
                items={[
                  {
                    id: t('intervention.csv'),
                    title: t('intervention.csv'),
                  },
                  {
                    id: t('intervention.excel'),
                    title: t('intervention.excel'),
                  },
                  {
                    id: t('intervention.pdf'),
                    title: t('intervention.pdf'),
                  },
                ]}
                value={exportType}
                onchooseOption={(value: string) => setExportType(value)}
                icon={Export}
              ></DialogRadioGroup>

              <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={0}>
                <Box sx={{ paddingLeft: '7px' }}>
                  <Table></Table>
                </Box>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={0}>
                  <Calendar
                    disabled={false}
                    format="dd/MM/yyyy"
                    text={t('intervention.from')}
                    onChange={handlechangeStartDate}
                    value={disabled ? today : startDate}
                  />
                  {/* <Box ml={-9}></Box> */}
                  <Calendar
                    disabled={false}
                    format="dd/MM/yyyy"
                    text={t('intervention.to')}
                    onChange={handlechangeEndtDate}
                    value={disabled ? today : endDate}
                    calendarSelectedFromToday={calendarSelectedFromToday}
                  />
                </Stack>
              </Stack>
            </Stack>
          </DialogContent>
          <DialogActions type={'export'} onClose={handleClose} onAction={handleExport} secondButtonLabel={t('intervention.export')} />
        </Dialog>
      </ThemeProvider>
    </>
  );
};
