import React from 'react';

export const Contact = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="34" height="34" viewBox="0 0 34 34" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9.557 25.721c.194 0 .38-.043.557-.13.177-.087.383-.237.618-.45l2.315-2.045c-.229-.19-.438-.471-.626-.841-.189-.37-.283-.862-.283-1.473v-6.437c0-.92.184-1.712.553-2.377a3.768 3.768 0 0 1 1.578-1.535c.683-.36 1.49-.539 2.422-.539h6.432l-.009-.168a3.186 3.186 0 0 0-.502-1.405 2.774 2.774 0 0 0-1.144-.968C20.976 7.118 20.373 7 19.658 7H7.498c-1.097 0-1.954.292-2.572.875C4.31 8.458 4 9.28 4 10.34v8.7c0 1.033.294 1.835.883 2.407.59.572 1.41.858 2.461.858h1.304v2.423c0 .309.081.551.244.728.163.177.385.265.665.265zM24.872 27c.286 0 .509-.09.669-.27.16-.179.24-.42.24-.723v-2.423h.875c1.052 0 1.872-.288 2.46-.863.59-.574.884-1.378.884-2.41v-5.713c0-1.066-.309-1.89-.926-2.47-.618-.58-1.475-.87-2.573-.87h-9.475c-1.132 0-1.998.293-2.599.879-.6.586-.9 1.432-.9 2.537v5.637c0 1.054.302 1.864.905 2.427.603.564 1.468.846 2.594.846h3.421l3.25 2.835c.24.208.448.357.622.446.174.09.359.135.553.135z"
        fillRule="nonzero"
        opacity=".5"
      />
    </svg>
  );
};

export default Contact;
