import React, { useState } from 'react';
import { useEffect } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { OnTheTable } from '../table/onTheTable/onTheTable';
import { CONSTANTS, isAdmin } from '../../utils';
import { TableFactory } from '../table/tableFactory/TableFactory';
import { getPatrimonies } from '../../store/patrimonies/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../..';

/**
 * component returning the prestations list
 *
 * @returns {ExoticComponent<() => JSX.Element>}
 */
export const PrestationsList = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState<number>(CONSTANTS.DEFAULT_PAGE);
  const [perpage, setPerpage] = useState<number>(CONSTANTS.DEFAULT_PER_PAGE);
  const row_description = ['grey', 'grey'];

  const headItems = [
    {
      label: t('patrimony.ref_patrimony'),
      type: 'normal',
      height: '50',
      width: '200',
      value: 'refPat',
    },

    {
      label: t('patrimony.adress_patrimony'),
      type: 'normal',
      height: '50',
      width: '300',
      value: 'streetName',
    },
  ];

  // get redux Parimonies State
  const patrimonies = useSelector((state: RootState) => state.patrimonies.patrimonies);
  const loading = useSelector((state: RootState) => state.patrimonies.loading);

  // get dispatch hooks
  const dispatch = useDispatch();

  useEffect(() => {
    getPatrimoniesList();
  }, []);

  const getPatrimoniesList = () => {
    try {
      dispatch(getPatrimonies());
    } catch (error) {
      console.log('error');
    }
  };

  return (
    <>
      <Grid container rowSpacing={5} direction="column">
        <Grid item>
          <OnTheTable title={t('prestation.manage_prestation')} />
        </Grid>
        <Grid item>
          <TableFactory
            loading={loading}
            name="patrimoins"
            canDelete={false}
            headItems={headItems}
            bodyData={isAdmin() ? [] : patrimonies.slice((page - 1) * perpage, page * perpage)}
            rowDescription={row_description}
            count={patrimonies.length}
            page={page}
            perPage={perpage}
            handleChangePage={setPage}
            handleChangeRowsPerPage={(event) => {
              setPage(CONSTANTS.DEFAULT_PAGE);
              setPerpage(Number(event.target.value));
            }}
          ></TableFactory>
        </Grid>
      </Grid>
    </>
  );
};
