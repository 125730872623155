export const GET_SERVICES_DELIVERY_TYPE = 'serviceDeliverType/getAll';
export const ADD_SERVICE_DELIVERY_TYPE = 'serviceDeliveryType/add';
export const ADDING_SERVICE_DELIVERY_TYPE = 'serviceDeliveryType_add/loading';
export const UPDATE_SERVICE_DELIVERY_TYPE = 'serviceDeliveryType/update';
export const UPDATING_SERVICE_DELIVERY_TYPE = 'serviceDeliveryType_update/loading';
export const DELETE_SERVICE_DELIVERY_TYPE = 'serviceDeliveryType/delete';
export const GET_SERVICES_DELIVERY_TYPE_AVAILABLE = 'serviceDeliverTypeAvailable/getAll';
export const GETTING_SERVICES_DELIVERY_TYPE = 'serviceDeliveryType/loading';
export const GET_SERVICES_DELIVERY_TYPE_ERROR = 'serviceDeliveryType/fetchError';

export interface ServiceDeliveyTypePaginated {
  filter(arg0: (service: ServiceDeliveryType) => boolean): ServiceDeliveyTypePaginated;
  items: ServiceDeliveryType[];
  lastPage: number;
  totalItems: number;
}

// INTERFACE ServiceDeliveyType
export interface ServiceDeliveryType {
  id: string;
  type: string;
  duration: string;
  refPat: string;
  agencyId: string;
  patrimony: Patrimony;
}
export interface ServiceDeliveryTypeWS {
  id: string;
  type: string;
  duration: string;
  authorizedPatrimonies: Patrimony[];
}

// INTERFACE PATRIMONY
export interface Patrimony {
  id: string;
  refPat: string;
  nature: string;
  latitude: number;
  longitude: number;
  streetNumber: string;
  streetName: string;
  insee: number;
  constructType: string;
  floor: string;
  elevator: boolean;
  lastReEstablishment: number;
  class: string;
  surface: number;
  heaterType: string;
  heaterClass: string;
  waterClass: string;
  uptakeIndice: string;
  uptakeValue: number;
  gazClass: string;
  gazValue: number;
  mail: string;
  codeUser: number;
  codeService: number;
  level: number;
  agencyId: string;
}

// INTERFACE SERVICE_DELIVERY_TYPE_AVAILABLES
export interface ServiceDeliveryTypeAvailables {
  items: ServiceDeliveryTypeAvailable[];
  lastPage: number;
  totalItems: number;
}

// INTERFACE SERVICE_DELIVERY_TYPE_AVAILABLE
export interface ServiceDeliveryTypeAvailable {
  items: ServiceDeliveryTypeAvailable[];
  type: string;
  duration: string;
  id?: number;
}

export interface ServiceDeliveryTypeState {
  services: ServiceDeliveyTypePaginated;
  serviceDeliveryTypeAvailables: ServiceDeliveryTypeAvailables;
  items: ServiceDeliveryTypeAvailable[];
  loading: boolean;
  addLoading: boolean;
}

// GET SERVICE_DELIVERY_TYPE ACTION
interface GetServiceDeliverTypeAction {
  type: typeof GET_SERVICES_DELIVERY_TYPE;
  payload: ServiceDeliveyTypePaginated;
}

// ADD SERVICE_DELIVERY_TYPE ACTION
interface AddServiceDeliveryTypeAction {
  type: typeof ADD_SERVICE_DELIVERY_TYPE | typeof ADDING_SERVICE_DELIVERY_TYPE;
}
// DELETE SERVICE_DELIVERY_TYPE ACTION
interface DeleteServiceDeliveryTypeAction {
  payload: string | number;
  type: typeof DELETE_SERVICE_DELIVERY_TYPE;
}

// UPDATE SERVICE_DELIVERY_TYPE ACTION
interface UpdateServiceDeliveryTypeAction {
  type: typeof UPDATE_SERVICE_DELIVERY_TYPE | typeof UPDATING_SERVICE_DELIVERY_TYPE;
}

// GET SERVICE_DELIVERY_TYPE_AVAILABLES ACTION
interface GetServiceDeliveryTypeAvailableAction {
  type: typeof GET_SERVICES_DELIVERY_TYPE_AVAILABLE;
  payload: ServiceDeliveryTypeAvailable;
}

interface LoadingListAction {
  type: typeof GETTING_SERVICES_DELIVERY_TYPE;
}

interface ListErrorAction {
  type: typeof GET_SERVICES_DELIVERY_TYPE_ERROR;
}

export type ServiceDeliveryTypeActionTypes =
  | GetServiceDeliverTypeAction
  | AddServiceDeliveryTypeAction
  | DeleteServiceDeliveryTypeAction
  | UpdateServiceDeliveryTypeAction
  | GetServiceDeliveryTypeAvailableAction
  | LoadingListAction
  | ListErrorAction;
