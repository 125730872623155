import React from 'react';

export const ArrowDown = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h20v20H0z" />
        <path d="M8.676 12.801 5.077 7.567A1 1 0 0 1 5.901 6h7.198a1 1 0 0 1 .824 1.567L10.324 12.8a1 1 0 0 1-1.648 0z" fill="#3E3F40" />
      </g>
    </svg>
  );
};

export default ArrowDown;
