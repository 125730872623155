/* eslint-disable @typescript-eslint/no-explicit-any */
import { ENDPOINTS, sendAsyncRequest } from '../utils';

const getUsersList = async (page?: number, perPage?: number): Promise<any> => {
  const response = await sendAsyncRequest(
    'get',
    ENDPOINTS.USERS,
    true,
    { page: page, limit: perPage, filter: { include: [{ relation: 'roles' }], order: ['firstname'] } },
    undefined,
  );
  return response.data;
};

const addNewUser = async (data: {
  civility: string;
  firstname: string;
  lastname: string;
  agencyId: string;
  roleId: string;
  login: string;
  email: string;
  active: boolean;
}): Promise<any> => {
  await sendAsyncRequest('post', ENDPOINTS.USERS, true, undefined, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw new Error(error);
    });
};

const editUsr = async (
  id: string,
  data: {
    civility: string;
    firstname: string;
    lastname: string;
    agencyId: string;
    roleId: string;
    login: string;
    email: string;
    active: boolean;
  },
): Promise<any> => {
  await sendAsyncRequest('put', ENDPOINTS.USERS + '/' + id, true, undefined, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw new Error(error);
    });
};

const getRoles = async () => {
  const response = await sendAsyncRequest('get', ENDPOINTS.ROLES, true, undefined, undefined);
  return response.data;
};

export const users = { getUsersList, addNewUser, editUsr, getRoles };
