import React from 'react';

export const ShutDown = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g stroke="#72706F" strokeWidth="1.5" fill="none" fillRule="evenodd" strokeLinecap="round">
        <path d="M7.29 8.026a6.864 6.864 0 1 0 9.127-.02M11.864 12V4" />
      </g>
    </svg>
  );
};

export default ShutDown;
