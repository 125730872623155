export const CONSTANTS = {
  TOKEN: 'token',
  ROLE: 'role',
  FRENCH_ABV: 'fr',
  ENGLISH_ABV: 'en',
  ROLES: ['COLLAB_DAF', 'COLLAB_GTI', 'ADMIN'],
  USER: 'user',
  ADMIN_ROLE: 'ADMIN',
  GTI_ROLE: 'COLLAB_GTI',
  DAF_ROLE: 'COLLAB_DAF',
  INTEVENTION: 'intervention',
  INTERVENTIONDAF: 'interventionDAF',
  PRESTATION: 'prestation',
  PATRIMOINS: 'patrimoins',
  NOTIFICATION: 'notification',
  DELETE: 'delete',
  CREATE: 'create',
  EXPORT: 'export',
  SUCCESS: 'success',
  COLORED: 'colored',
  CHECKBOX: 'checkbox',
  CIRCLE: 'circle',
  DOMANYS: 'domanys',
  NORMAL: 'normal',
  BLACK: 'black',
  GREY: 'grey',
  Update: 'update',
  PENDING: 'pending',
  YES: 'oui',
  SNACK_BAR_DURATION: 3000,
  DEFAULT_PAGE: 1,
  DEFAULT_PER_PAGE: 25,
  STATUS_DONE: 'done',
  STATUS_IN_PROGRESS: 'inprogress',
  STATUS_TO_DO: 'todo',
};
