export interface Description {
  label: string;
  type: string;
  height: number;
  width: number;
  choices?: { label: string; value: string }[];
  value?: string;
  value1?: string;
  value2?: string;
  cellType?: string;
  cellType2?: string;
  valueName?: string;
  index1?: number;
  index?: number;
  indexCollab?: number;
  secondValue?: boolean;
}
export const headItems: Description[] = [
  {
    label: 'intervention.type',
    type: 'normal',
    height: 50,
    width: 200,
    value: 'type',
    valueName: 'type',
  },

  {
    label: 'intervention.Collaborators',
    type: 'normal',
    height: 50,
    width: 200,
    value: 'firstname',
    valueName: 'collaborators',
  },
  {
    label: 'intervention.date',
    type: 'normal',
    height: 50,
    width: 150,
    value: 'date',
    cellType: 'serviceDeliveryDate',
  },
  {
    label: 'intervention.status',
    type: 'select',
    height: 50,
    width: 100,
    choices: [
      { label: 'intervention.todo', value: '10' },
      { label: 'intervention.inprogress', value: '20' },
      { label: 'intervention.validated', value: '30' },
    ],
    value: 'status',
    valueName: 'status',
    cellType: 'status',
  },
  {
    label: 'intervention.stock_withdrawal',
    type: 'select',
    height: 50,
    width: 150,
    choices: [
      { label: 'intervention.yes', value: '10' },
      { label: 'intervention.no', value: '20' },
    ],
    value: 'outOfStock',
    valueName: 'outOfStock',
    cellType: 'outofStock',
  },

  {
    label: 'intervention.ref_patrimony',
    type: 'normal',
    height: 50,
    width: 280,
    value: 'refPat',
    valueName: 'refPat',
  },
];

export const row_description = ['black', 'black', 'grey', 'colored', 'circle', 'grey'];
