/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosPromise, AxiosRequestConfig, ResponseType } from 'axios';
import { CONFIG } from '../config/config';
import { MenuInterface } from '../interfaces/menu';
import { CONSTANTS } from './constants';
import { menuAdmin, menuDAF, menuGTI } from './menus';

/**
 * send async request
 * @param method "post" | "get" | "put" | "patch" | "delete"
 * @param baseURL
 * @param url
 * @param authorization set header authorization
 * @param params
 * @param data
 * @param content_type
 * @returns
 */
export const sendAsyncRequest = (
  method: 'post' | 'get' | 'put' | 'patch' | 'delete',
  url: string,
  authorization?: boolean,
  params?: { [key: string]: any },
  data?: { [key: string]: any },
  responseType?: ResponseType,
): AxiosPromise<any> => {
  const config: AxiosRequestConfig = {
    baseURL: CONFIG.BASE_URL_API,
    timeout: 20000,
    url,
    method,
    headers: {
      'content-type': 'application/json',
      'X-Application-Type': 'frontend-bo',
    },
  };
  if (params) {
    config.params = params;
  }
  if (data) {
    config.data = data;
  }
  if (authorization) {
    config.headers.Authorization = 'Bearer ' + localStorage.getItem(CONSTANTS.TOKEN);
  }
  if (responseType) {
    config.responseType = responseType;
  }
  return axios(config);
};

/**
 * Confirms if there is a connected user or not
 *
 * @returns {boolean}
 */
export const isLoggedIn = () => {
  return localStorage.getItem(CONSTANTS.TOKEN) ? true : false;
};

export const isRoleValid = () => {
  const role = getRole();
  return CONSTANTS.ROLES.includes(role);
};

export const getRole = () => {
  const user = getUser();
  return user && user?.roles && user?.roles[0] ? user.roles[0].role : '';
};

export const getUser = () => {
  const user = localStorage.getItem(CONSTANTS.USER);
  if (user == null) {
    return '';
  } else {
    return JSON.parse(decrypt(user));
  }
};

/**
 * used to encrypt users roles saved in local storage
 *
 * @returns {string}
 */
export const encrypt = (value: string): string => {
  const CryptoJS = require('crypto-js');
  const encryptedText = CryptoJS.AES.encrypt(value, process.env.DOMANYS_SECTRET_KEY).toString();
  return encryptedText;
};

/**
 * used to decrypt users roles saved in local storage
 *
 * @returns {string}
 */
export const decrypt = (value: string): string => {
  const CryptoJS = require('crypto-js');
  const bytes = CryptoJS.AES.decrypt(value, process.env.DOMANYS_SECTRET_KEY);
  const decryptedText = bytes.toString(CryptoJS.enc.Utf8);
  return decryptedText;
};

/**
 * used to get the current date in the format MM/DD/YYYY
 *
 *
 * @returns {string}
 */
export const getCurrentDate = (): string => {
  const today = new Date().toLocaleDateString();
  return today;
};

/**
 * verify if the app mode is dev
 *
 *
 * @returns {boolean}
 */
export const isDevMode = (): boolean => {
  return CONFIG.APP_MODE === 'development';
};

/**
 * rturn the correct menu array depending on the connected user
 *
 *
 * @returns {MenuInterface[]}
 */
export const getMenuByRole = (): MenuInterface[] => {
  const role = getRole();
  let menu: MenuInterface[] = [];

  switch (role) {
    case CONSTANTS.ADMIN_ROLE:
      menu = menuAdmin;
      break;
    case CONSTANTS.GTI_ROLE:
      menu = menuGTI;
      break;
    case CONSTANTS.DAF_ROLE:
      menu = menuDAF;
      break;
    default:
      menu = menuAdmin;
      break;
  }
  return menu;
};

/**
 * get the title to be displayed for the list of users to each role
 *
 *
 * @returns {string}
 */
export const getUsersTitleByRole = (): string => {
  const role = getRole();
  let menu = '';

  switch (role) {
    case CONSTANTS.ADMIN_ROLE:
      menu = 'users';
      break;
    case CONSTANTS.GTI_ROLE:
      menu = 'my_collaborators';
      break;
    case CONSTANTS.DAF_ROLE:
      menu = 'collaborators';
      break;
    default:
      menu = 'users';
      break;
  }
  return menu;
};

/**
 * verify if the current use is an admin
 *
 *
 * @returns {boolean}
 */
export const isAdmin = (): boolean => {
  const role = getRole();
  return role === CONSTANTS.ADMIN_ROLE;
};

export const reformatDate = (date: string): string => {
  const newDate = date.substring(0, 10);
  return newDate.replace(/-/g, '/');
};

/**
 * Validate email format
 *
 * @returns {boolean}
 */
export const isValidEmail = (email: string) => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email,
  );
};

/**
 * Validate login length min-10 max-30
 *
 * @returns {boolean}
 */
export const isValidLogin = (login: string) => {
  return /^.{2,30}$/.test(login);
};

/**
 * Return a valid date format
 *
 * @returns {boolean}
 */
export const formatDate = (date: string) => {
  const d = new Date(date);
  return (
    ('0' + d.getDate()).slice(-2) +
    '/' +
    ('0' + (d.getMonth() + 1)).slice(-2) +
    '/' +
    d.getFullYear() +
    ' à ' +
    ('0' + d.getHours()).slice(-2) +
    ':' +
    ('0' + d.getMinutes()).slice(-2)
  );
};

/**
 * Return a valid date format from service Delivery
 *
 * @returns {boolean}
 */
export const formatDateServiceDelivery = (date: string) => {
  const d = new Date(date);
  return ('0' + d.getDate()).slice(-2) + '/' + ('0' + (d.getMonth() + 1)).slice(-2) + '/' + d.getFullYear();
};

/**
 * Return a valid date format for startDate and endDate
 *
 * @returns {boolean}
 */
export const formatStartEndDate = (date: Date | string) => {
  const d = new Date(date);
  return d.getFullYear() + '-' + ('0' + (d.getMonth() + 1)).slice(-2) + '-' + ('0' + d.getDate()).slice(-2);
};

/**
 * Return error msg
 *
 * @returns {string}
 */
export const errorMsg = (event: any): string => {
  const defaultError = 'error.unknow';
  let msg = '';

  if (typeof event === 'object' && event.msg) {
    msg = event;
  } else if (typeof event === 'string') {
    msg = event || defaultError;
  } else if (event && event.data) {
    const eventData = event.data;
    if (eventData) {
      msg = eventData.msg;
    }
  } else {
    msg = 'error.network';
  }

  return msg || defaultError;
};

/**
 * Return a valid dateTime format for the calendar
 *
 * @returns {boolean}
 */
export const formatCalendarDate = (date: string, time: string) => {
  const d = new Date(date);
  const dateTimeString = d.getFullYear() + '-' + ('0' + (d.getMonth() + 1)).slice(-2) + '-' + ('0' + d.getDate()).slice(-2) + ' ' + time;
  const dateTime = new Date(dateTimeString);
  return dateTime;
};

/**
 * convert from float to hours
 * @param number
 * @returns string
 */
export const convertNumToTime = (number: number) => {
  if (!number) {
    return '00:00';
  }
  // Check sign of given number
  let sign: string | number = number >= 0 ? 1 : -1;

  // Set positive value of number of sign negative
  number = number * sign;

  // Separate the int from the decimal part
  let hour: string | number = Math.floor(number);
  let decpart = number - hour;

  const min = 1 / 60;
  // Round to nearest minute
  decpart = min * Math.round(decpart / min);

  let minute = Math.floor(decpart * 60) + '';

  // Add padding if need
  if (minute.length < 2) {
    minute = '0' + minute;
  }

  hour = hour + '';

  if (hour.length < 2) {
    hour = '0' + hour;
  }

  // Add Sign in final result
  sign = sign == 1 ? '' : '-';

  // Concate hours and minutes
  const time = sign + hour + ':' + minute;

  return time;
};

/**
 * get list of available times
 * @param number
 * @returns Array<string>
 */
export const getHoursByDuration = (number: number) => {
  const list = [];
  for (let i = 7; i <= 17; i++) {
    list.push(`${convertNumToTime(i)}-${convertNumToTime(i + number)}`);
  }
  return list;
};
