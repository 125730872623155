import {
  User,
  UserActionTypes,
  GET_USERS,
  ADD_USER,
  GET_ROLES,
  UPDATE_USER,
  GETTING_USERS,
  ADDING_USER,
  UPDATING_USER,
  GET_USERS_ERROR,
} from './types';
import { APIS } from '../../api';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../index';
import { showSnackBar } from '../snackBar/actions';
import { SnackBarActionTypes, SHOW_SNACK_BAR } from '../snackBar/types';
import { errorMsg, getRole } from '../../utils';

// GET ALL USERS
export const getUsers =
  (page?: number, perPage?: number) => async (dispatch: ThunkDispatch<RootState, undefined, UserActionTypes | SnackBarActionTypes>) => {
    dispatch({
      type: GETTING_USERS,
    });
    try {
      const paginatedUsers = await APIS.users.getUsersList(page, perPage);

      const data: User[] = [];
      const usersFromWs = paginatedUsers.items ? paginatedUsers.items : paginatedUsers;
      if (usersFromWs && usersFromWs?.length > 0) {
        usersFromWs.map((item) => {
          const user = { ...item, role: null };
          if (item.roles && item.roles[0]) {
            user.role = item.roles[0].role;
            user.role_description = item.roles[0].description;
            user.role_isDisplayed = item.roles[0].isDisplayed;
            user.role_level = item.roles[0].level;
            user.role_alias = item.roles[0].alias;
            user.role_access = item.roles[0].access;
            if ((getRole() === 'COLLAB_GTI' && item.roles[0].role != "COLLAB_DAF" && item.roles[0].role != "COLLAB_GTI" && item.roles[0].role != "ADMIN")) {
              data.push({ ...user });
            } else if ((getRole() === 'COLLAB_DAF' && item.roles[0].role != "COLLAB_DAF" && item.roles[0].role != "ADMIN")) {
              data.push({ ...user });
            } else if (getRole() === 'ADMIN')
              data.push({ ...user });
          }
        });
      }
      paginatedUsers.items = [...data];
      dispatch({
        type: GET_USERS,
        payload: paginatedUsers,
      });
    } catch (error) {
      dispatch({
        type: SHOW_SNACK_BAR,
        payload: { message: errorMsg(error), severity: 'error' },
      });
      dispatch({
        type: GET_USERS_ERROR,
      });
    }
  };

// ADD USER
export const addUser =
  (civility: string, firstname: string, lastname: string, agencyId: string, roleId: string, login: string, email: string, active: boolean) =>
    async (dispatch: ThunkDispatch<RootState, undefined, UserActionTypes | SnackBarActionTypes>) => {
      dispatch({
        type: ADDING_USER,
      });
      try {
        await APIS.users.addNewUser({ civility, firstname, lastname, agencyId, roleId, login, email, active });
        dispatch(showSnackBar('user.add_success', 'success'));

        dispatch(getUsers(1, 5));
        dispatch({
          type: ADD_USER,
        });
        return true;
      } catch (error) {
        dispatch({
          type: SHOW_SNACK_BAR,
          payload: { message: errorMsg(error), severity: 'error' },
        });
        dispatch({
          type: ADD_USER,
        });
        throw new Error('error');
      }
    };

// EDIT USER
export const editUser =
  (
    id: string,
    civility: string,
    firstname: string,
    lastname: string,
    agencyId: string,
    roleId: string,
    login: string,
    email: string,
    active: boolean,
  ) =>
    async (dispatch: ThunkDispatch<RootState, undefined, UserActionTypes | SnackBarActionTypes>) => {
      try {
        await APIS.users.editUsr(id, { civility, firstname, lastname, agencyId, roleId, login, email, active });
        dispatch(showSnackBar('user.update_success', 'success'));
        dispatch(getUsers(1, 5));
        dispatch({
          type: UPDATING_USER,
        });
        return true;
      } catch (error) {
        dispatch({
          type: SHOW_SNACK_BAR,
          payload: { message: errorMsg(error), severity: 'error' },
        });
        dispatch({
          type: UPDATE_USER,
        });
        throw new Error('error');
      }
    };

// GET ALL roles
export const getAllRoles = () => async (dispatch: ThunkDispatch<RootState, undefined, UserActionTypes>) => {
  const roles = await APIS.users.getRoles();

  dispatch({
    type: GET_ROLES,
    payload: roles,
  });
};
