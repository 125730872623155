import React from 'react';

export const PlanningClock = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g xmlns="http://www.w3.org/2000/svg" transform="translate(2.9 2.4)" stroke="#FFF" strokeWidth="1.6" fill="none" fillRule="evenodd">
        <circle cx="9.6" cy="9.6" r="8.8" />
        <path strokeLinecap="round" strokeLinejoin="round" d="M9.6 4.8v4.8L12 12" />
      </g>
    </svg>
  );
};

export default PlanningClock;
