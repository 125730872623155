import React, { FunctionComponent } from 'react';
import { Grid } from '@mui/material';
import { PlanningDashboard } from '../../components/planningDashboard/planningDashboard';

/**
 * Component PlanningPage
 *
 * @component
 *
 * @example
 * return (
 *   <PlanningPage />
 * )
 */
const PlanningPage: FunctionComponent = () => {
  return (
    <Grid container justifyContent="flex-start" alignItems="flex-start" className="plannig_page_container">
      <PlanningDashboard />
    </Grid>
  );
};

export default PlanningPage;
