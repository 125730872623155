import {
  ServiceDeliveryTypeActionTypes,
  ServiceDeliveryTypeState,
  ServiceDeliveryTypeAvailables,
  ServiceDeliveryTypeAvailable,
  ServiceDeliveyTypePaginated,
  GET_SERVICES_DELIVERY_TYPE,
  ADD_SERVICE_DELIVERY_TYPE,
  ADDING_SERVICE_DELIVERY_TYPE,
  DELETE_SERVICE_DELIVERY_TYPE,
  UPDATE_SERVICE_DELIVERY_TYPE,
  UPDATING_SERVICE_DELIVERY_TYPE,
  GET_SERVICES_DELIVERY_TYPE_AVAILABLE,
  GETTING_SERVICES_DELIVERY_TYPE,
  GET_SERVICES_DELIVERY_TYPE_ERROR,
} from './types';

// INITIAL STATE SERVICE
const INITIAL_STATE: ServiceDeliveryTypeState = {
  services: {} as ServiceDeliveyTypePaginated,
  serviceDeliveryTypeAvailables: {} as ServiceDeliveryTypeAvailables,
  items: [] as ServiceDeliveryTypeAvailable[],
  loading: true,
  addLoading: false,
};

// SERVICE REDUCER
export const ServiceDeliverTypeReducer = (
  state: ServiceDeliveryTypeState = INITIAL_STATE,
  action: ServiceDeliveryTypeActionTypes,
): ServiceDeliveryTypeState => {
  switch (action.type) {
    case GETTING_SERVICES_DELIVERY_TYPE:
      return {
        ...state,
        loading: true,
      };

    case GET_SERVICES_DELIVERY_TYPE:
      return {
        ...state,
        services: action.payload,
        loading: false,
      };

    case GET_SERVICES_DELIVERY_TYPE_ERROR:
      return {
        ...state,
        services: {} as ServiceDeliveyTypePaginated,
        loading: false,
      };
    case ADDING_SERVICE_DELIVERY_TYPE:
      return {
        ...state,
        addLoading: true,
      };
    case ADD_SERVICE_DELIVERY_TYPE:
      return {
        ...state,
        addLoading: false,
      };

    case DELETE_SERVICE_DELIVERY_TYPE:
      return {
        ...state,
        services: state.services.filter((service) => service.id !== action.payload),
        addLoading: false,
      };
    case UPDATING_SERVICE_DELIVERY_TYPE:
      return {
        ...state,
        addLoading: true,
      };
    case UPDATE_SERVICE_DELIVERY_TYPE:
      return {
        ...state,
        addLoading: false,
      };
    case GET_SERVICES_DELIVERY_TYPE_AVAILABLE:
      return {
        ...state,
        items: action.payload.items,
      };

    default:
      return state;
  }
};
