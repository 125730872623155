import React, { useState } from 'react';
import moment, { Moment } from 'moment';
import { PatrimoniesByAgency } from '../store/patrimonies/types';
import {APIS} from "../api";
import {setMyCalendarData} from "../store/myCalendar/actions";
import {useDispatch} from "react-redux";
import {ENDPOINTS} from "../utils";
import {CalendarRequestBody} from "../api/calendarService";
import {showSnackBar} from "../store/snackBar/actions";

export interface ContextProps {
  calendarWeek: Moment;
  weekTypeSelected: boolean;
  currentWeekSelected: boolean;
  handelChangeWeekNext: () => void;
  handelChangeWeekPrev: () => void;
  handleChangeSemaineType: () => void;
  HandleChangeSemaineEnCours: () => void;
  handleChangePatrimony: (value: PatrimoniesByAgency | null) => void;
  selectedPatrominy: PatrimoniesByAgency | null;
}

export const GlobalContext = React.createContext<ContextProps | null>(null);

const GlobalProvider = ({ children }: { children: React.ReactNode }) => {
  const [calendarWeek, setCalendarWeek] = useState<Moment>(moment());
  const [maxDate, setMaxDate] = useState(0);
  const [selectedPatrominy, setSelectedPatrominy] = useState<PatrimoniesByAgency | null>(null);
  const [weekTypeSelected, setWeekTypeSelected] = React.useState(false);
  const [currentWeekSelected, setCurretWeekSelected] = React.useState(true);
  const dispatch = useDispatch();
  const handelChangeWeekNext = () => {
    if (maxDate < 5) {
      if (weekTypeSelected) {
        dispatch(showSnackBar('parcourir les semaines n\'est pas autorisé dans la semaine type', 'info'));
      } else {
        setCalendarWeek(moment(calendarWeek).add(7, 'days'));
        setMaxDate(maxDate + 1);
      }
    }
  };

  const handelChangeWeekPrev = () => {
    if (maxDate <= 5 && maxDate > -25 && !weekTypeSelected) {
      setCalendarWeek(moment(calendarWeek).add(-7, 'days'));
      setMaxDate(maxDate - 1);
    } else {
      if(weekTypeSelected) {
        dispatch(showSnackBar('parcourir les semaines n\'est pas autorisé dans la semaine type', 'info'));
      } else {
        dispatch(showSnackBar('vous êtes arrivés à la semaine limite autorisée!', 'info'));
      }
    }
  };

  const handleChangePatrimony = (value: PatrimoniesByAgency | null) => {
    setSelectedPatrominy(value);
  };
  const handleChangeSemaineType = () => {
    setWeekTypeSelected(true);
    setCurretWeekSelected(false);
  };

  const HandleChangeSemaineEnCours = () => {
    setCurretWeekSelected(true);
    setWeekTypeSelected(false);
  };

  return (
    <GlobalContext.Provider
      value={{
        calendarWeek,
        weekTypeSelected,
        currentWeekSelected,
        handelChangeWeekPrev,
        handelChangeWeekNext,
        selectedPatrominy,
        handleChangePatrimony,
        handleChangeSemaineType,
        HandleChangeSemaineEnCours,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContext;
export { GlobalProvider };
