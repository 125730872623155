import {
  patrimoniesActionType,
  GET_PATRIMONIES,
  GETTING_PATRIMONIES,
  GET_PATRIMONIES_BY_AGENCY,
  GETTING_PATRIMONIES_BY_AGENCY,
  GET_PATRIMONIES_ERROR,
  GET_PATRIMONY_DETAIL,
  GET_PATRIMONY_DETAIL_SUCCESS,
  GET_PATRIMONY_DETAIL_ERROR,
  UPDATE_PATRIMONY,
  UPDATE_PATRIMONY_SUCCESS,
  UPDATE_PATRIMONY_ERROR,
} from './types';
import { APIS } from '../../api';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../index';
import { SnackBarActionTypes, SHOW_SNACK_BAR } from '../snackBar/types';
import { errorMsg } from '../../utils';

// GET ALL PATRIMONIES
export const getPatrimonies = () => async (dispatch: ThunkDispatch<RootState, undefined, patrimoniesActionType | SnackBarActionTypes>) => {
  dispatch({
    type: GETTING_PATRIMONIES,
  });
  try {
    const patrimonies = await APIS.patrimonies.getPatrimonies();
    dispatch({
      type: GET_PATRIMONIES,
      payload: patrimonies,
    });
  } catch (error) {
    dispatch({
      type: SHOW_SNACK_BAR,
      payload: { message: errorMsg(error), severity: 'error' },
    });
    dispatch({
      type: GET_PATRIMONIES_ERROR,
    });
  }
};
export const getPatrimoniesWithPrestation = (sds:string[]) => async (dispatch: ThunkDispatch<RootState, undefined, patrimoniesActionType | SnackBarActionTypes>) => {
  dispatch({
    type: GETTING_PATRIMONIES,
  });
  try {
    const patrimonies = await APIS.patrimonies.getPatrimoniesWithPrestation(sds);
    dispatch({
      type: GET_PATRIMONIES,
      payload: patrimonies,
    });
  } catch (error) {
    dispatch({
      type: SHOW_SNACK_BAR,
      payload: { message: errorMsg(error), severity: 'error' },
    });
    dispatch({
      type: GET_PATRIMONIES_ERROR,
    });
  }
};
// GET THE PATRIMONIES BY AGENCY
export const getAllPatrimoniesByAgency =
  (id: string) => async (dispatch: ThunkDispatch<RootState, undefined, patrimoniesActionType | SnackBarActionTypes>) => {
    dispatch({
      type: GETTING_PATRIMONIES_BY_AGENCY,
    });
    try {
      const AgencyPat = await APIS.patrimonies.getPatrimonyListByAgency(id);
      dispatch({
        type: GET_PATRIMONIES_BY_AGENCY,
        payload: AgencyPat,
      });
    } catch (error) {
      dispatch({
        type: SHOW_SNACK_BAR,
        payload: { message: errorMsg(error), severity: 'error' },
      });
      dispatch({
        type: GET_PATRIMONIES_ERROR,
      });
    }
  };

export const getPatrominyDetail =
  (id: string) => async (dispatch: ThunkDispatch<RootState, undefined, patrimoniesActionType | SnackBarActionTypes>) => {
    dispatch({
      type: GET_PATRIMONY_DETAIL,
    });
    try {
      const patrimony = await APIS.patrimonies.getPatrimonyDetail(id);
      dispatch({
        type: GET_PATRIMONY_DETAIL_SUCCESS,
        payload: patrimony,
      });
    } catch (error) {
      dispatch({
        type: SHOW_SNACK_BAR,
        payload: { message: errorMsg(error), severity: 'error' },
      });
      dispatch({
        type: GET_PATRIMONY_DETAIL_ERROR,
      });
    }
  };

export const updatePatrimony =
  (id: string, refPat: string, agencyId: string, serviceDeliveryTypeIds: string[]) =>
  async (dispatch: ThunkDispatch<RootState, undefined, patrimoniesActionType | SnackBarActionTypes>) => {
    dispatch({
      type: UPDATE_PATRIMONY,
    });
    try {
      const patrimony = await APIS.patrimonies.updatePatrimony(id, refPat, agencyId, serviceDeliveryTypeIds);
      dispatch({
        type: UPDATE_PATRIMONY_SUCCESS,
        payload: patrimony,
      });
    } catch (error) {
      dispatch({
        type: SHOW_SNACK_BAR,
        payload: { message: errorMsg(error), severity: 'error' },
      });
      dispatch({
        type: UPDATE_PATRIMONY_ERROR,
      });
    }
  };
