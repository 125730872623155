import { Box, FormControl, InputAdornment, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

interface DialogTextFieldProps {
  label?: string;
  name?: string;
  value?: string;
  error?: string;
  icon?: React.ComponentType<{ className: string }>;
  onChange?: (event: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>) => void;
  withIcon?: boolean;
  bigSize?: boolean;
  disableUnderline?: boolean;
}

const useStyles = makeStyles({
  big_size: {
    '& .MuiFilledInput-root': {
      fontSize: '17px',
    },
  },
});

export const DialogTextField = (props: DialogTextFieldProps) => {
  const { label, value, onChange, error, withIcon, bigSize, disableUnderline = true } = props;
  const InputIcon: React.ReactNode = props.icon;

  const classes = useStyles();

  return (
    <>
      <FormControl variant="standard">
        <TextField
          className={`background ${bigSize && classes.big_size}`}
          placeholder={label}
          InputProps={{
            disableUnderline: disableUnderline,
            'aria-label': 'Without label',
            startAdornment: (
              <InputAdornment position="start">{withIcon === true ? <InputIcon className="icon" /> : <Box sx={{ width: '24px' }} />}</InputAdornment>
            ),
          }}
          sx={{
            BackgroundColor: 'white',
            color: 'white',
          }}
          {...(error && { error: true, helperText: error })}
          // label={label}
          variant="filled"
          size="small"
          // name={name}
          value={value}
          onChange={onChange}
        />
      </FormControl>
    </>
  );
};
