import {
  AppBar,
  Box,
  Divider,
  FormControl,
  Grid,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
  Tabs,
  Tab,
  ThemeProvider
} from '@mui/material';
import moment from 'moment';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Button } from '..';
import logo from '../../assets/images/logo-domanys.png';
import styles from '../../assets/sass/header.module.scss';
import GlobalContext from '../../context/GlobalContext';
import { MenuInterface } from '../../interfaces/menu';
import { logout } from '../../store/auth/actions';
import { getMenuByRole, getUser, PATHS } from '../../utils';
import 'moment/locale/fr';
import { PatrimoniesSelect } from "../PatrimoniesSelect/PatrimoniesSelect";
import { color } from '@mui/system';
import { dialogTabsTheme } from '../../theme';

/**
 * component describing the application header
 *
 * @returns {JSX.Element}
 */

export const Header = () => {
  const { t } = useTranslation();
  const menu = getMenuByRole();
  const gContext = useContext(GlobalContext);

  const location = useLocation();

  const showWeeksMenu = location.pathname == PATHS.Home;

  const user = getUser();
  const navigate = useNavigate();

  const [value, setValue] = React.useState('one');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const handleLogout = () => {
    logout();
    navigate(PATHS.Login, { replace: true });
  };

  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
  const [clicked, setIconClicked] = React.useState(false);

  // get redux Parimonies State
  const handleMenu = ({ currentTarget }: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setIconClicked(true);
    setAnchorEl(currentTarget);
  };

  const handleClose = () => {
    handleLogout();
    setAnchorEl(null);
  };
  const handleCancellation = () => {
    setIconClicked(false);
    setAnchorEl(null);
  };

  return (
    <>
      <AppBar className={styles.app_bar} elevation={0}>
        <Toolbar className={styles.toolbar_app}>
          <Grid container alignItems="center" wrap="nowrap">
            <Grid container alignItems="center" wrap="nowrap">
              <Grid item>
                <NavLink to={PATHS.Users}>
                  <img src={logo} alt="logo" className={styles.logo} />
                </NavLink>
              </Grid>
              {showWeeksMenu && (
                <>
                  <Box sx={{ width: 65 }}></Box>
                  <Grid item>
                    <FormControl>
                      <PatrimoniesSelect />
                    </FormControl>
                  </Grid>
                  <Box sx={{ width: 25 }}></Box>
                  <Grid item>
                    <Stack alignItems="center" direction="row" justifyContent="space-between">
                      <ThemeProvider theme={dialogTabsTheme} >
                        <>
                          <Tabs style={{marginBottom:30}} value={value} onChange={handleChange} textColor="primary" indicatorColor="primary">
                            <Tab
                              className={styles.week_label}
                              sx={{ color: 'rgb(121, 118, 111)',  fontSize: 15, fontWeight: 'bold' }}
                              onClick={() => gContext?.handleChangeSemaineType()
                              }
                              label={t('week.type_week')}
                            >
                            </Tab>
                            <Box sx={{ width: 25 }}></Box>
                            <Tab
                              className={styles.week_label}
                              sx={{ color: 'rgb(121, 118, 111)', fontSize: 15, fontWeight: 'bold' }}
                              onClick={() => gContext?.HandleChangeSemaineEnCours()}
                              label={t('week.current_week')}
                            >
                            </Tab>
                          </Tabs>
                        </>
                      </ThemeProvider>

                      <Box sx={{ width: 27 }}></Box>
                      <Button
                        startIcon={{
                          name: 'arrowBack',
                          prefix: 'domanys',
                        }}
                        onClick={() => gContext?.handelChangeWeekPrev()}
                      />
                      <Button
                        startIcon={{
                          name: 'arrowForward',
                          prefix: 'domanys',
                        }}
                        onClick={() => gContext?.handelChangeWeekNext()}
                      />
                      <Box sx={{ width: 28 }}></Box>
                      <Typography className={styles.month}>{moment(gContext?.calendarWeek).format('MMMM YYYY')}</Typography>
                    </Stack>
                  </Grid>
                </>
              )}
            </Grid>
            <Grid item>
              <Grid wrap="nowrap" container alignItems="center">
                {menu.map((item: MenuInterface, index: number) => (
                  <Tooltip title={t(item.title) || ''} key={`menu-item-${index}`}>
                    <Grid>
                      {item.url ? (
                        <NavLink
                          key={`menu-item-${index}`}
                          to={item.url}
                          className={(navData) => (navData.isActive ? styles.icon_clicked : styles.icon_unclicked)}
                        >
                          <Button startIcon={item.icon} disabled={item.disabled} />
                        </NavLink>
                      ) : (
                          <Button startIcon={item.icon} disabled={item.disabled} />
                        )}
                    </Grid>
                  </Tooltip>
                ))}
                <Divider orientation="vertical" variant="middle" flexItem />
                <Button
                  className={clicked ? styles.icon_clicked : styles.icon_unclicked}
                  onClick={handleMenu}
                  startIcon={{
                    name: 'profile',
                    prefix: 'domanys',
                  }}
                />
                <Menu
                  PaperProps={{
                    style: {
                      width: 240,
                      height: 128,
                      borderRadius: '8px',
                      boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.16)',
                    },
                  }}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleCancellation}
                >
                  <MenuItem component="div" className={styles.user_details} sx={{ height: 75 }}>
                    <Stack direction="column">
                      <Typography className={styles.user}>{user?.firstname + ' ' + user?.lastname}</Typography>
                      <Typography className={styles.email}>{user?.email}</Typography>
                    </Stack>
                  </MenuItem>

                  <MenuItem sx={{ height: 35 }} className={styles.disconnect} onClick={handleClose}>
                    {t('user.disconnect')}
                  </MenuItem>
                </Menu>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
};
