import { Grid } from '@mui/material';
import { FunctionComponent } from 'react';
import { LoginForm } from '../../components/loginForm/loginForm';
import React from 'react';

const LoginPage: FunctionComponent = () => {
  return (
    <Grid container justifyContent="center" className="login_container" alignItems="center">
      <LoginForm />
    </Grid>
  );
};

export default LoginPage;
