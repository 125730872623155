import { Icon as MaterialIcon } from '@mui/material';
import React from 'react';
import { IconData } from '../../../interfaces/icon-data';
import { IMap } from '../../../utils/maputils';
import Active from './icons/Active';
import ArrowBack from './icons/ArrowBack';
import ArrowDown from './icons/ArrowDown';
import ArrowForward from './icons/ArrowForward';
import Article from './icons/Article';
import Clock from './icons/Clock';
import Close from './icons/Close';
import ClosedEye from './icons/ClosedEye';
import Code from './icons/Code';
import Contact from './icons/Contact';
import { Delete } from './icons/Delete';
import Export from './icons/Export';
import Eye from './icons/Eye';
import Form from './icons/Form';
import Function from './icons/Function';
import FunctionAbreviation from './icons/functionAbreviation';
import GreenFilledTick from './icons/greenFilledTick';
import Historic from './icons/Historic';
import IdCard from './icons/idCard';
import Intervention from './icons/Interventions';
import { ListIcon } from './icons/list';
import Location from './icons/location';
import { MenuDots } from './icons/Menu';
import Notifications from './icons/Notifications';
import OrangeFilledCircle from './icons/OrangeFilledCircle';
import OutlineIdentifier from './icons/outlineIdentifier';
import Patrimony from './icons/Patrimony';
import Planning from './icons/Planing';
import Plus from './icons/plus';
import Prestation from './icons/Prestation';
import Profile from './icons/Profile';
import ShutDown from './icons/shutDown';
import SideBarDownArrow from './icons/SideBarDownArrow';
import ArrowUp2, { SideBarUpArrow } from './icons/SideBarUpArrow';
import { SmallDelete } from './icons/smallDelete';
import { StockExit } from './icons/stockExit';
import Table from './icons/table';
import TextArea from './icons/TextArea';
import { Trash } from './icons/Trash';
import Trash2 from './icons/Trash2';
import UserInputIcon from './icons/UserInputIcon';
import { Users } from './icons/Users';
import { View } from './icons/View';
import { WhiteTick } from './icons/WhiteTick';
import { PatrimonyMenu } from './icons/PatrimonyMenu';
import { PlanningClock } from './icons/PlanningClock';
import { PlanningDone } from './icons/PlanningDone';

declare type IconName =
  | 'users'
  | 'profile'
  | 'arrowForward'
  | 'arrowBack'
  | 'intervention'
  | 'notification'
  | 'planning'
  | 'trash'
  | 'delete'
  | 'smallDelete'
  | 'idCard'
  | 'prestation'
  | 'contact'
  | 'article'
  | 'form'
  | 'shutDown'
  | 'function'
  | 'outlineIdentifier'
  | 'code'
  | 'trash2'
  | 'close'
  | 'arrowDown'
  | 'patrimony'
  | 'location'
  | 'list'
  | 'clock'
  | 'textArea'
  | 'email'
  | 'clock'
  | 'table'
  | 'functionAbreviation'
  | 'greenFilledTick'
  | 'plus'
  | 'arrowUp2'
  | 'eye'
  | 'export'
  | 'closedEye'
  | 'UserInput'
  | 'stockExit'
  | 'sideBarUpArrow'
  | 'sideBarDownArrow'
  | 'historic'
  | 'active'
  | 'menu'
  | 'whiteTick'
  | 'view'
  | 'orangeFilledCircle'
  | 'planningClock'
  | 'planningDone'
  | 'patrimonyMenu';
interface IconProps {
  onClick?(): void;
  title?: string;
  color?: string;
}

export function Icon(props: IconData & IconProps): JSX.Element {
  const iconMap = new IMap<IconName, JSX.Element>([
    ['users', <Users {...props} key={'users'} />],
    ['profile', <Profile {...props} key={'profile'} />],
    ['arrowForward', <ArrowForward {...props} key={'arrowForward'} />],
    ['arrowBack', <ArrowBack {...props} key={'arrowBack'} />],
    ['intervention', <Intervention {...props} key={'intervention'} />],
    ['notification', <Notifications {...props} key={'notification'} />],
    ['planning', <Planning {...props} key={'planning'} />],
    ['trash', <Trash {...props} key={'trash'} />],
    ['delete', <Delete {...props} key={'delete'} />],
    ['smallDelete', <SmallDelete {...props} key={'smallDelete'} />],
    ['idCard', <IdCard {...props} key={'idCard'} />],
    ['prestation', <Prestation {...props} key={'prestation'} />],
    ['contact', <Contact {...props} key={'contact'} />],
    ['article', <Article {...props} key={'article'} />],
    ['form', <Form {...props} key={'form'} />],
    ['shutDown', <ShutDown {...props} key={'form'} />],
    ['function', <Function {...props} key={'function'} />],
    ['code', <Code {...props} key={'code'} />],
    ['close', <Close {...props} key={'close'} />],
    ['trash2', <Trash2 {...props} key={'trash2'} />],
    ['outlineIdentifier', <OutlineIdentifier {...props} key={'outlineIdentifier'} />],
    ['arrowDown', <ArrowDown {...props} key={'arrowDown'} />],
    ['patrimony', <Patrimony {...props} key={'location'} />],
    ['location', <Location {...props} key={'location'} />],
    ['list', <ListIcon {...props} key={'textArea'} />],
    ['textArea', <TextArea {...props} key={'textArea'} />],
    ['clock', <Clock {...props} key={'clock'} />],
    ['table', <Table {...props} key={'table'} />],
    ['functionAbreviation', <FunctionAbreviation {...props} key={'functionAbreviation'} />],
    ['greenFilledTick', <GreenFilledTick {...props} key={''} />],
    ['plus', <Plus {...props} key={'plus'} />],
    ['arrowUp2', <ArrowUp2 {...props} key={'arrowUp2'} />],
    ['eye', <Eye {...props} key={'eye'} />],
    ['closedEye', <ClosedEye {...props} key={'closedEye'} />],
    ['export', <Export {...props} key={'export'} />],
    ['UserInput', <UserInputIcon {...props} key={'UserInput'} />],
    ['orangeFilledCircle', <OrangeFilledCircle {...props} key={'orangeFilledCircle'} />],
    ['stockExit', <StockExit {...props} key={'stockExit'} />],
    ['sideBarUpArrow', <SideBarUpArrow {...props} key={'sideBarUpArrow'} />],
    ['sideBarDownArrow', <SideBarDownArrow {...props} key={'sideBarDownArrow'} />],
    ['historic', <Historic {...props} key={'historic'} />],
    ['active', <Active {...props} key={'active'} />],
    ['menu', <MenuDots {...props} key={'menu'} />],
    ['whiteTick', <WhiteTick {...props} key={'whiteTick'} />],
    ['view', <View {...props} key={'view'} />],
    ['patrimonyMenu', <PatrimonyMenu {...props} key={'patrimonyMenu'} />],
    ['planningClock', <PlanningClock {...props} key={'planningClock'} />],
    ['planningDone', <PlanningDone {...props} key={'planningDone'} />],
  ]);

  switch (props.prefix) {
    case 'material':
      return (
        <MaterialIcon
          className={props.className}
          fontSize={props.fontSize}
          color={props.type}
          style={{ ...props.style, color: props.color }}
          onClick={props.onClick}
        >
          {props.name}
        </MaterialIcon>
      );

    case 'domanys':
      return iconMap.getValue(props.name as IconName) || <></>;

    default:
      return <></>;
  }
}
