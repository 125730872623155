import {
  UserActionTypes,
  ADDING_USER,
  GETTING_USERS,
  UserState,
  GET_USERS,
  UsersPaginated,
  ADD_USER,
  UPDATE_USER,
  UPDATING_USER,
  Role,
  GET_ROLES,
  GET_USERS_ERROR,
} from './types';

const INITIAL_STATE: UserState = {
  users: {} as UsersPaginated,
  roles: [] as Role[],
  loading: true,
  addLoading: false,
};

export const userReducer = (state: UserState = INITIAL_STATE, action: UserActionTypes): UserState => {
  switch (action.type) {
    case GETTING_USERS:
      return {
        ...state,
        loading: true,
      };
    case GET_USERS:
      return {
        ...state,
        users: action.payload,
        loading: false,
      };
    case GET_USERS_ERROR:
      return {
        ...state,
        users: {} as UsersPaginated,
        loading: false,
      };
    case ADDING_USER:
      return {
        ...state,
        addLoading: true,
      };
    case ADD_USER:
      return {
        ...state,
        addLoading: false,
      };
    case UPDATING_USER:
      return {
        ...state,
        addLoading: true,
      };
    case UPDATE_USER:
      return {
        ...state,
        addLoading: false,
      };
    case GET_ROLES:
      return {
        ...state,
        roles: action.payload,
      };
    default:
      return state;
  }
};
