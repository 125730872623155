import { createTheme } from '@mui/material/styles';
import { colors } from './shared-theme';

export const dialogTheme = createTheme({
  palette: {
    contrastThreshold: 3,
    tonalOffset: 0.1,
  },
  shadows: [
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
  ],
  typography: {
    allVariants: {
      textTransform: 'none',
      display: 'flex',
      alignItems: 'center',
      fontFamily: 'ArialMT',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      letterSpacing: 'normal',
      lineHeight: 'normal',
    },
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        root: {
          '.text_area_border': {
            borderRadius: '3.2px',
          },
          '.select_primary_text': {
            fontSize: '13px !important',
            color: colors.MAIN_BLUE,
          },
          '.select_secondary_text': {
            fontSize: '11px !important',
            color: colors.MEDIUM_LIGHT_GREY,
          },
          '.helper_text': {
            color: '#d32f2f',
            marginLeft: '13px',
          },
          '& .MuiDialog-paper': {
            borderRadius: '8px',
            width: 375,
            minHeight: 516,
          },
          '&.MuiDialog-container': {
            overflowY: 'scroll',
          },
          '.validate_week': {
            color: colors.MAIN_GREY,
            fontSize: '16px',
          },
          '.icon': {
            fill: colors.MAIN_GREY,
            height: '24px',
            width: '24px',
          },
          '.head_icon': {
            position: 'absolute',
          },
          '.background': {
            '& .MuiFilledInput-root': {
              backgroundColor: 'white',
              padding: '0px 0px 0px 0px',
            },
          },
          '.header_menu_item_selected': {
            fontSize: '17px',
            color: colors.MAIN_BLUE,
            fontFamily: 'ArialMT !important',

            textDecoration: 'underline',
            textDecorationThickness: '2px',
            textUnderlineOffset: '6px',
          },
          '.header_menu_item_unselected': {
            fontSize: '17px',
            fontFamily: 'ArialMT !important',
            color: colors.MAIN_GREY,
          },
          '.cancel_button': {
            backgroundColor: 'white !important',
            color: colors.MAIN_GREY + '!important',
            width: '103px',
          },
          '.green_button': {
            backgroundColor: colors.SECONDARY_GREEN + '!important',
            color: 'white !important',
            width: '128px',
          },
          '.blue_button': {
            backgroundColor: colors.MAIN_BLUE + '!important',
            color: 'white !important',
            minWidth: '96px',
          },
          '.export_blue_button': {
            width: '108px !important',
          },
          '.delete_error_button': {
            backgroundColor: colors.SECONDARY_RED,
            color: 'white !important',
            fontSize: '16px !important',
            fontFamily: 'Arial-BoldMT',
            fontWeight: 'bold !important',
            width: '123px',
            height: '48px',
          },
          '.purge_error_button': {
            width: '94px',
          },
          '.select_style': {
            // opacity: 1,
            '.MuiSelect-select': {
              color: colors.MAIN_GREY,
            },
            '.MuiSelect-icon': {
              color: colors.BLACK,
              fill: colors.BLACK,
              top: 0,
            },
          },
          '.delete_position': {
            right: 48,
            top: 8,
          },
          '.close_position': {
            right: 8,
            top: 8,
            zIndex: '1',
          },
          '.create_title': {
            position: 'absolute',
            right: 110,
            top: 3,
            fontFamily: 'ArialMT',
            fontSize: '17px',
            color: colors.MAIN_GREY,
          },
          '.export_title': {
            position: 'absolute',
            right: 150,
            top: 3,
            fontFamily: 'ArialMT',
            fontSize: '17px',
            color: colors.MAIN_GREY,
          },
          '.bold_title': {
            fontFamily: 'Arial',
            fontSize: '17px',
            fontWeight: 'bold',
            position: 'absolute',
            color: colors.SECONDARY_GREEN,
            left: 60,
            top: 3,
          },
          '.orange_title': {
            fontFamily: 'Arial',
            fontSize: '17px',
            fontWeight: 'bold',
            position: 'absolute',
            color: colors.SECONDARY_ORANGE,
            left: 60,
            top: 3,
          },

          '.text_area': {
            '& .MuiFilledInput-root': {
              background: colors.LIGHTER_GREY,
              height: '100px',
              width: '287px',
              paddingTop: '4px',
              paddingLeft: '0px !important',
            },
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: colors.MEDIUM_GREY,
          '&:hover': {
            background: 'none',
          },
          '&.Mui-checked': {
            color: colors.MAIN_BLUE,
          },
          '& .MuiSvgIcon-root': {
            fontSize: 16,
          },
        },
      },
    },

    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: colors.MAIN_BLUE,
          '&.Mui-checked': {
            color: colors.MAIN_BLUE,
          },
        },
        checked: {},
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.MuiFilledInput-root': {
            '&:after,&:before': {
              borderBottom: '1px solid #ececec',
              width: '270px',
              left: '40px',
            },
          },
          '& .MuiFilledInput-input': {
            paddingLeft: '19px',
            color: colors.MAIN_GREY,
          },
          '& .MuiInput-input': {
            paddingLeft: '19px',
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .MuiFormControl-root': {
            width: '150%',
          },
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          // paddingLeft: '7px',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {},
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: colors.DARK_GREY,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          borderTop: '1px solid',
          borderTopColor: colors.LIGHT_GREY,
          paddingTop: 15,
          button: {
            fontSize: 16,
            fontFamily: 'Arial-BoldMT',
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          overflow: 'scroll',
        },
      },
    },
  },
});
