/* eslint-disable @typescript-eslint/no-explicit-any */
import { ServiceDeliveryState } from '../store/intervention/type';
import { ENDPOINTS, EXPORT_FILE_FORMATS, sendAsyncRequest } from '../utils';

const getServiceDeliveryList = async () => {
  const response = await sendAsyncRequest('get', ENDPOINTS.SERVICEDELIVERIES, true, undefined, undefined);

  return response.data.calendar;
};

const getServiceDeliveryBYID = async (id: string, page?: number, perPage?: number) => {
  const response = await sendAsyncRequest(
    'get',
    ENDPOINTS.SERVICEDELIVERIES + '/' + id,
    true,
    { page: page, limit: perPage, filter: { include: [{ relation: 'types' }] } },
    undefined,
  );
  return response.data;
};
const getServiceDeliveryBYPAT = async (pat_ids: string[]) => {
  const response = await sendAsyncRequest('get', ENDPOINTS.SERVICEDELIVERIES, true, { pat_ids: pat_ids }, undefined);
  return response.data;
};
const getServiceJob = async (params: any, url: string) => {
  const weekCalendarResponse = await sendAsyncRequest('get', url, true, params, undefined);
  return weekCalendarResponse.data;
};
const getServiceDeliveryListGTI = async (page?: number, perPage?: number, status?: string, outOfStock: boolean = false) => {
  const response = await sendAsyncRequest('get', ENDPOINTS.SERVICEDELIVERIESGTI, true, { page: page, limit: perPage, status, outOfStock }, undefined);

  return response.data;
};

const addNewServiceDelivery = async (data: {
  date: string;
  initialTimeRange: string[];
  isIntervention: boolean;
  refPat: string;
  serviceDeliveryTypeIds: string[];
  collabIds: string[];
}): Promise<any> => {
  await sendAsyncRequest('post', ENDPOINTS.SERVICEDELIVERIES, true, undefined, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log('errorrrrr!!', error);
    });
};

const editServiceDel = async (
  id: string,
  data: {
    date: string;
    initialTimeRange: string[];
    actualTimeRange: string[];
    isIntervention: boolean;
    refPat: string;
    comment: string;
  },
): Promise<any> => {
  const response = await sendAsyncRequest('put', ENDPOINTS.SERVICEDELIVERIES + '/' + id, true, undefined, data);
  return response.data;
};
const updateServiceDelStatus = async (
  id: string,
  data: {
    timeIn: string;
    timeOut: string;
    status: string;
    comment: string;
    outOfStock: boolean;
    date: string;
  },
): Promise<any> => {
  const response = await sendAsyncRequest('patch', ENDPOINTS.SERVICEDELIVERIES + '/' + id + ENDPOINTS.UPDATE_STATUS, true, undefined, data);
  return response.data;
};

const exportServiceDelivery = (exportType: string, startDate: Date, endDate: Date) => {
  const apiURL = `${ENDPOINTS.EXPORT}${ENDPOINTS.EXPORT_TYPE}${exportType}${ENDPOINTS.STARTDATE}${startDate}${ENDPOINTS.ENDDATE}${endDate}`;
  sendAsyncRequest('get', apiURL, true, undefined, undefined, 'arraybuffer').then((response) => {
    let binaryData = [];
    binaryData.push(response.data);
    const _URL = window.URL || window.webkitURL;
    const downloadUrl = _URL.createObjectURL(new Blob(binaryData));
    const link = document.createElement('a');
    link.href = downloadUrl;
    const extension = EXPORT_FILE_FORMATS.find((e) => e.format == exportType)?.extension || '.pdf';
    link.setAttribute('download', `interventions_${Date.now()}${extension}`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  });
};

const purgeServiceDelivery = async (startDate: string) => {
  const response = await sendAsyncRequest('delete', ENDPOINTS.SERVICEDELIVERIES + '/purge', true, { end_date: startDate }, undefined);
  return response.data;
};

const deleteServiceDelivery = async (id: string): Promise<any> => {
  const response = await sendAsyncRequest('delete', ENDPOINTS.SERVICEDELIVERIES + '/' + id, true, undefined, undefined);
  return response.data;
};

const addIntervention = async (data: {
  date: string;
  initialTimeRange: string[];
  isIntervention: boolean;
  refPat: string;
  comment: string;
  serviceDeliveryTypeIds: string[];
  collabIds: string[];
}): Promise<any> => {
  await sendAsyncRequest('post', ENDPOINTS.SERVICEDELIVERIES, true, undefined, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const updateOnlyServiceDeliveryStatus = async (id: string, status: string): Promise<void> => {
  await sendAsyncRequest('patch', ENDPOINTS.UPDATE_SERVICE_STATUS + `/${id}`, true, undefined, { status });
};

const updateServiceDeliveryStatusWeek = async (id: string, week: Date): Promise<void> => {
  await sendAsyncRequest('post', ENDPOINTS.VALIDATE_WEEK, true, undefined, { week, id });
};

export const serviceDelivery = {
  getServiceDeliveryList,
  getServiceDeliveryBYID,
  addNewServiceDelivery,
  editServiceDel,
  exportServiceDelivery,
  purgeServiceDelivery,
  deleteServiceDelivery,
  getServiceDeliveryListGTI,
  addIntervention,
  getServiceJob,
  getServiceDeliveryBYPAT,
  updateServiceDelStatus,
  updateOnlyServiceDeliveryStatus,
  updateServiceDeliveryStatusWeek,
};
