import { Typography } from '@mui/material';
import React from 'react';
import { colors } from '../../../theme/shared-theme';
interface PatrimonyDetailsProps {
  reference: string;
  adress: string;
}

export const PatrimonyDetails = (props: PatrimonyDetailsProps) => {
  const { reference, adress } = props;

  return (
    <>
      <Typography sx={{ fontSize: '12px' }}>{reference}</Typography>
      <Typography
        sx={{
          fontSize: '12px',
          color: colors.MAIN_GREY,
          width: '117px',
        }}
      >
        {adress}
      </Typography>
    </>
  );
};
