import { AuthActionTypes, AuthState } from './types';

const INITIAL_STATE: AuthState = {
  user: {
    login: '',
    password: '',
    firstname: '',
    lastname: '',
    email: '',
  },
};

export const authReducer = (state = INITIAL_STATE, action: AuthActionTypes) => {
  switch (action.type) {
    case 'auth/login':
      return {
        ...state,
        user: {
          ...state.user,
          login: action.payload.login,
          firstname: action.payload.firstname,
          lastname: action.payload.lastname,
          email: action.payload.email,
        },
      };

    default:
      return state;
  }
};
