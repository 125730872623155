import React from 'react';

export const Intervention = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="34" height="34" viewBox="0 0 34 34" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M26 25a1 1 0 0 1 0 2H8a1 1 0 0 1 0-2h18zm-1-12a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H9a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16zm1-5a1 1 0 0 1 0 2H8a1 1 0 1 1 0-2h18z"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default Intervention;
