import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import fr from './locales';
import { CONSTANTS } from './utils';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      fr: {
        translation: fr,
      },
    },
    lng: CONSTANTS.FRENCH_ABV,
    fallbackLng: CONSTANTS.FRENCH_ABV,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
