import { createTheme } from '@mui/material/styles';
import { colors } from './shared-theme';

export const sideBarTheme = createTheme({
  palette: {
    contrastThreshold: 3,
    tonalOffset: 0.1,
  },
  shadows: [
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
  ],
  typography: {
    allVariants: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: 'ArialMT',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      color: colors.DARK_GREY,
      border: 'none',
      textAlign: 'left',
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },
  },
  components: {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          height: '18px',
          width: '18px',
          color: colors.MAIN_BLUE,
          '&.Mui-checked': {
            color: colors.MAIN_BLUE,
          },
          '&.pink': {
            color: colors.TERTIARY_PINK,
          },
          '&.green': {
            color: colors.TERTIARY_DARK_GREEN,
          },
          '&.grey': {
            color: colors.MAIN_GREY,
          },
          '&.blue': {
            color: '#257DCF',
          },
        },
        checked: {},
      },
    },
    // MuiButtonBase:{
    //   styleOverrides:{
    //     root: {
    //       "&.button_create":{
    //         width:"200px"
    //       }

    //     }
    //   }
    // }
  },
});
