import { Box, Tab, Tabs, ThemeProvider } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { dialogTabsTheme } from '../../../../theme';

interface DialogTabs {
  title: string;
  value: number;
  component: JSX.Element;
}
interface DialogTabsProps {
  items: Array<DialogTabs>;
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function a11yProps(index: number) {
  return {
    id: `create-${index}`,
    'aria-controls': `create-${index}`,
  };
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`create-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export const DialogTabs = (props: DialogTabsProps) => {
  const { items } = props;
  const [activeTab, setTab] = React.useState<number>(0);
  const { t } = useTranslation();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  return (
    <>
      <ThemeProvider theme={dialogTabsTheme}>
        <>
          <Box sx={{ m: -4 }} />
          <Tabs value={activeTab} onChange={handleChange} aria-label="basic tabs example">
            {items?.map((item: DialogTabs, index: number) => (
              <Tab label={t(item.title)} {...a11yProps(item.value)} key={`tab-${item.value}-${index}`} />
            ))}
          </Tabs>
          {items?.map((item: DialogTabs, index: number) => (
            <TabPanel value={activeTab} index={item.value} key={`tab-panel-${item.value}-${index}`}>
              {item.component}
            </TabPanel>
          ))}
        </>
      </ThemeProvider>
    </>
  );
};
