import React from 'react';

export const Form = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="34" height="34" viewBox="0 0 34 34" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M22.437 6c1.157 0 2.04.317 2.65.95.608.635.913 1.548.913 2.739V25.31c0 1.198-.305 2.113-.914 2.743-.609.63-1.492.946-2.65.946H11.564c-1.15 0-2.032-.315-2.644-.946C8.306 27.424 8 26.51 8 25.311V9.69c0-1.191.306-2.104.919-2.738C9.53 6.317 10.413 6 11.563 6zM16.25 21.25h-4.5a.75.75 0 1 0 0 1.5h4.5a.75.75 0 1 0 0-1.5zm6-5h-10.5a.75.75 0 1 0 0 1.5h10.5a.75.75 0 1 0 0-1.5zm0-5h-10.5a.75.75 0 1 0 0 1.5h10.5a.75.75 0 1 0 0-1.5z"
        fill="#72706F"
        fillRule="nonzero"
        opacity=".5"
      />
    </svg>
  );
};

export default Form;
