/* eslint-disable @typescript-eslint/no-explicit-any */
import { ENDPOINTS, sendAsyncRequest } from '../utils';

const getServicesDeliveryTypeList = async (page?: number, perPage?: number): Promise<any> => {
  const response = await sendAsyncRequest('get', ENDPOINTS.SERVICES, true, { page: page, limit: perPage }, undefined);
  return response.data;
};

const getServiceDeliveryTypeAvailable = async (): Promise<any> => {
  const response = await sendAsyncRequest('get', ENDPOINTS.SERVICES_AVAILABLES, true, undefined, undefined);
  return response.data;
};
const addNewServiceDeliveryType = async (data: { type: string; duration: number; refPat: string }): Promise<any> => {
  await sendAsyncRequest('post', ENDPOINTS.SERVICES, true, undefined, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log('errorrrrr!!', error);
    });
};

const deleteServiceDelivery = async (id: string): Promise<any> => {
  const response = await sendAsyncRequest('delete', ENDPOINTS.SERVICES + '/' + id, true, undefined, undefined);
  return response.data;
};

const editServiceDelivery = async (
  id: string,
  data: {
    type: string;
    duration: number;
    refPat: string;
  },
): Promise<any> => {
  await sendAsyncRequest('put', ENDPOINTS.SERVICES + '/' + id, true, undefined, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw new Error(error);
    });
};

export const servicesDeliveryType = {
  getServicesDeliveryTypeList,
  addNewServiceDeliveryType,
  deleteServiceDelivery,
  editServiceDelivery,
  getServiceDeliveryTypeAvailable,
};
