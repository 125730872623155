import React, { useEffect, useState } from 'react';
import { switchTheme } from '../../../../theme';
import { FormGroup, Grid, Typography, ThemeProvider, Switch } from '@mui/material';

interface DialogSwitchtProps {
  label?: string;
  duration?: number;
  value?: string;
  error?: boolean;
  status?: boolean;
  id?: string;
  getServicesStatus?: (id: string, status: boolean) => void;
}

export const DialogSwitch = (props: DialogSwitchtProps) => {
  const { getServicesStatus, status } = props;

  const [state, setState] = useState({
    checkedA: status,
  });

  const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, checkedA: !state.checkedA });
    getServicesStatus(event.target.name, event.target.checked);
  };
  useEffect(() => {
    setState({ ...state, checkedA: status });
  }, [status]);

  return (
    <>
      <ThemeProvider theme={switchTheme}>
        <FormGroup aria-label="position">
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 50 }}>
            <Grid component="label" item color={'#3e3f40'} fontSize={16} width="60%">
              {props.label}
              <Grid color={'gray'}>
                <Typography> {props.duration} </Typography>
              </Grid>
            </Grid>
            <Grid item style={{ marginLeft: 50 }}>
              <Switch onChange={handleChangeSwitch} inputProps={{ 'aria-label': 'controlled' }} name={props.id} checked={state.checkedA} />
            </Grid>
          </div>
        </FormGroup>
      </ThemeProvider>
    </>
  );
};
