import React from 'react';

export const Location = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(5.6 4.8)" fill="none" fillRule="evenodd">
        <path
          d="M6.4 15.2c4.267-3.51 6.4-6.444 6.4-8.8A6.4 6.4 0 1 0 0 6.4c0 2.356 2.133 5.29 6.4 8.8z"
          stroke="#72706F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle fill="#72706F" cx="6.4" cy="6.4" r="1.6" />
      </g>
    </svg>
  );
};

export default Location;
