import React from 'react';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Box, FormHelperText, TextField, TextFieldProps, ThemeProvider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import frLocale from 'date-fns/locale/fr';
import { useTranslation } from 'react-i18next';
import { calendarTheme } from '../../../theme';
import { colors } from '../../../theme/shared-theme';
import Table from '../Icon/icons/table';
import { FromTextBox } from './fromTextBox';
import { ToTextBox } from './toTextBox';

interface CalendarProps {
  disabled?: boolean;
  format?: string;
  text?: string;
  label?: string;
  onChange?: (value: Date | null) => void;
  startDateValue?: Date;
  endDateValue?: Date;
  value?: string | Date;
  error?: boolean | string;
  calendarSelectedFromToday?: boolean;
}

const useStyles = makeStyles({
  select: {
    padding: '15px 0px 0px 0px',
  },
  noBorder: {
    outline: 'none',
    border: 'none',
    color: '#fff',
  },

  reverse: {
    flexDirection: 'row-reverse',
    color: colors.MAIN_GREY,
    fontFamily: 'ArialMT',
    fontSize: '14px',
    textTransform: 'capitalize',
  },
});

export const Calendar = (props: CalendarProps) => {
  const { disabled, format, text, onChange, value, error, calendarSelectedFromToday } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const today = new Date();
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
        <ThemeProvider theme={calendarTheme}>
          <DatePicker
            open={open}
            onOpen={() => !disabled && setOpen(true)}
            onClose={() => setOpen(false)}
            disabled={disabled}
            inputFormat={format ? format : 'EEEE, dd MMMM'}
            mask="____, __ ____"
            leftArrowButtonText={t('notification.previous_month')}
            rightArrowButtonText={t('notification.next_month')}
            minDate={calendarSelectedFromToday ? today : null}
            InputProps={{
              classes: {
                notchedOutline: classes.noBorder,
                root: classes.reverse,
              },
            }}
            {...(error && { error: true })}
            components={{
              OpenPickerIcon: text === t('intervention.to') ? ToTextBox : text === t('intervention.from') ? FromTextBox : Table,
              SwitchViewIcon: Box,
            }}
            value={value ? new Date(value) : ''}
            onChange={(date) => {
              onChange(date);
              setOpen(false);
            }}
            renderInput={(params: TextFieldProps) => (
              <TextField {...params} onClick={() => !disabled && setOpen(true)} disabled={true} onKeyPress={(event) => event.preventDefault()} />
            )}
          />
          {error && (
            <FormHelperText
              className={'helper_text'}
              style={{ fontSize: 13, paddingTop: '20px', fontFamily: 'Arial-BoldMT', fontStyle: 'normal', fontWeight: 'bold' }}
            >
              {error}
            </FormHelperText>
          )}
        </ThemeProvider>
      </LocalizationProvider>
    </>
  );
};
