import { createTheme } from '@mui/material/styles';
import { colors } from './shared-theme';

export const calendarTableHeader = createTheme({
  palette: {
    contrastThreshold: 3,
    tonalOffset: 0.1,
  },
  shadows: [
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
  ],
  typography: {
    allVariants: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: 'ArialMT',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '24px',
      opacity: 0.5,
      color: colors.MAIN_GREY,
      // border: "none",
      textTransform: 'uppercase',
    },
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          height: 50,
          borderWidth: '1px',
          padding: '0px 16px',
          color: colors.MAIN_GREY,
        },
      },
    },
  },
});
