import React, { useContext, useEffect, useState } from 'react';
import { Box, Dialog, DialogContent, Grid, ThemeProvider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { dialogTheme } from '../../../theme';
import { DialogActions } from '../dialogActions/dialogActions';
import { DialogTitle } from '../../ui/Dialog/DialogLabel/DialogLabel';
import { DialogHeader } from '../dialogTitle/dialogTitle';
import ListIcon from '../../ui/Icon/icons/list';
import Patrimony from '../../ui/Icon/icons/Patrimony';
import Location from '../../ui/Icon/icons/location';
import FunctionAbreviation from '../../ui/Icon/icons/functionAbreviation';
import { Calendar } from '../../ui/Calendar/calendar';
import { CONSTANTS } from '../../../utils';
import Clock from '../../ui/Icon/icons/Clock';
import { DialogTextArea } from '../../ui/Dialog/DialogTextArea/DialogTextArea';
import { StockExit } from '../interventionsDialog/stockExit';
import UserInputIcon from '../../ui/Icon/icons/UserInputIcon';
import { MultiSelectItem, SelectMulti } from '../../SelectMulti/SelectMulti';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../index';
import GlobalContext from '../../../context/GlobalContext';
import { editServiceDelivery, updateServiceDeliveryStatus } from '../../../store/intervention/action';
import { User } from '../../../store/user/types';
import { APIS } from '../../../api';

interface TaskDialogProps {
  show?: boolean;
  loading?: boolean;
  canDelete?: boolean;
  showAction?: boolean;
  showStatus?: boolean;
  title?: string;
  changeDialog: (value: boolean) => void;
  onDelete: () => void;
  children?: React.ReactNode;
  showDeleteDialog?: boolean;
  setShowChangeDialog?: () => void;
  handleCloseEdit: () => void;
  weekTypeSelected?: boolean;
  updateWeek: () => void;
}

/**
 * component describing an update task dialog
 *
 */

export const CustomDialog = (props: TaskDialogProps) => {
  const { t } = useTranslation();
  const {
    show = false,
    canDelete = false,
    showAction = false,
    showStatus = false,
    changeDialog,
    showDeleteDialog,
    loading = false,
    onDelete,
    children,
    title,
    setShowChangeDialog,
    handleCloseEdit,
    weekTypeSelected,
    updateWeek,
  } = props;
  console.log('props in custom dialog == ', props);
  const [typePrestation, setTypePrestation] = useState('');
  const [sDate, setSDate] = useState('');
  const [startActualTimeRange, setStartActualTimeRange] = useState('');
  const [endActualTimeRange, setEndActualTimeRange] = useState('');
  const [hours, setHours] = useState('');
  const [refPat, setRefPat] = useState('');
  const [collab, setCollab] = useState('');
  const [collabNames, setCollabNames] = useState<string[]>([]);
  const [date, setDate] = useState('');
  const [adress, setAdress] = useState('');
  const [status, setStatus] = useState('');
  const [comment, setComment] = useState('');
  const [serviceId, setServiceId] = useState('');
  const [collabsList, setCollabsList] = useState<MultiSelectItem[]>([]);
  const [allCollabsList, setAllCollabsList] = useState<MultiSelectItem[]>([]);

  const gContext = useContext(GlobalContext);
  const listUsers = useSelector((state: RootState) => state.users.users?.items);
  const patrimoniesByAgency = useSelector((state: RootState) => state.patrimonies.patrimonyAgency);
  const pat = patrimoniesByAgency?.find((item) => item.refPat === gContext?.selectedPatrominy?.refPat);
  const filtredUsers = listUsers;
  const dispatch = useDispatch();

  const handleSubmitModifier = async () => {
    try {
      const resp = await APIS.serviceDelivery.updateOnlyServiceDeliveryStatus(serviceId, 'done');
      updateWeek();
    } catch (err) {
      console.log('error in updating service delivery STATUS');
    }
    changeDialog(false);
  };

  useEffect(() => {
    console.log('in dialog, data is ', props);
    if (show && children) {
      setAllCollabsList(
        filtredUsers.map((item) => ({
          label: (
            <Grid container wrap="nowrap" alignItems="center">
              <Grid item className="role-alias-text">{`${item.firstname} ${item.lastname}`}</Grid>
            </Grid>
          ),
          value: item.id,
          id: item.id,
        })),
      );
      if (children.props && children.props.children) {
        const data = children.props.children;
        if (data) {
          const titleService = data.types[0].type;
          const startDate = data.initialTimeRange[0];
          const endDate = data.initialTimeRange[1];
          const ref = data.refPat;
          const lastName = data.collaborators[0].lastname;
          const collabNames: string[] = [];
          data.collaborators.forEach((elem: User) => {
            collabNames.push(elem.firstname + ' ' + elem.lastname);
          });
          const firstName = data.collaborators[0].firstname;
          const d = data.date;
          const streetName = data.patrimony.streetName;
          const streetNumber = data.patrimony.streetNumber;
          const inse = data.patrimony.insee;
          const etat = data.status;
          const description = data.comment;
          const service = data.id;
          setCollabNames(collabNames);
          setTypePrestation(titleService);
          setHours(startDate + ' ' + 'à' + ' ' + endDate);
          setSDate(startDate);
          setRefPat(ref);
          setCollab(lastName + ' ' + firstName);
          setDate(d);
          setAdress(streetName + ' ' + ' ' + streetNumber + ' ' + inse);
          setStatus(etat);
          setComment(description);
          setServiceId(service);
          if (data.actualTimeRange) {
            setStartActualTimeRange(data.actualTimeRange[0]);
            setEndActualTimeRange(data.actualTimeRange[1]);
          }
        }

        setCollabsList(
          data.collaborators.map((item: any) => ({
            label: (
              <Grid container wrap="nowrap" alignItems="center">
                <Grid item className="role-alias-text">{`${item.firstname} ${item.lastname}`}</Grid>
              </Grid>
            ),
            value: item.id,
            id: item.id,
          })),
        );
      }
    }
  }, [show]);
  return (
    <>
      <ThemeProvider theme={dialogTheme}>
        <Dialog open={show}>
          <DialogHeader
            onClose={() => changeDialog(false)}
            canDelete={canDelete}
            showStatus={showStatus}
            type={'update'}
            etat={status}
            onDelete={onDelete}
            setShowChangeDialog={setShowChangeDialog}
            showDeleteDialog={showDeleteDialog}
            titleText={title}
            service={serviceId}
            handleCloseEdit={handleCloseEdit}
            weekTypeSelected={weekTypeSelected}
          />
          <DialogContent sx={{ mt: 3 }}>
            {weekTypeSelected ? (
              <>
                <Grid>
                  <DialogTitle icon={Patrimony} label={refPat} size={false} colorHours={false} />
                </Grid>
                <Grid>
                  <DialogTitle icon={ListIcon} label={typePrestation} size={false} colorHours={false} />
                </Grid>
                <Box sx={{ m: 2 }} />
                <Grid>
                  <Calendar value={date} label={'tous les lundi'} />
                </Grid>
                <Box sx={{ m: 2 }} />
                <Grid>
                  <DialogTitle label={hours} icon={Clock} colorHours={false} />
                </Grid>
                <Box sx={{ m: 2 }} />
                <Grid>
                  <SelectMulti
                    label={t('intervention.add_collaborator')}
                    icon={UserInputIcon}
                    values={collabsList}
                    items={allCollabsList}
                    onChange={function (value: MultiSelectItem[]): void {
                      console.log('this function is not yet ready');
                    }}
                  />
                </Grid>
              </>
            ) : (
              <>
                <Grid>
                  <DialogTitle icon={ListIcon} label={typePrestation} bigSize={true} color={true} />
                  <Calendar value={date} disabled={true} />
                  <DialogTitle label={hours} withoutIcon={true} bigSize={true} colorHours={true} />
                </Grid>
                <Box sx={{ m: 2 }} />
                <Grid item>
                  <DialogTitle label={refPat} icon={Patrimony} />
                </Grid>
                <Box sx={{ m: 2 }} />
                <Grid item>
                  <DialogTitle label={adress} icon={Location} />
                </Grid>
                <Box sx={{ m: 2 }} />
                {collabNames.map((cn) => {
                  return (
                    <>
                      <Grid item rowSpacing={1}>
                        <Grid>
                          {' '}
                          <FunctionAbreviation title={'AR'} />
                        </Grid>
                        <Grid sx={{ mt: -3.1 }}>
                          {' '}
                          <DialogTitle label={cn} withoutIcon={true} />
                        </Grid>
                      </Grid>
                    </>
                  );
                })}
                <Box sx={{ m: 2 }} />
                {showStatus && status === CONSTANTS.STATUS_IN_PROGRESS ? (
                  <Grid>
                    <DialogTitle label={sDate} icon={Clock} />
                    <Box sx={{ m: 2 }} />
                    <DialogTextArea label={comment} withoutIcon={true} disabled={true} />
                  </Grid>
                ) : showStatus && status === CONSTANTS.STATUS_DONE ? (
                  <Grid>
                    <DialogTitle label={startActualTimeRange} icon={Clock} iconColor={'green'} />
                    <Box sx={{ m: 2 }} />
                    <DialogTitle label={endActualTimeRange} icon={Clock} iconColor={'red'} />
                    <Box sx={{ m: 2 }} />
                    <Grid item>
                      <DialogTitle label={'Sacs poubelle et éponge'} icon={StockExit} />
                    </Grid>
                    <Box sx={{ m: 2 }} />
                    <DialogTextArea label={comment} withoutIcon={true} disabled={true} />
                  </Grid>
                ) : (
                  <></>
                )}
              </>
            )}
          </DialogContent>
          <>
            {weekTypeSelected ? (
              <>
                <DialogActions
                  secondButtonLabel={t('common.validate')}
                  onClose={() => changeDialog(false)}
                  onAction={handleSubmitModifier}
                  loading={loading}
                  type={'create'}
                />
              </>
            ) : (
              <>
                {' '}
                {showAction && (
                  <DialogActions
                    secondButtonLabel={t('common.validate')}
                    onClose={() => changeDialog(false)}
                    onAction={handleSubmitModifier}
                    loading={loading}
                    type={'create'}
                  />
                )}
              </>
            )}
          </>
        </Dialog>
      </ThemeProvider>
    </>
  );
};
