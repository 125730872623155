import { CONSTANTS } from '.';

export const FakeAdmin = {
  lastname: 'fakeAdmin',
  firstname: 'fakeAdmin',
  email: 'fakeAdmin@fake.com',
  type: [CONSTANTS.ADMIN_ROLE],
  token: 'token',
};

export const FakeDAF = {
  lastname: 'fakeDAF',
  firstname: 'fakeDAF',
  email: 'fakeDAF@fake.com',
  type: [CONSTANTS.DAF_ROLE],
  token: 'token',
};

export const FakeGTI = {
  lastname: 'fakeGTI',
  firstname: 'fakeGTI',
  email: 'fakeGTI@fake.com',
  type: [CONSTANTS.GTI_ROLE],
  token: 'token',
};
