import React, { useState, useEffect, useContext } from 'react';
import { Grid, SelectChangeEvent, Stack, ThemeProvider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../..';
import { dialogTheme } from '../../../../theme/dialog-theme';
import { Calendar } from '../../../ui/Calendar/calendar';
import { DialogTitle } from '../../../ui/Dialog/DialogLabel/DialogLabel';
import { DialogItem, DialogSelect } from '../../../ui/Dialog/DialogSelect/DialogSelect';
import Clock from '../../../ui/Icon/icons/Clock';
import ListIcon from '../../../ui/Icon/icons/list';
import Location from '../../../ui/Icon/icons/location';
import Patrimony from '../../../ui/Icon/icons/Patrimony';
import Profile from '../../../ui/Icon/icons/Profile';
import {getPatrimonies, getPatrominyDetail} from '../../../../store/patrimonies/actions';
import { addServiceDelivery } from '../../../../store/intervention/action';
import { convertNumToTime, getHoursByDuration } from '../../../../utils';
import { MultiSelectItem, SelectMulti } from '../../../SelectMulti/SelectMulti';
import { DialogActions } from '../../dialogActions/dialogActions';
import { PatrimoniesSelect } from "../../../PatrimoniesSelect/PatrimoniesSelect";
import GlobalContext from "../../../../context/GlobalContext";
import { colors } from '../../../../theme/shared-theme';

interface createPrestation {
    refPat?: string;
    prestation?: string;
    date?: string | Date;
    civility?: string;
    location?: string;
    initialTimeRange?: string[];
    isIntervention?: boolean;
    serviceDeliveryTypeIds?: string;
    collabIds?: MultiSelectItem[];
    collaboratorIds: string[];
    onCloseModal: () => void;

    startHour: string;
    endHour: string;
}

const initialValues: createPrestation = {
    refPat: '',
    prestation: '',
    date: '',
    civility: '',
    location: '',
    initialTimeRange: '',
    isIntervention: true,
    serviceDeliveryTypeIds: '',
    collabIds: [],
    collaboratorIds: [],

    startHour: '07:00',
    endHour: '07:00',
};

const initialErrorsValues = {
    refPat: '',
    serviceDeliveryTypeIds: '',
    date: '',
    initialTimeRange: '',
    // startHour: '00:00',
    // endHour: '00:00',
};

const PrestationDialogGtiBody = (props: createPrestation) => {
    const { onCloseModal } = props;
    const { t } = useTranslation();
    const [values, setValues] = useState(initialValues);
    const [collabsList, setCollabsList] = useState<MultiSelectItem[]>([]);
    const [servicesTypes, setServicesTypes] = useState<DialogItem[]>([]);
    const [times, setTimes] = useState<DialogItem[]>([]);
    const patrimoniesByAgency = useSelector((state: RootState) => state.patrimonies.patrimonies);
    const listUsers = useSelector((state: RootState) => state.users.users?.items);
    const selectedPatrimonyDetail = useSelector((state: RootState) => state.patrimonies.patrimony);
    const servicesTypesLoading = useSelector((state: RootState) => state.patrimonies.loadingPatrimonyDetail);
    const [errors, setErrors] = useState({ ...initialErrorsValues });
    const dispatch = useDispatch();

    const gContext = useContext(GlobalContext);
/*     const setPatrimony = (value: string) => {
        setValues({ ...values, prestation: '', initialTimeRange: ['00:00-00:00'], refPat: value });
        const pat = patrimoniesByAgency?.find((item) => item.refPat === value);
        const filtredUsers = listUsers;
        setCollabsList(
            filtredUsers.map((item) => ({
                label: (
                    <Grid container wrap="nowrap" alignItems="center">
                        <Grid item className="role-alias">
                            {item.role_alias?.substring(0, 2)}
                        </Grid>
                        <Grid item className="role-alias-text">{`${item.firstname} ${item.lastname}`}</Grid>
                    </Grid>
                ),
                value: item.id,
                id: item.id,
            })),
        );
        if (pat) {
            dispatch(getPatrominyDetail(pat?.id));
        }
    } */
    const setPatrimony = (value: any) => {
        setValues({...values, refPat: value});
        const pat = patrimoniesByAgency?.find((item) => item.refPat === value);
        const filtredUsers = listUsers;
        setCollabsList(
            filtredUsers.map((item) => ({
                label: (
                    <Grid container wrap="nowrap" alignItems="center">
                        <Grid item className="role-alias">
                            {item.role_alias?.substring(0, 2)}
                        </Grid>
                        <Grid item className="role-alias-text">{`${item.firstname} ${item.lastname}`}</Grid>
                    </Grid>
                ),
                value: item.id,
                id: item.id,
            })),
        );
        if (pat) {
            dispatch(getPatrominyDetail(pat?.id));
        }else if(value){
            dispatch(getPatrominyDetail(value));

        }
    }
    const generateHours = () => {
        const list = [];
        for (let i = 7; i <= 9; i++) {
            list.push({label: `0${i}:00`, value: `0${i}:00`});
            list.push({label: `0${i}:05`, value: `0${i}:05`});
            list.push({label: `0${i}:10`, value: `0${i}:10`});
            list.push({label: `0${i}:15`, value: `0${i}:15`});
            list.push({label: `0${i}:20`, value: `0${i}:20`});
            list.push({label: `0${i}:25`, value: `0${i}:25`});
            list.push({label: `0${i}:30`, value: `0${i}:30`});
            list.push({label: `0${i}:35`, value: `0${i}:35`});
            list.push({label: `0${i}:40`, value: `0${i}:40`});
            list.push({label: `0${i}:45`, value: `0${i}:45`});
            list.push({label: `0${i}:50`, value: `0${i}:50`});
            list.push({label: `0${i}:55`, value: `0${i}:55`});
        }
        for (let i = 10; i < 19; i++) {
            list.push({label: `${i}:00`, value: `${i}:00`});
            list.push({label: `${i}:05`, value: `${i}:05`});
            list.push({label: `${i}:10`, value: `${i}:10`});
            list.push({label: `${i}:15`, value: `${i}:15`});
            list.push({label: `${i}:20`, value: `${i}:20`});
            list.push({label: `${i}:25`, value: `${i}:25`});
            list.push({label: `${i}:30`, value: `${i}:30`});
            list.push({label: `${i}:35`, value: `${i}:35`});
            list.push({label: `${i}:40`, value: `${i}:40`});
            list.push({label: `${i}:45`, value: `${i}:45`});
            list.push({label: `${i}:50`, value: `${i}:50`});
            list.push({label: `${i}:55`, value: `${i}:55`});
        }
        return list;
    };
    const getAllPatrimonies = () => {
        dispatch(getPatrimonies());
    };
    const handleChange = (prop: string) => (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement> | SelectChangeEvent<string>) => {
        if (prop === 'refPat') {
        } else if (prop === 'serviceDeliveryTypeIds') {
            setValues({ ...values, initialTimeRange: [''], [prop]: event.target.value });
            const types = Object.values(selectedPatrimonyDetail?.sdTypes);
            const selectedService = types?.find((item) => item.ref_obj.id === event.target.value);
            if (selectedService) {
                const hours = getHoursByDuration(selectedService.ref_obj.duration);
                setTimes(hours.map((item) => ({ label: item, value: item, id: item })));
            } else {
                setTimes([]);
            }
        } else {
            setValues({ ...values, [prop]: event.target.value });
        }
    };

    const handleChangeDate = (event: Date | null) => {
        setValues({ ...values, date: event || '' });
    };

    const handleChangeCollabs = (event: MultiSelectItem[]) => {
        const array = [];
        event.map((el) => {
            array.push(el.id);
        });
        setValues({ ...values, collabIds: event || [], collaboratorIds: array });
    };

    const validate = () => {
        errors.refPat = values.refPat ? '' : t('error.empty_field');
        errors.serviceDeliveryTypeIds = values.serviceDeliveryTypeIds ? '' : t('error.empty_field');
        errors.initialTimeRange =
            values.startHour === '00:00' || values.endHour === '00:00' ? t('error.empty_field') : '';
        errors.date = values.date ? '' : t('error.empty_field');
        setErrors({
            ...errors,
        });
        console.log('!!!!!!', Object.values(errors));
        return Object.values(errors).every((x) => x === '');
    };

    const handleSubmitValider = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        // const startHour = values.initialTimeRange?.split('-')[0];
        //  const endHour = values.initialTimeRange?.split('-')[1];
        if (validate()) {
            try {
                await dispatch(
                    addServiceDelivery(
                        values.date,
                        [values.startHour, values.endHour],
                        false,
                        values.refPat,
                        [values.serviceDeliveryTypeIds],
                        values.collaboratorIds,
                        false
                    ),
                );
                onCloseModal();
            } catch (err) {
                console.log('eero accured');
            }
        } else {
            console.log('erroror')
        }
    };


    useEffect(() => {
        if (listUsers) {
            setCollabsList(
                listUsers.map((item) => ({
                    label: (
                        <Grid container wrap="nowrap" alignItems="center">
                            <Grid className="role-alias" item>
                                {item.role_alias?.substring(0, 2)}
                            </Grid>
                            <Grid className="role-alias-text" item>{`${item.firstname} ${item.lastname}`}</Grid>
                        </Grid>
                    ),
                    value: item.id,
                    id: item.id,
                })),
            );
        }
    }, [listUsers]);

    useEffect(() => {
        if (selectedPatrimonyDetail && selectedPatrimonyDetail?.sdTypes) {
            let types = Object.values(selectedPatrimonyDetail?.sdTypes);
            types = types.filter((type) => type.status);
            setServicesTypes(
                types.map((item) => ({
                    secondaryText: convertNumToTime(item.ref_obj.duration),
                    label: item.ref_obj.type,
                    value: item.ref_obj.id,
                    id: item.ref_obj.id,
                })),
            );

        }
    }, [selectedPatrimonyDetail]);

    useEffect(() => {
        getAllPatrimonies();
        if (gContext?.selectedPatrominy != null) {
            setPatrimony(gContext?.selectedPatrominy.refPat);
        }
    }, [])
    const getPatrimony = () => {
        const pat = patrimoniesByAgency.find((x) => x.refPat === values.refPat);
        return pat;
    };

    return (
        <ThemeProvider theme={dialogTheme}>
            <Stack direction="column" alignItems="flex-start" spacing={1.5}>

                <PatrimoniesSelect
                    setPatrimony={setPatrimony}
                    popup={true} />
                <DialogSelect
                    error={errors.serviceDeliveryTypeIds}
                    label={t('intervention.select_prestation_type')}
                    value={values.serviceDeliveryTypeIds}
                    onChange={handleChange('serviceDeliveryTypeIds')}
                    icon={ListIcon}
                    disabled={!values.refPat || servicesTypes.length <= 0}
                    items={servicesTypes}
                    loading={servicesTypesLoading}
                />
                <Calendar error={errors.date} disabled={!values.refPat} value={values.date} onChange={handleChangeDate}
                    label={t('common.date')}></Calendar>
                {/*                 <DialogSelect
                    error={errors.initialTimeRange}
                    disabled={!values.serviceDeliveryTypeIds}
                    label={'00:00-00:00'}
                    value={values.initialTimeRange}
                    onChange={handleChange('initialTimeRange')}
                    icon={Clock}
                    items={times}
                /> */}
                <Grid item>
                    <DialogSelect
                        iconColor={colors.SECONDARY_GREEN}
                        value={values.startHour}
                        onChange={handleChange('startHour')}
                        icon={Clock}
                        items={generateHours()}
                    />
                </Grid>

                <Grid item>
                    <DialogSelect
                        error={errors.endHour}
                        iconColor={colors.SECONDARY_RED}
                        value={values.endHour}
                        onChange={handleChange('endHour')}
                        icon={Clock}
                        items={generateHours()}
                    />
                </Grid>
                <DialogTitle
                    // fake data
                    label={values.refPat && getPatrimony() ? `${getPatrimony()?.streetNumber} ${getPatrimony()?.streetName}, ${getPatrimony()?.insee}` : ''}
                    icon={Location}
                />
                <SelectMulti
                    disabled={!values.refPat}
                    label={t('intervention.add_collaborator')}
                    onChange={handleChangeCollabs}
                    icon={Profile}
                    items={collabsList}
                    values={values.collabIds}
                />
            </Stack>
            <DialogActions type={'create'} onClose={onCloseModal} onAction={handleSubmitValider}
                secondButtonLabel={'Valider'}></DialogActions>
        </ThemeProvider>
    );
}
    ;

export default PrestationDialogGtiBody;
