export const GET_PATRIMONIES = 'patrimonies/getAll';
export const GETTING_PATRIMONIES = 'patrimonies/loading';
export const GET_PATRIMONIES_ERROR = 'patrimonies/fetchError';
export const GET_PATRIMONIES_BY_AGENCY = 'agency/id/patrimonies';
export const GETTING_PATRIMONIES_BY_AGENCY = 'partimonyAgency/loading';
export const GET_PATRIMONY_DETAIL = 'partimonyDetail/loading';
export const GET_PATRIMONY_DETAIL_SUCCESS = 'partimonyDetail/sucess';
export const GET_PATRIMONY_DETAIL_ERROR = 'partimonyDetail/error';
export const UPDATE_PATRIMONY = 'updatePatrimony/loading';
export const UPDATE_PATRIMONY_SUCCESS = 'updatePatrimony/success';
export const UPDATE_PATRIMONY_ERROR = 'updatePatrimony/error';

// INTERFACE PATRIMONIES
export interface patrimonies {
  patrimonies: Array<Patrimony>;
}

// INTERFACE PATRIMONY
export interface Patrimony {
  id: string;
  refPat: string;
  nature: string;
  latitude: number;
  longitude: number;
  streetNumber: string;
  streetName: string;
  insee: number;
  constructType: string;
  floor: string;
  elevator: boolean;
  lastReEstablishment: number;
  class: string;
  surface: number;
  heaterType: string;
  heaterClass: string;
  waterClass: string;
  uptakeIndice: string;
  uptakeValue: number;
  gazClass: string;
  gazValue: number;
  mail: string;
  codeUser: number;
  codeService: number;
  level: number;
  agencyId: string;
  sdTypes: { [key: string]: PatrimonyServicetype };
}

export interface PatrimonyServicetype {
  status: boolean;
  ref_obj: {
    duration: number;
    id: string;
    type: string;
  };
}

// INTERFACE PATRIMONY AGENCY
export interface PatrimoniesByAgency {
  id: string;
  refPat: string;
  patrimoniesByAgency: Patrimony[];
  streetNumber: string;
  streetName: string;
  insee: number;
  agencyId: string;
}

export interface patrimonyState {
  patrimony: Patrimony;
  patrimonies: patrimonies[];
  patrimonyAgency: PatrimoniesByAgency[];
  loading: boolean;
  addLoading: boolean;
  loadingPatrimonyDetail: boolean;
}

interface getPatrimoniesAction {
  type: typeof GET_PATRIMONIES;
  payload: patrimonies[];
}

interface getThePatrimonyAgency {
  type: typeof GET_PATRIMONIES_BY_AGENCY;
  payload: PatrimoniesByAgency[];
}

interface updateThePatrimony {
  type: typeof UPDATE_PATRIMONY;
}

interface LoadingGetThePatrimonyAgency {
  type: typeof GETTING_PATRIMONIES_BY_AGENCY;
}

interface LoadingListAction {
  type: typeof GETTING_PATRIMONIES;
}

interface ListErrorAction {
  type: typeof GET_PATRIMONIES_ERROR;
}

interface patrimonyActionType {
  type: typeof GET_PATRIMONY_DETAIL;
}
interface patrimonySuccessActionType {
  type: typeof GET_PATRIMONY_DETAIL_SUCCESS;
  payload: Patrimony;
}

interface patrimonyErrorActionType {
  type: typeof GET_PATRIMONY_DETAIL_ERROR;
}

interface PatrimonyUpdateAction {
  type: typeof UPDATE_PATRIMONY;
}
interface PatrimonyUpdateSuccessAction {
  type: typeof UPDATE_PATRIMONY_SUCCESS;
  payload: Patrimony;
}

interface PatrimonyUpdateErrorAction {
  type: typeof UPDATE_PATRIMONY_ERROR;
}

export type patrimoniesActionType =
  | getPatrimoniesAction
  | LoadingListAction
  | ListErrorAction
  | getThePatrimonyAgency
  | LoadingGetThePatrimonyAgency
  | updateThePatrimony
  | patrimonyActionType
  | patrimonySuccessActionType
  | patrimonyErrorActionType
  | PatrimonyUpdateAction
  | PatrimonyUpdateSuccessAction
  | PatrimonyUpdateErrorAction;
