import React from 'react';

export const Email = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <g stroke="#72706F" strokeWidth="1.5" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <path d="M4.8 7.2h14.4L12 13.6z" />
        <path d="M4.8 7.2h14.4v10.4H4.8z" />
      </g>
    </svg>
  );
};

export default Email;
