import React from 'react';

export const Notifications = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="34" height="34" viewBox="0 0 34 34" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M17.5 28c1.44 0 2.794-.275 4.062-.826a10.753 10.753 0 0 0 5.617-5.618c.547-1.264.821-2.616.821-4.056s-.274-2.792-.821-4.056a10.714 10.714 0 0 0-2.274-3.35 10.696 10.696 0 0 0-3.354-2.273A10.135 10.135 0 0 0 17.489 7c-1.426 0-2.772.274-4.04.821a10.696 10.696 0 0 0-3.354 2.274 10.714 10.714 0 0 0-2.274 3.349A10.097 10.097 0 0 0 7 17.5c0 1.44.275 2.792.826 4.056A10.771 10.771 0 0 0 10.1 24.9a10.753 10.753 0 0 0 3.35 2.274c1.267.55 2.617.826 4.05.826zm0-8.685c-.59 0-.893-.3-.907-.897l-.14-5.315a.898.898 0 0 1 .27-.718c.194-.19.45-.286.766-.286.31 0 .562.095.756.286.195.19.292.434.292.73l-.151 5.303c-.015.598-.31.897-.886.897zm0 3.489c-.331 0-.616-.11-.853-.33a1.054 1.054 0 0 1-.357-.804c0-.324.119-.596.357-.816.237-.22.522-.33.853-.33.331 0 .616.11.853.33.238.22.357.492.357.816 0 .324-.12.594-.362.81a1.23 1.23 0 0 1-.848.324z" />
    </svg>
  );
};

export default Notifications;
