import React from 'react';

export const SelectBtn = () => {
  return (
    <svg width="35" height="34" viewBox="0 0 35 34" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.527 7.4c5.293 0 9.584 4.298 9.584 9.6s-4.29 9.6-9.584 9.6c-5.293 0-9.584-4.298-9.584-9.6s4.29-9.6 9.584-9.6zm4.52 8.85h-9.012l-.102.007a.75.75 0 0 0 .102 1.493h9.012l.102-.007a.75.75 0 0 0-.102-1.493z"
        fill="#72706F"
        fillRule="evenodd"
        opacity=".5"
      />
    </svg>
  );
};

export default SelectBtn;
