import { Box, Stack, SwipeableDrawer, ThemeProvider, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '..';
import styles from '../../assets/sass/historyBar.module.scss';
import { dialogTheme } from '../../theme';
import { fake_history_data } from './fake_history_data';
import { HistoryItem } from './HistoryItem';
interface Item {
  date: string;
  prestation: string;
  collaborator: string;
  status: string;
}

interface HistoryBarProps {
  state: boolean;
  onClose: () => void;
  onOpen: () => void;
}
export const HistoryBar = (props: HistoryBarProps) => {
  const { onClose, onOpen, state } = props;
  const { t } = useTranslation();

  return (
    <ThemeProvider theme={dialogTheme}>
      <SwipeableDrawer
        BackdropProps={{
          style: {
            backgroundColor: 'transparent',
          },
        }}
        PaperProps={{
          style: {
            borderLeft: '1px solid #d1d1d1',
          },
        }}
        anchor={'right'}
        open={state}
        onClose={() => onClose()}
        onOpen={() => onOpen()}
      >
        <>
          <Box sx={{ width: 280 }} onClick={() => onClose()}>
            <Stack style={{ minWidth: '243px', paddingLeft: '24px' }} direction="row" justifyContent="space-between" alignItems="center">
              <Typography className={styles.history_title}>{t('history.history')}</Typography>
              <Button
                onClick={() => onClose()}
                startIcon={{
                  name: 'close',
                  prefix: 'domanys',
                }}
              />
            </Stack>
            <Stack direction="column" spacing={2}>
              {fake_history_data.map((item: Item, index: number) => (
                <HistoryItem data={item} key={index} />
              ))}
            </Stack>
          </Box>
        </>
      </SwipeableDrawer>
    </ThemeProvider>
  );
};
