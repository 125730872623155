export const fake_data_notifications = [
  ['Titre de la notification', '04:10/2021', 'Olivier Durand', 'Programmée'],
  ['Titre de la notification', '04:10/2021', 'Olivier Durand', 'Programmée'],
  ['Titre de la notification', '04:10/2021', 'Olivier Durand', 'Envoyée'],
  ['Titre de la notification', '04:10/2021', 'Olivier Durand', 'Programmée'],
  ['Titre de la notification', '04:10/2021', 'Olivier Durand', 'Envoyée'],
  ['Titre de la notification', '04:10/2021', 'Olivier Durand', 'Envoyée'],
  ['Titre de la notification', '04:10/2021', 'Olivier Durand', 'Programmée'],
  ['Titre de la notification', '04:10/2021', 'Olivier Durand', 'Envoyée'],
  ['Titre de la notification', '04:10/2021', 'Olivier Durand', 'Envoyée'],
];
