import React, { FunctionComponent } from 'react';
import { Grid } from '@mui/material';
import { NotificationsList } from '../../components/notificationsList/notificationsList';

/**
 * Component NotificationsPage
 *
 * @component
 *
 * @example
 * return (
 *   <NotificationsPage localData={localData}/>
 * )
 */
const NotificationsPage: FunctionComponent = () => {
  return (
    <Grid container justifyContent="center" alignItems="center" className="listing_page_container">
      <NotificationsList />
    </Grid>
  );
};

export default NotificationsPage;
