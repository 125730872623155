import { ENDPOINTS, sendAsyncRequest } from '../utils';

// GET ALL PATRIMONIES
const getPatrimonies = async () => {
  console.log("get all");
  const response = await sendAsyncRequest('get', ENDPOINTS.PATRIMONIES, true, { filter: { order: ['RefPat'] },pat_levels:["1","2","3"], }, undefined);

  return response.data;
};
const getPatrimoniesWithPrestation = async (sdt_ids: string[]) => {
  const response = await sendAsyncRequest('get', ENDPOINTS.PATRIMONIES, true, {  sdt_ids }, { filter: { order: ['RefPat'] },pat_levels:["1","2","3"] }, undefined);

  return response.data;
};
// GET PATRIMONIES BY AGENCY
const getPatrimonyListByAgency = async (id: string) => {
  console.log("get by ag");
  const response = await sendAsyncRequest(
    'get',
    ENDPOINTS.AGENCIES + '/' + id + ENDPOINTS.PATRIMONIES,
    true,
    { filter: { order: ['RefPat'] },pat_levels:["1","2","3"] },
    undefined,
  );
  return response.data;
};

// GET first patrimonies page,
const getSomePatrimonies = async (params: any) => {
  console.log("get some");
  const response = await sendAsyncRequest(
    'get',
    ENDPOINTS.PATRIMONIES,
    true,
    params,
    undefined,
  );
  return response.data;
};

// GET PATRIMONIES BY AGENCY
const getPatrimonyDetail = async (id: string) => {
  const response = await sendAsyncRequest('get', ENDPOINTS.PATRIMONIES + '/' + id, true, undefined, undefined);
  return response.data;
};

// GET PATRIMONY's services types
const getPatrimonyServiceTypes = async (id: string) => {
  const response = await sendAsyncRequest('get', ENDPOINTS.PATRIMONIES + '/' + id + ENDPOINTS.SERVICES, true, undefined, undefined);
  return response.data;
};

// UPDATE PATRIMONY
const updatePatrimony = async (id: string, refPat: string, agencyId: string, serviceDeliveryTypeIds: string[]) => {
  const response = await sendAsyncRequest('put', ENDPOINTS.PATRIMONIES + '/' + id, true, undefined, {  serviceDeliveryTypeIds });
  return response.data;
};

export const patrimonies = {
  getPatrimonies,
  getPatrimoniesWithPrestation,
  getPatrimonyListByAgency,
  getPatrimonyDetail,
  updatePatrimony,
  getSomePatrimonies,
  getPatrimonyServiceTypes
};
