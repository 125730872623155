import React from 'react';
import { Grid } from '@mui/material';
import { Header } from '../components';
import { GlobalProvider } from '../context/GlobalContext';

/**
 * Component Layout
 *
 * @component
 *
 * @example
 * return (
 *   <Layout>...</Layout>
 * )
 */
interface Layoutprops {
  children: React.ReactNode;
}

export const Layout = (props: Layoutprops) => {
  return (
    <GlobalProvider>
      <Grid container className="app_container">
        <Header />
        <Grid container>{props.children}</Grid>
      </Grid>
    </GlobalProvider>
  );
};
export default Layout;
