import { createTheme } from '@mui/material/styles';
import { colors } from './shared-theme';

export const inputTheme = createTheme({
  palette: {
    primary: {
      main: colors.BLACK,
      contrastText: colors.BLACK,
    },
    secondary: {
      main: colors.BLACK,
      contrastText: colors.BLACK,
      dark: colors.BLACK,
    },
    action: {
      disabledBackground: colors.BLACK,
    },
    contrastThreshold: 3,
    tonalOffset: 0.1,
  },
  shape: {
    borderRadius: 8,
  },
  typography: {
    allVariants: {
      textTransform: 'none',
      display: 'flex',
      alignItems: 'center',
      fontFamily: 'Arial',
      fontStyle: 'normal',
      fontSize: '17px',
    },
  },
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          margin: '0',
          marginTop: 3,
          color: colors.MAIN_GREY,
          fontSize: 12,
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          width: 327,
          borderRadius: 8,
          background: colors.LIGHTER_GREY,
          padding: '0 16px',
          marginTop: '4px !important',
          height: 48,
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: colors.LIGHT_GREY,
          '&:after,&:before': {
            content: 'none',
          },
          input: {
            width: 284,
            height: 24,
            padding: 0,
            '&:-webkit-autofill,&:-webkit-autofill:hover,	&:-webkit-autofill:focus,	&:-webkit-autofill:active': {
              WebkitBoxShadow: 'unset !important',
              WebkitTransition: 'color 9999s ease-out, background-color 9999s ease-out',
            },
          },
          '.MuiIconButton-root': {
            padding: 0,
          },
          '&.MuiInputBase-adornedEnd': {
            paddingRight: 8,
          },
          'input[type=password]': {
            fontWeight: 'bold',
            letterSpacing: 6,
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          margin: '12px 0',
          minWidth: 'none',
          label: {
            color: colors.MAIN_GREY,
            fontSize: 13,
            textAlign: 'left',
            fontFamily: 'Arial',
          },
          '&.input_error': {
            label: {
              color: colors.SECONDARY_RED,
            },
            '.MuiInput-root': {
              borderColor: colors.SECONDARY_RED,
            },
            '.MuiFormHelperText-root': {
              color: colors.SECONDARY_RED,
            },
          },
        },
      },
    },
  },
});
