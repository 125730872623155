export interface Description {
  label: string;
  type: string;
  height: number;
  width: number;
  choices?: { label: string; value: string }[];
  value?: string;
  value2?: string;
  cellType?: string;
  valueName?: string;
  index?: number;
}

export const usersHeader: Description[] = [
  {
    label: 'user.first_name',
    type: 'normal',
    height: 50,
    width: 200,
    value: 'firstname',
  },
  {
    label: 'user.last_name',
    type: 'normal',
    height: 50,
    width: 200,
    value: 'lastname',
  },
  {
    label: 'user.type',
    type: 'select',
    height: 50,
    width: 150,
    choices: [
      { label: 'Collaborateur', value: '10' },
      { label: 'Intérimaire', value: '20' },
    ],
    value: 'type',
    cellType: 'type',
  },
  {
    label: 'user.function',
    type: 'select',
    height: 50,
    width: 150,
    choices: [
      { label: 'AR', value: '10' },
      { label: 'EV', value: '20' },
      { label: 'AP', value: '30' },
      { label: 'DAF', value: '40' },
      { label: 'GTI', value: '50' },
      { label: 'OP', value: '60' },
      { label: 'RTA', value: '70' },
      { label: 'RT', value: '80' },
      { label: 'INT', value: '90' },
    ],
    value: 'role_alias',
    valueName: 'role',
  },
  {
    label: 'user.last_connection',
    type: 'normal',
    height: 50,
    width: 250,
    value: 'lastConnexionTime',
    cellType: 'date',
  },
  {
    label: 'user.account',
    type: 'normal',
    height: 50,
    width: 200,

    value: 'active',
    cellType: 'boolean',
  },
];

export const colabHeader: Description[] = [
  {
    label: 'user.first_name',
    type: 'normal',
    height: 50,
    width: 200,

    value: 'firstname',
  },
  {
    label: 'user.last_name',
    type: 'normal',
    height: 50,
    width: 200,
    value: 'lastname',
  },
  {
    label: 'user.function',
    type: 'select',
    height: 50,
    width: 150,
    choices: [
      { label: 'AR', value: '10' },
      { label: 'EV', value: '20' },
      { label: 'AP', value: '30' },
      { label: 'DAF', value: '40' },
      { label: 'GTI', value: '50' },
      { label: 'OP', value: '60' },
      { label: 'RTA', value: '70' },
      { label: 'RT', value: '80' },
      { label: 'INT', value: '90' },
    ],
    value: 'role_alias',
    valueName: 'role',
  },
  {
    label: 'user.last_connection',
    type: 'normal',
    height: 50,
    width: 250,
    value: 'lastConnexionTime',
    cellType: 'date',
  },
  {
    label: 'user.account',
    type: 'normal',
    height: 50,
    width: 200,
    value: 'active',
    cellType: 'boolean',
  },
];

export const rowDescriptionUsers = ['black', 'black', 'grey', 'grey', 'grey', 'colored'];

export const rowDescriptionCol = ['black', 'black', 'grey', 'grey', 'colored'];
