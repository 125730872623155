import React from 'react';

export const Trash2 = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="34" height="34" viewBox="0 0 34 34" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g stroke="#72706F" strokeWidth="1.5" fill="none" fillRule="evenodd" opacity=".5">
        <path strokeLinejoin="round" d="M13.8 24.2h6.4L21.8 13h-9.6zM14.6 13V9.8h4.8V13" />
        <path strokeLinecap="round" d="M10.6 13h12.8" />
      </g>
    </svg>
  );
};

export default Trash2;
