import { Box, FormControl, Stack, TextField } from '@mui/material';
import React, { ChangeEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import TextArea from '../../Icon/icons/TextArea';
interface DialogTextAreaProps {
  withoutIcon?: boolean;
  label?: string;
  disabled?: boolean;
  onChange?: (event: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export const DialogTextArea = (props: DialogTextAreaProps) => {
  const { t } = useTranslation();
  const { withoutIcon, onChange, label, disabled } = props;
  return (
    <>
      <Stack direction="row" alignItems="center">
        <Box sx={{ width: '24px' }}>
          {!withoutIcon && <TextArea></TextArea>}
          <Box sx={{ height: '40px' }}></Box>
        </Box>

        <FormControl variant="standard" sx={{ ml: 1, width: 225 }}>
          <TextField
            className={'text_area'}
            multiline
            margin="normal"
            rows={4}
            onChange={onChange}
            placeholder={t('notification.add_description')}
            InputProps={{
              disableUnderline: true,
              'aria-label': 'Without label',
              classes: {
                root: 'text_area_border',
              },
            }}
            variant="filled"
            size="small"
            value={label ? label : 'No comment'}
            disabled={disabled ? true : false}
          />
        </FormControl>
      </Stack>
    </>
  );
};
