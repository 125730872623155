import React, { FunctionComponent } from 'react';
import { Grid,TextField } from '@mui/material';
import { PatrimoniesList } from '../../components/patrimoniesList/patrimoniesList';

/**
 * Component PrestationsPage
 *
 * @component
 *
 * @example
 * return (
 *   <PrestationsPage localData={localData}/>
 * )
 */
const PatrimoniesPage: FunctionComponent = () => {
  return (
    <Grid container justifyContent="center" alignItems="center" className="listing_page_container">
      <PatrimoniesList />
    </Grid>
  );
};

export default PatrimoniesPage;
