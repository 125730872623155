import { Dialog, DialogContent, ThemeProvider } from '@mui/material';
import React, { useEffect, useContext } from 'react';
import { dialogTheme } from '../../../theme';
import { DialogTabs } from '../../ui/Dialog/DialogTabs/DialogTabs';
import { DialogHeader } from '../dialogTitle/dialogTitle';
import { CreateInterventionsBody } from '../interventionsDialog/createInterventionBody';
import { NotificationDialogBody } from '../notificationsDialog/notificationDialogBody/notificationDialogBody';
import PrestationDialogGtiBody from '../prestationsDialog/prestationDialogGtiBody/prestationDialogGtiBody';
import GlobalContext from '../../../context/GlobalContext';

interface DialogHeaderProps {
  show?: boolean;
  type: string;
  changeDialog: (value: boolean) => void;
}

export const PlanningCreateDialog = (props: DialogHeaderProps) => {
  const { show = false, type, changeDialog } = props;
  const [open, setOpen] = React.useState<boolean>(show);
  const handleClose = () => {
    setOpen(false);
    changeDialog(false);
  };
  useEffect(() => {
    setOpen(show);
  }, [show]);
  const gContext = useContext(GlobalContext);

  const items = [
    { title: 'prestation.prestation', value: 0, component: <PrestationDialogGtiBody onCloseModal={handleClose} /> },
    {
      title: 'intervention.intervention',
      value: 1,
      component: <CreateInterventionsBody type={type} onCloseModal={handleClose} />,
    },
    { title: 'notification.notification', value: 2, component: <NotificationDialogBody /> },
  ];
  const itemsWeekTemplate = [
    {
      title: 'prestation.prestation',
      value: 0,
      component: <CreateInterventionsBody type={type} onCloseModal={handleClose} />,
    },
  ];
  return (
    <>
      <ThemeProvider theme={dialogTheme}>
        <Dialog open={open} style={{ marginTop: '-30px' }}>
          <DialogHeader type={type} onClose={handleClose}></DialogHeader>
          <DialogContent>{gContext?.weekTypeSelected ? <DialogTabs items={itemsWeekTemplate} /> : <DialogTabs items={items} />}</DialogContent>
        </Dialog>
      </ThemeProvider>
    </>
  );
};
