import { HIDE_SNACK_BAR, SHOW_SNACK_BAR, SnackBarActionTypes } from './types';

export const hideSnackBar = (): SnackBarActionTypes => ({
  type: HIDE_SNACK_BAR,
});

export const showSnackBar = (message: string, severity: 'error' | 'warning' | 'info' | 'success'): SnackBarActionTypes => ({
  type: SHOW_SNACK_BAR,
  payload: {
    message,
    severity,
  },
});
