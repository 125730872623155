import React from 'react';

export const ClosedEye = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g xmlns="http://www.w3.org/2000/svg" fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <g transform="translate(4 5.777)">
          <path
            d="M8 11.023c2.946 0 5.612-1.6 8-4.8-2.388-3.2-5.054-4.8-8-4.8-2.946 0-5.612 1.6-8 4.8 2.388 3.2 5.054 4.8 8 4.8z"
            stroke="#00AADE"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <rect fill="#00AADE" transform="rotate(45 8 6.223)" x="7.2" y="-1.777" width="1.6" height="16" rx=".8" />
          <circle fill="#00AADE" cx="8" cy="6.223" r="1.6" />
        </g>
      </g>
    </svg>
  );
};

export default ClosedEye;
