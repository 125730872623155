export const PATHS = {
  Home: '/',
  Login: '/login',
  Users: '/users',
  Planning: '/',
  Prestations: '/prestations',
  Profile: '/profile',
  notifications: '/notifications',
  intervention: '/interventions',
  Patrimonies: '/patrimonies',
};
