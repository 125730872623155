import { Box, FormControl, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { colors } from '../../../../theme/shared-theme';

interface RadioItem {
  id: string | boolean;
  title: string;
}
interface DialogRadioGroupProps {
  items: Array<RadioItem>;
  icon: React.ComponentType<{ className: string }>;
  onchooseOption: (value: string) => void;
  value?: string;
}

const useStyles = makeStyles({
  label: {
    color: colors.MAIN_GREY,
  },
});

export const DialogRadioGroup = (props: DialogRadioGroupProps) => {
  const classes = useStyles();
  const { items, value, onchooseOption } = props;
  const CheckBoxIcon = props.icon;

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onchooseOption(event.target.value);
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
        <Grid container direction="row" alignItems="center" columnSpacing={2.5}>
          <Grid item>
            <Box sx={{ width: '24px' }}>
              <CheckBoxIcon className={'icon'} />
            </Box>
          </Grid>
          <Grid item>
            <FormControl>
              <RadioGroup row defaultValue={items[0].id} value={value}>
                {items.map((item: RadioItem, index: number) => (
                  <FormControlLabel
                    key={`radio-${index}`}
                    className={classes.label}
                    value={item.id}
                    control={<Radio onChange={(ev) => handleRadioChange(ev)} disableRipple />}
                    label={item.title}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
