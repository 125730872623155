import React from 'react';

export const TextArea = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g stroke="#72706F" strokeWidth="2" fill="none" fillRule="evenodd" strokeLinecap="round">
        <path d="M5 17.5h7M5 12.5h14M5 7.5h14" />
      </g>
    </svg>
  );
};

export default TextArea;
